import { useNavigate } from "react-router-dom";
import { Navbar } from "../../components/Navbar";
import { Header } from "../../components/Header";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  DateRangePicker,
  DateRange,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import dashboard2 from "../../assets/img/dashboard2.png";
import {
  dashboardClearActive,
  dashboardSetActive,
  loadDashboards,
  deleteDashboard,
} from "../../actions/dashboard";
import Swal from "sweetalert2";
import { uiOpenModal3, uiOpenModal7 } from "../../actions/ui";
import { ModalShare } from "../../components/ModalShare";
import { ModalPreviewDashboard } from "../../components/ModalPreviewDashboard";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
export const Dashboards = () => {
  
  const limit: number = 12;
  const [page, setPage] = useState(1);
  const { mode } = useSelector((state: any) => state.ui);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uid } = useSelector((state: any) => state.auth);
  const { dashboards, totalPages } = useSelector(
    (state: any) => state.dashboard
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [DasboardId, setDasboardId] = useState<any>("");
  const [type, setType] = useState(true);
  const [value, setValue] = useState<DateRange<Dayjs>>([null, null]);
  useEffect(() => {
    dispatch(loadDashboards(uid, limit, page, searchTerm, value));
  }, [page]);

  const handleEdit = (dashboard) => {
    dispatch(dashboardSetActive(dashboard));
    navigate("/create-dashboards");
  };

  const handleChangeDate = (newValue: any) => {
    setValue(newValue);
    if (newValue[0] && newValue[1]) {
      setPage(1);

      dispatch(loadDashboards(uid, limit, 1, searchTerm, newValue));
    }
  };
  const handleOpen = () => {
    dispatch(dashboardClearActive());

    navigate(`/create-dashboards`);
  };
  const handleChangePage = (e, value) => {
    if (value === 0) {
      if (parseInt(e.target.value) === 0) {
        setPage(1);
      }
      if (parseInt(e.target.value) > totalPages) {
        setPage(totalPages);
      } else {
        setPage(parseInt(e.target.value));
      }
    }
    if (value === -1) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
    if (value === 1) {
      if (page < totalPages) {
        setPage(page + 1);
      }
    }
  };
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setPage(1);
      dispatch(loadDashboards(uid, limit, 1, searchTerm, value));
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm]);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleOpenPrevie = (dashboard) => {
    setDasboardId(dashboard?._id);
    dispatch(dashboardSetActive(dashboard));
    dispatch(uiOpenModal7());
  };
  const handleDelete = (dashboard) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "buttonsAlert",
        cancelButton: "buttonCancel",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Borrar Dashboard",
        text: "Estas seguro de que quieres borrar este dasboard?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Borrar",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Borrado!",
            "Tu dashboard ha sido borrado.",
            "success"
          );
          dispatch(deleteDashboard(dashboard));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado", "", "error");
        }
      });
  };

  const handleCompartir = (dashboard: any) => {
    dispatch(dashboardSetActive(dashboard));
    dispatch(uiOpenModal3());
    //  Swal.fire('Link generado', `Tu link para compartir es: <a href="${ baseUrl}chart/${chart._id}" target="_blank"> ${ baseUrl}chart/${chart._id} </a> `, 'success');
  };

  return (
    <>
      <div className="dashboard">
        <Navbar />

        <Header />

        <main className={`${mode ? `dark-mode` : ""}`}>
          <div className="page-content">
            <div className="row">
              <div className="col-md-12">
                <div className="main-col">
                  <div className="headline">
                    <h3 className="title-dashboard">Mis Dashboards</h3>

                    <div className="actions">
                      <a onClick={handleOpen} className="al-btn primary-btn">
                        Nuevo dashboard
                      </a>
                    </div>
                  </div>

                  <section>
                    <div className="dashboard-content">
                      <div className="grid-graphics-container dashboard-table">
                        <div className="table-actions">
                          <div className="filtering">
                          <FormControl sx={{ m: 1, minWidth: 180 }}>
                                <InputLabel id="demo-simple-select-helper-label">Origen</InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  label="Origen"
                                  defaultValue={'propio'}
                                >
                                  <MenuItem value={'propio'}>
                                    Propio
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            <TextField id="outlined-basic" label="Buscar" variant="outlined" value={searchTerm}  onChange={(e) => handleSearch(e)} />
                            

                            <div className="data-range d-flex">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              localeText={{
                                start: "Fecha de inicio",
                                end: "Fecha de fin",
                              }}
                              className="data-range"
                            >
                              <DateRangePicker
                                value={value}
                                onChange={handleChangeDate}
                                className="data-range"
                                renderInput={(startProps, endProps) => (
                                  <>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> Hasta </Box>
                                    <TextField {...endProps} />
                                  </>
                                )}
                              />
                            </LocalizationProvider>
                            <IconButton aria-label="delete" className="ms-2"  onClick={() => {
                                dispatch(
                                  loadDashboards(uid, limit, 1, searchTerm, [
                                    null,
                                    null,
                                  ])
                                );
                                setValue([null, null]);
                                setPage(1);
                              }}>
                                <svg xmlns="http://www.w3.org/2000/svg"  width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                    <path d="M9 12l6 0" />
                                </svg>
                              </IconButton>
                          </div>
                          </div>
                          
                          <div className="tabs d-none">
                            <button className="active"> Propios</button>

                            {/* <button className='colorButton'> Colaboraciones</button> */}
                          </div>
                          
                          
                          <div className="d-flex gap-4">
                            <div className="d-flex gap-2">
                              <button
                                onClick={() => setType(true)}
                                className="rounded-2 border p-1 display-mode"
                                aria-choose={type ? "selected" : ""}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <rect
                                    x="4"
                                    y="4"
                                    width="6"
                                    height="6"
                                    rx="1"
                                  />
                                  <rect
                                    x="14"
                                    y="4"
                                    width="6"
                                    height="6"
                                    rx="1"
                                  />
                                  <rect
                                    x="4"
                                    y="14"
                                    width="6"
                                    height="6"
                                    rx="1"
                                  />
                                  <rect
                                    x="14"
                                    y="14"
                                    width="6"
                                    height="6"
                                    rx="1"
                                  />
                                </svg>
                              </button>
                              <button
                                onClick={() => setType(false)}
                                className="rounded-2 border p-1 display-mode"
                                aria-choose={!type ? "selected" : ""}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <line x1="9" y1="6" x2="20" y2="6" />
                                  <line x1="9" y1="12" x2="20" y2="12" />
                                  <line x1="9" y1="18" x2="20" y2="18" />
                                  <line x1="5" y1="6" x2="5" y2="6.01" />
                                  <line x1="5" y1="12" x2="5" y2="12.01" />
                                  <line x1="5" y1="18" x2="5" y2="18.01" />
                                </svg>
                              </button>
                            </div>

                            <div className="al-pagination justify-content-end">
                              <button
                                disabled={page == 1 ? true : false}
                                onClick={(e) => handleChangePage(e, -1)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <polyline points="15 6 9 12 15 18" />
                                </svg>
                              </button>
                              <div className="pages">
                                <input
                                  type="number"
                                  value={page}
                                  min={1}
                                  max={totalPages && totalPages}
                                  onChange={(e) => handleChangePage(e, 0)}
                                />
                                <span>de {totalPages && totalPages}</span>
                              </div>
                              <button
                                disabled={page == totalPages ? true : false}
                                onClick={(e) => handleChangePage(e, 1)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <polyline points="9 6 15 12 9 18" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                        {type ? (
                          <div className="grid-dashboards bg-gray p-3 rounded-3">
                            {dashboards.length > 0 &&
                              dashboards.map((chart: any) => (
                                <div className="graph-item dashboard-card">
                                  <button
                                    id="dropdownMenuButton"
                                    className="graph-actions"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="#2c3e50"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <circle cx="12" cy="12" r="1" />
                                      <circle cx="12" cy="19" r="1" />
                                      <circle cx="12" cy="5" r="1" />
                                    </svg>
                                  </button>
                                  <ul
                                    className="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => handleEdit(chart)}
                                      >
                                        Editar
                                      </a>
                                    </li>

                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => handleCompartir(chart)}
                                      >
                                        Compartir
                                      </a>
                                    </li>
                                    {/* https://web.whatsapp.com/send?text=Hola estoy interesad@ en sus servicios, quisiera más información */}

                                    <li>
                                      <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        onClick={() => handleDelete(chart._id)}
                                      >
                                        Eliminar
                                      </a>
                                    </li>
                                  </ul>

                                  <a
                                    onClick={() => handleOpenPrevie(chart)}
                                    className="img-contain"
                                  >
                                    <img
                                      src={
                                        chart?.img?.length > 1
                                          ? chart.img
                                          : dashboard2
                                      }
                                      alt=""
                                    />
                                  </a>
                                  <div className="graph-name justify-content-between">
                                    <div className="center-vertical">
                                      <div className="graph-type">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="22"
                                          height="22"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <rect
                                            x="4"
                                            y="4"
                                            width="6"
                                            height="6"
                                            rx="1"
                                          />
                                          <rect
                                            x="14"
                                            y="4"
                                            width="6"
                                            height="6"
                                            rx="1"
                                          />
                                          <rect
                                            x="4"
                                            y="14"
                                            width="6"
                                            height="6"
                                            rx="1"
                                          />
                                          <rect
                                            x="14"
                                            y="14"
                                            width="6"
                                            height="6"
                                            rx="1"
                                          />
                                        </svg>
                                      </div>
                                      <p>{chart.name}</p>
                                    </div>
                                    <div className="center-vertical gap-2 px-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#2c3e50"
                                        fill="#2c3e50"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                      </svg>
                                      <b>4.4</b>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className="table-container dash-table">
                            <table>
                              <thead>
                                <tr>
                                  <th>Nombre</th>
                                  <th className="date-th">Autor</th>
                                  <th className="date-th">Fecha de creación</th>
                                  <th className="date-th">Cliente</th>
                                  <th className="date-th">
                                    Fecha de modificación
                                  </th>

                                  <th className="date-th">Opciones</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dashboards &&
                                  dashboards.map((dashboard) => (
                                    <tr>
                                      <td className="name">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleOpenPrevie(dashboard)
                                          }
                                        >
                                          {dashboard.name}
                                        </a>
                                      </td>
                                      <td>{dashboard.userid.name}</td>
                                      <td className="date">
                                        {new Date(
                                          dashboard.createdAt
                                        ).toLocaleString()}
                                      </td>
                                      <td>{dashboard.clientid.name}</td>
                                      <td className="date">
                                        {new Date(
                                          dashboard.updatedAt
                                        ).toLocaleString()}
                                      </td>
                                      <td>
                                        <button
                                          id="dropdownMenuButton"
                                          className="graph-actions"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <circle cx="12" cy="12" r="1" />
                                            <circle cx="12" cy="19" r="1" />
                                            <circle cx="12" cy="5" r="1" />
                                          </svg>
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-end"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          <li>
                                            <button
                                              className="dropdown-item"
                                              onClick={() =>
                                                handleEdit(dashboard)
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#2c3e50"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                />
                                                <circle
                                                  cx="9.5"
                                                  cy="9.5"
                                                  r="6.5"
                                                />
                                                <rect
                                                  x="10"
                                                  y="10"
                                                  width="11"
                                                  height="11"
                                                  rx="2"
                                                />
                                              </svg>
                                              Editar
                                            </button>
                                          </li>
                                          {/* <li><a className="dropdown-item"  onClick={()=>handleEditClient(source)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="#2c3e50" fill="none" stroke-linecap="round"
                                                                    stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <circle cx="9" cy="7" r="4" />
                                                                    <path
                                                                        d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                                    <path d="M16 11h6m-3 -3v6" />
                                                                </svg>Cambiar Cliente</a></li> */}
                                          {/* <li><a className="dropdown-item" onClick={()=>handleDownload(source?._id,source?.tableName)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="#2c3e50" fill="none" stroke-linecap="round"
                                                                    stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path
                                                                        d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                                                    <polyline points="7 11 12 16 17 11" />
                                                                    <line x1="12" y1="4" x2="12" y2="16" />
                                                                </svg>Descargar</a></li> */}
                                          <li>
                                            <button
                                              className="dropdown-item"
                                              onClick={() =>
                                                handleCompartir(dashboard)
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#2c3e50"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                />
                                                <line
                                                  x1="4"
                                                  y1="7"
                                                  x2="20"
                                                  y2="7"
                                                />
                                                <line
                                                  x1="10"
                                                  y1="11"
                                                  x2="10"
                                                  y2="17"
                                                />
                                                <line
                                                  x1="14"
                                                  y1="11"
                                                  x2="14"
                                                  y2="17"
                                                />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                              </svg>
                                              Compartir
                                            </button>
                                          </li>
                                          <li>
                                            <button
                                              className="dropdown-item"
                                              onClick={() =>
                                                handleDelete(dashboard?._id)
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#2c3e50"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                />
                                                <line
                                                  x1="4"
                                                  y1="7"
                                                  x2="20"
                                                  y2="7"
                                                />
                                                <line
                                                  x1="10"
                                                  y1="11"
                                                  x2="10"
                                                  y2="17"
                                                />
                                                <line
                                                  x1="14"
                                                  y1="11"
                                                  x2="14"
                                                  y2="17"
                                                />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                              </svg>
                                              Eliminar
                                            </button>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ModalShare />
      <ModalPreviewDashboard id={DasboardId} setId={setDasboardId} />
    </>
  );
};
