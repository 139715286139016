
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';


export const PrivateRoute = ({children}:any) => {
    
    const { uid } = useSelector( (state:any) => state.auth);
    
 
    return uid ? children : <Navigate to="/"/>
}

