const dictionaryOfLength = {
  1: "all",
  2: "all",
  3: 40,
  4: 30,
  5: 22,
  6: 17,
  7: 16,
  8: 13,
  9: 10,
};

export const handleText = (
  text: string | any,
  propsData: any[],
  type: boolean
) => {
  const numData = propsData?.length;
  const numLetters = dictionaryOfLength[numData] || dictionaryOfLength[9];

  if (type) {
      
    return text;
  }

  if (text?.length <= numLetters || numLetters === "all") {

    return text;
  }
  if (text ===null) {

    return 'NULL';
    
  }

  let newText = "";

  for (let i = 0; i < numLetters; i++) {
    newText = newText + text?.[i];
  }
  
  return `${newText}...`;
};
