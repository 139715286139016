const hexaRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export const isHexa = (color: string) => {
  let colorToUse = color.trim();
  if (!colorToUse.startsWith("#")) {
    colorToUse = "#" + color.trim();
  }

  if (!hexaRegex.test(colorToUse)) {
    return false;
  }

  return colorToUse;
};
