import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { chartReducer } from "./chartRedurcer";
import { dashboardReducer } from "./dashboardRedurcer";
import { sourceReducer } from './sourceReducer';
import { uiReducer } from './uiReducer';





export const rootReducer=combineReducers({
   
    auth:authReducer,
    source:sourceReducer,
    ui:uiReducer,
    chart:chartReducer,
    dashboard:dashboardReducer
   
})