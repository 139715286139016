import React from "react";
// import { Modal } from '@mui/material';
import Modal from "react-bootstrap/Modal";
// import Select from 'react-select'
import { useSelector, useDispatch } from "react-redux";
import { uiCloseModal3 } from "../actions/ui";
import combine from "../assets/img/join-forms/join-form-1.svg";
import combine2 from "../assets/img/join-forms/join-form-2.svg";
import combine3 from "../assets/img/join-forms/join-form-3.svg";
import combine4 from "../assets/img/join-forms/join-form-4.svg";
import combine5 from "../assets/img/join-forms/join-form-5.svg";
import { useForm } from "../hooks/useForm";
import { useEffect } from "react";
export const ModalCombine = ({
  data,
  typeJoin,
  setTypeJoin,
  getSql,
  source,
  setKeys,
  keys,
}) => {
  const { modalOpen3 } = useSelector((state: any) => state.ui);
  const { mode } = useSelector((state: any) => state.ui);
  const datasources = data?.filter((item) => item?._id !== source?._id);

  const initialValue = {
    key: "",
    key2: "",
    index: 0,
  };
  const { FormData, onChange, setFormData } = useForm(initialValue);
  const { key, key2, index } = FormData;
  const dispatch = useDispatch();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#ffff",
    boxShadow: 24,
  };
  const options = [
    { value: "Some example data", label: "Some example data" },
    { value: "Some example data 2", label: "Some example data 2" },
    { value: "Some example data 3", label: "Some example data 3" },
  ];

  const closeModal = () => {
    dispatch(uiCloseModal3());
  };
  const handleChange = (value: string) => {
    setTypeJoin(value);
  };

  const handleSql = () => {
    let from: string = ``;
    if (source.index <= 1) {
      from = `from \`vise-192912.altum_hist.${
        data[0].tableName ? data[0].tableName : data[0].view
      }${data[0].tableName && !data[0]?.typeSync ? data[0]._id : ""}\` `;

      switch (typeJoin) {
        case "Inner":
          from = from + " JOIN ";
          break;
        case "Left":
          from = from + " LEFT OUTER JOIN ";
          break;
        case "Right":
          from = from + " RIGHT OUTER JOIN ";
          break;
        case "Full":
          from = from + " FULL OUTER JOIN ";
          break;

        default:
          break;
      }

      from = `${from} \`vise-192912.altum_hist.${
        source.tableName ? source.tableName : source.view
      }${
        source.tableName && !source?.typeSync ? source._id : ""
      }\` ON \`vise-192912.altum_hist.${
        data[0].tableName ? data[0].tableName : data[0].view
      }${
        data[0].tableName && !data[0]?.typeSync ? data[0]._id : ""
      }\`.${key} = \`vise-192912.altum_hist.${
        source.tableName ? source.tableName : source.view
      }${source.tableName && !source?.typeSync ? source._id : ""}\`.${key2} `;
      setKeys([...keys, { index: 0, key, key2, from }]);
      getSql(false, { index: 0, key, key2, from });
    } else {
      switch (typeJoin) {
        case "Inner":
          from = from + " JOIN ";
          break;
        case "Left":
          from = from + " LEFT OUTER JOIN ";
          break;
        case "Right":
          from = from + " RIGHT OUTER JOIN ";
          break;
        case "Full":
          from = from + " FULL OUTER JOIN ";
          break;

        default:
          break;
      }
      from = `${from} \`vise-192912.altum_hist.${
        source.tableName ? source.tableName : source.view
      }${
        source.tableName && !source?.typeSync ? source._id : ""
      }\` ON \`vise-192912.altum_hist.${
        datasources[index].tableName
          ? datasources[index].tableName
          : datasources[index].view
      }${
        datasources[index].tableName && !datasources[index]?.typeSync
          ? datasources[index]._id
          : ""
      }\`.${key} = \`vise-192912.altum_hist.${
        source.tableName ? source.tableName : source.view
      }${source.tableName && !source?.typeSync ? source._id : ""}\`.${key2} `;

      setKeys([...keys, { index: index, key, key2, from }]);
      getSql(false, { index: index, key, key2, from });
    }

    closeModal();
  };
  useEffect(() => {}, [data]);

  return (
    <>
      <Modal
        show={modalOpen3}
        onHide={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="xl"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title text-center" id="exampleModalLabel">
            Configuración del Join
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => closeModal()}
          ></button>
        </div>
        <div className="modal_body">
          <p>
            Indique cómo se combinan las filas de todas las tablas de la
            izquierda y la tabla de la derecha
          </p>
          <div className="join-types">
            <div className="col">
              <input
                type="radio"
                onClick={() => handleChange("Left")}
                name="jointype"
                id="join-1"
              />
              <label className="join-type" htmlFor="join-1">
                <img src={combine} alt="" />
                <p>Left Outer Join</p>
              </label>
            </div>
            <div className="col">
              <input
                type="radio"
                onClick={() => handleChange("Right")}
                name="jointype"
                id="join-2"
              />
              <label className="join-type" htmlFor="join-2">
                <img src={combine2} alt="" />
                <p>Right Outer Join</p>
              </label>
            </div>
            <div className="col">
              <input
                type="radio"
                name="jointype"
                onClick={() => handleChange("Inner")}
                id="join-3"
              />
              <label className="join-type" htmlFor="join-3">
                <img src={combine3} alt="" />
                <p>Inner Join</p>
              </label>
            </div>
            <div className="col">
              <input
                type="radio"
                name="jointype"
                onClick={() => handleChange("Full")}
                id="join-4"
              />
              <label className="join-type" htmlFor="join-4">
                <img src={combine4} alt="" />
                <p>Full Outer Join</p>
              </label>
            </div>
          </div>
          <h5 className="modal-title">Condiciones de unión</h5>
          <p>
            Especifíque cómo se relacionan las tablas. Añada uno o varios campos
            de las tablas de la izquierda que coincidan con los campos de la
            derecha
          </p>
          <div className="data-to-join">
            <div className="data-col" uk-sortable="handle: .data-item">
              <div className="">
                {/* <Select options={options} /> */}

                {source?.index > 1 && (
                  <select
                    name="index"
                    id=""
                    onChange={onChange}
                    className="data-item w-100"
                  >
                    <option value="">Seleccione...</option>
                    {datasources?.length > 1 &&
                      datasources?.map((data: any, index: any) => (
                        <option value={index}>{data.tableName}</option>
                      ))}
                  </select>
                )}
              </div>
              <div className="">
                {/* <Select options={options} /> */}

                <select
                  name="key"
                  id=""
                  onChange={onChange}
                  className="data-item w-100"
                >
                  <option value="">Seleccione...</option>
                  {source?.index > 1
                    ? datasources &&
                      datasources[index]?.data.map((data: any) => (
                        <option value={data.column_name}>
                          {data.column_name}
                        </option>
                      ))
                    : data &&
                      data[0]?.data.map((data: any) => (
                        <option value={data.column_name}>
                          {data.column_name}
                        </option>
                      ))}
                </select>
              </div>
            </div>

            <div>
              <div className="data-conect-state">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#2c3e50"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
                  <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                </svg>
              </div>
            </div>

            <div className="data-col" uk-sortable="handle: .data-item">
              {/* <Select options={options} /> */}
              <select
                name="key2"
                id=""
                onChange={onChange}
                className="data-item w-100"
              >
                <option value="">Seleccione...</option>
                {source &&
                  source?.data.map((data: any) => (
                    <option value={data.column_name}>{data.column_name}</option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {/* <!-- <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> --> */}
          <button
            type="button"
            className="al-btn primary-btn show-datafont"
            data-bs-dismiss="modal"
            onClick={() => {
              handleSql();
            }}
          >
            Generar unión
          </button>
        </div>
      </Modal>
    </>
  );
};
