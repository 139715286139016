import { AnyAction } from 'redux';
import Swal from 'sweetalert2';
import { fetchSinToken, fetchConToken } from '../helpers/fetch';
import { types } from '../types/types';





export const startLogin = ( user:string, password:string  ):any => {
    return async( dispatch:any ) => {

        const resp = await fetchSinToken('auth',{ user, password },'POST');
        
         const body = await resp.json();

        const tiempo:any = new Date().getTime()
        if(body.ok ) {
          
            sessionStorage.setItem('token', body.token );
            sessionStorage.setItem('token-init-date', tiempo );
           await dispatch( login({
                uid: body.uid,
                name: body.name,
                rol:body.rol,
              
            }))
         
        } else {
            Swal.fire('Error', 'usuario o contraseña incorrectos ', 'error');
        }
        

    }
}

// export const startRegister = (name:any, email:any,document:any, phone:any,password:any,province?:any,empresa?:any,state?:any,rol?:any,  ) => {
//     return async( dispatch:any ) => {
//         console.log(startRegister)
//         const resp = await fetchSinToken( 'auth/new', { name, email,document, phone,province,password,state,empresa,rol }, 'POST' );
    
//         const body = await resp.json();
   
//         if( body.ok ) {
//             dispatch( userAddNew( body.user ) );
//         } else {
//             Swal.fire('Error', body.msg, 'error');
//         }


//     }
// }

// const userAddNew = (user:any) => ({
//     type: types.restaurantAddNew,
//     payload: user
// });


  


export const startChecking = ():any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( 'auth/renew' );
        const body = await resp.json();
       
        const tiempo:any = new Date().getTime()
      
        if( body.ok ) {
            sessionStorage.setItem('token', body.token );
            sessionStorage.setItem('token-init-date', tiempo );
           
            dispatch( login({
                uid: body.uid,
                name: body.name,
                rol:body.rol,
                restaurant_id:body.restaurant_id
            }) )
        } else {
            sessionStorage.clear();
            dispatch( checkingFinish() );
        }
    }
}

const checkingFinish = () => ({ type: types.authCheckingFinish });



const login = ( user:any ) => ({
    type: types.authLogin,
    payload: user
});


export const startLogout = ():any => {
    return ( dispatch:any ) => {

       sessionStorage.clear();
        dispatch( logout() );
    }
}

const logout = () => ({ type: types.authLogout })

// export const forgotPassword = async ( email:any ) => {

//     const resp = await fetchSinToken( 'auth/forgot-password', { email }, 'PUT' );

//     const body = await resp.json();

//     if(!body.ok){
//         Swal.fire('Error', body.msg, 'error');
//     }else{
//         Swal.fire('', 'Revise su correo, le enviamos un link ');
//     }

//     console.log(body)
    
// }

// export const resetPassword = async ( password:any, token:any ) => {

//     const resp = await fetchSinToken( 'auth/reset-password', { password, token }, 'PUT' );

//     const body = await resp.json();

//     if(!body.ok){
//         Swal.fire('Error', body.msg, 'error');
//     }else{
//         Swal.fire('', body.msg);
//     }

//     console.log(body)
    
// }