import img from "../../assets/img/dash-img.png";
import dashboard1 from "../../assets/img/dashboard1.png";

import dashboard2 from "../../assets/img/dashboard2.png";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Navbar } from "../../components/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { chartSetActive, loadCharts } from "../../actions/chart";
import { useEffect, useState } from "react";
import { loadSource } from "../../actions/source";
import { loadDashboards, dashboardSetActive } from "../../actions/dashboard";
import { Swiper, SwiperSlide } from "swiper/react";
import promoImg from "../../assets/img/first-img.png";
import { EffectCards, Pagination, Autoplay, Navigation } from "swiper";
import Modal from "react-bootstrap/Modal";
import "swiper/css/bundle";

export const Home = () => {
  const { name, rol, uid } = useSelector((state: any) => state.auth);
  const { mode } = useSelector((state: any) => state.ui);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { charts } = useSelector((state: any) => state.chart);
  const { dashboards } = useSelector((state: any) => state.dashboard);
  const [type, setType] = useState(true);
  const handleEdit = (chart) => {
    dispatch(chartSetActive(chart));
    navigate("/create-chart");
  };
  const handleEditDashboard = (chart) => {
    dispatch(dashboardSetActive(chart));
    navigate("/create-dashboards");
  };
  useEffect(() => {
    dispatch(loadCharts(uid, 12, 1));

    dispatch(loadDashboards(uid, 5, 1));
  }, []);

  const pagination = {
    clickable: true,
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="dashboard">
        <Navbar />

        <Header />

        <main className={`${mode ? `dark-mode` : ""}`}>
          <section className="page-content">
            <div className="row">
              <div className="col-xl-7 col-xxl-8">
                <div className="welcome">
                  <h3 className="title-dashboard">
                    Bienvenido de vuelta {name}
                  </h3>
                  <p className="mb-0">Esperamos que tengas un muy buen día</p>
                  <img src={img} alt="" />
                </div>
                <div className="headline">
                  <h3 className="title-dashboard">Gráficos recientes</h3>
                  <div className="actions center-vertical">
                    <div className="d-flex gap-2">
                      <button
                        onClick={() => setType(true)}
                        className="rounded-2 border p-1 display-mode"
                        aria-choose={type ? "selected" : ""}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="4" y="4" width="6" height="6" rx="1" />
                          <rect x="14" y="4" width="6" height="6" rx="1" />
                          <rect x="4" y="14" width="6" height="6" rx="1" />
                          <rect x="14" y="14" width="6" height="6" rx="1" />
                        </svg>
                      </button>
                      <button
                        onClick={() => setType(false)}
                        className="rounded-2 border p-1 display-mode"
                        aria-choose={!type ? "selected" : ""}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="9" y1="6" x2="20" y2="6" />
                          <line x1="9" y1="12" x2="20" y2="12" />
                          <line x1="9" y1="18" x2="20" y2="18" />
                          <line x1="5" y1="6" x2="5" y2="6.01" />
                          <line x1="5" y1="12" x2="5" y2="12.01" />
                          <line x1="5" y1="18" x2="5" y2="18.01" />
                        </svg>
                      </button>
                    </div>
                    <a
                      onClick={() => navigate(`/charts`)}
                      className="al-btn primary-btn"
                    >
                      Ver todos
                    </a>
                  </div>
                </div>
                {type ? (
                  <div className="bg-gray p-3 rounded-4 graphics-container">
                    <div className="home-graph-grid">
                      {charts.length < 1 ? (
                        <>falta vista</>
                      ) : (
                        charts.map(
                          (chart, i) =>
                            i < 16 && (
                              <a
                                onClick={() => handleEdit(chart)}
                                className="graph-item"
                              >
                                <div className="img-contain">
                                  <img src={chart?.img} alt="" />
                                </div>
                                <div className="graph-name">
                                  <div className="graph-type">
                                    {chart.typegraf === "barchart" && (
                                      <>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <rect
                                            x="3"
                                            y="12"
                                            width="6"
                                            height="8"
                                            rx="1"
                                          ></rect>
                                          <rect
                                            x="9"
                                            y="8"
                                            width="6"
                                            height="12"
                                            rx="1"
                                          ></rect>
                                          <rect
                                            x="15"
                                            y="4"
                                            width="6"
                                            height="16"
                                            rx="1"
                                          ></rect>
                                          <line
                                            x1="4"
                                            y1="20"
                                            x2="18"
                                            y2="20"
                                          ></line>
                                        </svg>
                                      </>
                                    )}
                                    {chart.typegraf === "table" && (
                                      <>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <rect
                                            x="4"
                                            y="4"
                                            width="16"
                                            height="16"
                                            rx="2"
                                          ></rect>
                                          <line
                                            x1="4"
                                            y1="10"
                                            x2="20"
                                            y2="10"
                                          ></line>
                                          <line
                                            x1="10"
                                            y1="4"
                                            x2="10"
                                            y2="20"
                                          ></line>
                                        </svg>
                                      </>
                                    )}
                                    {chart.typegraf === "radiuschart" && (
                                      <>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <path d="M12 12l-6.5 5.5"></path>
                                          <path d="M12 3v9h9"></path>
                                          <circle
                                            cx="12"
                                            cy="12"
                                            r="9"
                                          ></circle>
                                          <path d="M12 12l5 7.5"></path>
                                        </svg>
                                      </>
                                    )}
                                    {chart.typegraf === "linechart" && (
                                      <>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19"></polyline>
                                          <polyline points="4 12 7 8 11 10 16 4 20 8"></polyline>
                                        </svg>
                                      </>
                                    )}

                                    {chart.typegraf ===
                                      "horizontalBarChart" && (
                                      <>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <line
                                            x1="3"
                                            y1="18"
                                            x2="17"
                                            y2="18"
                                          ></line>
                                          <path d="M9 9l3 3l-3 3"></path>
                                          <path d="M14 15l3 3l-3 3"></path>
                                          <line
                                            x1="3"
                                            y1="3"
                                            x2="3"
                                            y2="21"
                                          ></line>
                                          <line
                                            x1="3"
                                            y1="12"
                                            x2="12"
                                            y2="12"
                                          ></line>
                                          <path d="M18 3l3 3l-3 3"></path>
                                          <line
                                            x1="3"
                                            y1="6"
                                            x2="21"
                                            y2="6"
                                          ></line>
                                        </svg>
                                      </>
                                    )}
                                    {chart.typegraf === "donutchart" && (
                                      <>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <path d="M12 3v5m4 4h5"></path>
                                          <circle
                                            cx="12"
                                            cy="12"
                                            r="4"
                                          ></circle>
                                          <circle
                                            cx="12"
                                            cy="12"
                                            r="9"
                                          ></circle>
                                        </svg>
                                      </>
                                    )}
                                    {chart.typegraf ===
                                      "lineChartWithNodes" && (
                                      <>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <line
                                            x1="4"
                                            y1="19"
                                            x2="20"
                                            y2="19"
                                          ></line>
                                          <polyline points="4 15 8 9 12 11 16 6 20 10"></polyline>
                                        </svg>
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    <p>{chart?.name}</p>
                                    <span></span>
                                  </div>
                                </div>
                              </a>
                            )
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="grid-graphics-container dashboard-table">
                    <div className="table-container dash-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th className="date-th">Autor</th>
                            <th className="date-th">Fecha de creación</th>

                            <th className="date-th">Fecha de modificación</th>
                          </tr>
                        </thead>
                        <tbody>
                          {charts.length >= 1 &&
                            charts.map((chart) => (
                              <tr>
                                <td className="name">
                                  <a href="#" onClick={() => handleEdit(chart)}>
                                    {chart.name}
                                  </a>
                                </td>
                                <td>{chart?.userid?.name}</td>
                                <td className="date">
                                  {chart?.createdAt
                                    ? new Date(
                                        chart?.createdAt
                                      ).toLocaleString()
                                    : new Date(Date.now()).toLocaleString()}
                                </td>

                                <td className="date">
                                  {chart?.updatedAt
                                    ? new Date(
                                        chart?.updatedAt
                                      ).toLocaleString()
                                    : new Date(Date.now()).toLocaleString()}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xl-5 col-xxl-4">
                <div className="aux-col">
                  <div className="headline">
                    <h3 className="title-dashboard">Dashboard recientes</h3>

                    <div className="actions center-vertical">
                      <a
                        onClick={() => navigate(`/dashboards`)}
                        className="al-btn primary-btn"
                      >
                        Ver todos
                      </a>
                    </div>
                  </div>
                  <div className="bg-gray p-4 rounded-4 ">
                    {dashboards.length < 1 ? (
                      <>falta vista</>
                    ) : (
                      <Swiper
                        spaceBetween={30}
                        navigation={true}
                        modules={[Navigation]}
                        loop={true}
                      >
                        {dashboards.map(
                          (chart, i) =>
                            i < 5 && (
                              <SwiperSlide>
                                <a
                                  onClick={() => handleEditDashboard(chart)}
                                  className="graph-item dashboard-item"
                                >
                                  <div className="img-contain">
                                    <img
                                      src={
                                        chart?.img?.length > 1
                                          ? chart.img
                                          : dashboard1
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="graph-name justify-content-between">
                                    <div className="center-vertical">
                                      <div className="graph-type">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <rect
                                            x="4"
                                            y="4"
                                            width="6"
                                            height="5"
                                            rx="2"
                                          ></rect>
                                          <rect
                                            x="4"
                                            y="13"
                                            width="6"
                                            height="7"
                                            rx="2"
                                          ></rect>
                                          <rect
                                            x="14"
                                            y="4"
                                            width="6"
                                            height="7"
                                            rx="2"
                                          ></rect>
                                          <rect
                                            x="14"
                                            y="15"
                                            width="6"
                                            height="5"
                                            rx="2"
                                          ></rect>
                                        </svg>
                                      </div>

                                      <p>{chart?.name}</p>
                                    </div>
                                    <div className="center-vertical gap-2 px-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#2c3e50"
                                        fill="#2c3e50"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                      </svg>
                                      <b>4.4</b>
                                    </div>
                                  </div>
                                </a>
                              </SwiperSlide>
                            )
                        )}
                      </Swiper>
                    )}
                  </div>

                  <div className="overflow-hidden rounded-4 promo-slider text-white">
                    <Swiper
                      effect={"cards"}
                      pagination={pagination}
                      autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay, EffectCards, Pagination]}
                      className="swiper-promo"
                    >
                      <SwiperSlide className="bg-primary  rounded-4">
                        <div className="col-md-7">
                          <h5 className="title-dashboard">
                            Disfruta de la nueva actualización 2.0
                          </h5>
                          <p>
                            Actualiza tu experiencia con nuestra plataforma con
                            la nueva versión
                          </p>
                        </div>
                        <div className="col-md-5 h-100 position-relative">
                          <div className="img-fit h-100">
                            <img src={promoImg} alt="" />
                          </div>
                          <button
                            className="promo-video position-absolute"
                            onClick={handleShow}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#fff"
                              fill="#fff"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M7 4v16l13 -8z" />
                            </svg>
                          </button>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="bg-success rounded-4">
                        <div className="col-md-7">
                          <h5 className="title-dashboard">
                            Disfruta de la nueva actualización 2.0
                          </h5>
                          <p>
                            Actualiza tu experiencia con nuestra plataforma con
                            la nueva versión
                          </p>
                        </div>
                        <div className="col-md-5 h-100">
                          <div className="img-fit h-100">
                            <img
                              src="https://images.unsplash.com/photo-1457904375453-3e1fc2fc76f4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8bnVtYmVyc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                              alt=""
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="bg-secondary rounded-4">
                        <div className="col-md-7 ">
                          <h5 className="title-dashboard">
                            Disfruta de la nueva actualización 2.0
                          </h5>
                          <p>
                            Actualiza tu experiencia con nuestra plataforma con
                            la nueva versión
                          </p>
                        </div>
                        <div className="col-md-5 h-100">
                          <div className="img-fit h-100">
                            <img
                              src="https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fG51bWJlcnN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                              alt=""
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Modal centered size="xl" show={show} onHide={handleClose}>
          <Modal.Body>
            <iframe
              className="w-100"
              height={600}
              src="https://youtube.com/embed/8h-KrhcGw6s"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
