import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";

import { FormEvent, useEffect, useState } from "react";
import { useForm } from "../hooks/useForm";

import { uiCloseModal9 } from "../actions/ui";
import Swal from "sweetalert2";

export const ModalVariables = ({
  data,
  setSelect,
  setData,
  selected,
  setExtra,
  extra,
}) => {
  const initialValue: any = {
    filterType: "",
    type: "",
    value: 0,
    value2: 0,
    text: "",
    name: "",
    fecha: "",
    fecha2: "",
  };

  const { mode } = useSelector((state: any) => state.ui);
  const { uid } = useSelector((state: any) => state.auth);
  const { modalOpen9 } = useSelector((state: any) => state.ui);
  const { sourceActive } = useSelector((state: any) => state.source);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { FormData, onSelect, onChange, resetForm } = useForm(initialValue);

  const { filterType, type, value, value2, text, fecha, fecha2, key, name } =
    FormData;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#ffff",

    border: "2px solid #000",
    boxShadow: 24,
  };

  const closeModal = () => {
    dispatch(uiCloseModal9());
    resetForm();
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    let consulta: string;
    event.preventDefault();
    if (!name) {
      Swal.fire("Falta nombre", `Falta el nombre del campo `, "error");
    } else {
      switch (filterType) {
        case "mayus":
          consulta = ` UPPER(${key}) as ${name}`;
          setData([
            ...data,
            { column_name: name, data_type: "STRING", key: ` UPPER(${key}) ` },
          ]);
          break;

        case "minus":
          consulta = ` LOWER(${key}) as ${name}`;
          setData([
            ...data,
            { column_name: name, data_type: "STRING", key: ` LOWER(${key})` },
          ]);
          break;

        case "capi":
          consulta = ` INITCAP(${key}) as ${name}`;
          setData([
            ...data,
            { column_name: name, data_type: "STRING", key: ` INITCAP(${key})` },
          ]);

          break;

        case "Año":
          consulta = ` EXTRACT(YEAR FROM Date(${key})) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "INT64",
              key: ` EXTRACT(YEAR FROM Date(${key}))`,
            },
          ]);
          break;

        case "Mes":
          consulta = ` EXTRACT(MONTH FROM Date(${key})) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "INT64",
              key: ` EXTRACT(MONTH FROM Date(${key})) `,
            },
          ]);
          break;

        case "Mesesp":
          consulta = ` FORMAT_DATE('%b', Date(${key})) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "DATIME",
              key: ` FORMAT_DATE('%b', Date(${key}))`,
            },
          ]);
          break;

        case "atrime":
          consulta = ` CONCAT(CAST(EXTRACT(YEAR FROM Date(${key})) AS STRING), 'T', CAST(EXTRACT(QUARTER FROM Date(${key})) AS STRING)) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "STRING",
              key: ` CONCAT(CAST(EXTRACT(YEAR FROM Date(${key})) AS STRING), 'T', CAST(EXTRACT(QUARTER FROM Date(${key})) AS STRING)) `,
            },
          ]);
          break;
        case "ames":
          consulta = ` FORMAT_DATE('%m/%y', Date(${key})) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "DATETIME",
              key: ` FORMAT_DATE('%m/%y', Date(${key}))`,
            },
          ]);
          break;

        case "asem":
          consulta = ` CONCAT(EXTRACT(YEAR FROM Date(${key})), '/', FORMAT_DATE('%W', Date(${key}))) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "STRING",
              key: ` CONCAT(EXTRACT(YEAR FROM Date(${key})), '/', FORMAT_DATE('%W', Date(${key})))`,
            },
          ]);
          break;

        case "trime":
          consulta = ` EXTRACT(QUARTER FROM Date(${key})) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "STRING",
              key: ` EXTRACT(QUARTER FROM Date(${key}))`,
            },
          ]);
          break;
        case "mesd":
          consulta = ` FORMAT_DATE('%d/%m', Date(${key})) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "DATETIME",
              key: ` FORMAT_DATE('%d/%m', Date(${key}))`,
            },
          ]);
          break;
        case "dias":
          consulta = ` FORMAT_DATE('%A', Date(${key})) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "DATETIME",
              key: ` FORMAT_DATE('%A', Date(${key}))`,
            },
          ]);
          break;
        case "diam":
          consulta = ` FORMAT_DATE('%d', Date(${key})) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "DATETIME",
              key: ` FORMAT_DATE('%d', Date(${key}))`,
            },
          ]);
          break;

        case "sum":
          consulta = ` sum(${key}) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "FLOAT64",
              key: ` sum(${key}) `,
            },
          ]);
          break;

        case "count":
          consulta = ` count(${key}) AS ${name}`;
          setData([
            ...data,
            {
              column_name: name,
              data_type: "FLOAT64",
              key: ` count(${key}) `,
            },
          ]);
          break;
        default:
          break;
      }

      setExtra([...extra, { name: name, key: consulta }]);
      closeModal();
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {}, [sourceActive]);

  return (
    <>
      <Modal
        show={modalOpen9}
        onHide={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Selecciona el campo que quieres utilizar
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              closeModal();
            }}
            aria-label="Close"
          ></button>
        </div>
        {
          <div className="modal_body">
            <form
              className="tab-content"
              onSubmit={onSubmit}
              id="nav-tabContent"
            >
              <div
                className="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                tabIndex={0}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="al-field">
                      <label htmlFor="">Campo</label>
                      <select
                        name="type"
                        id=""
                        onChange={onSelect}
                        className="input_search mb-4"
                      >
                        <option value="">Seleccione...</option>
                        {data &&
                          data.map((table: any) => (
                            <option id={table.key} value={table.data_type}>
                              {table.column_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    {type && (
                      <div className="al-field">
                        <label htmlFor="">Formato</label>
                        <select
                          name="filterType"
                          id=""
                          onChange={onSelect}
                          className="input_search mb-4"
                        >
                          <option value="">Seleccione...</option>

                          {type === "STRING" && (
                            <option value="mayus"> Todo mayusculas </option>
                          )}
                          {type === "STRING" && (
                            <option value="minus"> Todo minusculas </option>
                          )}

                          {type === "STRING" && (
                            <option value="capi">Capitalizar </option>
                          )}
                          {(type === "DATETIME" || type === "DATE") && (
                            <option value="Año"> Año </option>
                          )}
                          {(type === "DATETIME" || type === "DATE") && (
                            <option value="Mes"> Mes </option>
                          )}
                          {/* {((type === "DATETIME") || (type === "DATE")) && (
                              <option value="Mesesp">
                                {" "}
                                Mes en texto {" "}
                              </option>
                            )} */}
                          {(type === "DATETIME" || type === "DATE") && (
                            <option value="atrime"> Año y trimestre </option>
                          )}
                          {(type === "DATETIME" || type === "DATE") && (
                            <option value="ames"> Año y mes </option>
                          )}
                          {(type === "DATETIME" || type === "DATE") && (
                            <option value="asem"> Año y Semana </option>
                          )}
                          {(type === "DATETIME" || type === "DATE") && (
                            <option value="trime"> Trimestre </option>
                          )}
                          {(type === "DATETIME" || type === "DATE") && (
                            <option value="mesd"> Mes y dia </option>
                          )}
                          {(type === "DATETIME" || type === "DATE") && (
                            <option value="dias"> Dia de la semana </option>
                          )}
                          {(type === "DATETIME" || type === "DATE") && (
                            <option value="diam"> Dia del mes </option>
                          )}
                          {(type === "INT64" || type === "FLOAT64") && (
                            <option value="sum"> Sumar </option>
                          )}
                          {<option value="count"> Count </option>}
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="al-field">
                    <label htmlFor="">
                      Nombre del campo (no usar espacios){" "}
                    </label>
                    <input
                      placeholder="Escriba aqui como quiere que se llame el nuevo campo"
                      onChange={onChange}
                      value={name}
                      name="name"
                      type="text"
                    />
                  </div>
                  {/* <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Host</label>
                                        <input type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div> */}
                </div>
              </div>

              <div className="modal-footer p-0 mt-4">
                <button
                  type="submit"
                  disabled={loading}
                  className="al-btn primary-btn show-datafont"
                >
                  Guardar
                </button>
              </div>
            </form>
            <div></div>
          </div>
        }
      </Modal>
    </>
  );
};
