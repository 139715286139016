import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useForm } from "../../../hooks/useForm";

function FiltrosDashboardMain({ filtro, handleFiltro }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const initialValue = {
    type: "",
    value: "",
    value2: "",
  };
  const [filtroselect, setfiltroselect] = useState<any>();
  const { FormData, onChange, setFormData, resetForm } = useForm(initialValue);
  const { type, value, value2 } = FormData;
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleOnChange = (e) => {
    const filtroseleccionado = filtro.find(
      (event, i) => i === parseInt(e.target.value)
    );
    setfiltroselect(filtroseleccionado);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleSubmit = (filtro, index) => {
    if (filtro.value === "remove") {
      handleFiltro({
        remove: "remove",
        index,
      });
    } else {
      handleFiltro({
        filtro: filtro,
        name: filtro?.name,
        value,
        value2,
        type,
        index,
      });
    }

    closeDropdown();
  };

  return (
    <>
      {filtro?.length > 0 && (
        <div className="dashboard-filter mb-0">
          <p>Filtros</p>
          <div className="filter-group">
            {filtro?.length > 0 &&
              filtro.map((fil, index) => (
                <Dropdown>
                  <Dropdown.Toggle
                    className="filter-item px-2"
                    variant="none"
                    id="dropdown-custom-components"
                  >
                    {fil.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    show={isOpen}
                    ref={dropdownRef}
                    aria-labelledby="filter-button-set-1"
                    className="filter-container mt-2"
                  >
                    {/* Contenido del menú desplegable */}

                    <div className="px-4 py-3">
                      <h3>{fil?.name}</h3>
                      <hr />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="al-field">
                            <label htmlFor="exampleDropdownFormEmail1">
                              tipo de filtro
                            </label>
                            <select name="type" id="" onChange={onChange}>
                              <option>Selecione...</option>
                              {fil?.type === "DATETIME" && (
                                <option value="entre">Entre</option>
                              )}
                              {fil?.type === "DATETIME" && (
                                <option value="mayor">Mayor</option>
                              )}
                              {fil?.type === "DATETIME" && (
                                <option value="menor">Menor</option>
                              )}
                              {fil?.type === "DATETIME" && (
                                <option value="igual">Igual</option>
                              )}

                              {fil?.type === "FLOAT64INT64" && (
                                <option value="entre">Entre</option>
                              )}
                              {fil?.type === "FLOAT64INT64" && (
                                <option value="mayor">Mayor</option>
                              )}
                              {fil?.type === "FLOAT64INT64" && (
                                <option value="menor">Menor</option>
                              )}
                              {fil?.type === "FLOAT64INT64" && (
                                <option value="igual">Igual</option>
                              )}
                              {fil?.type === "STRING" && (
                                <option value="igual">Igual</option>
                              )}
                              {fil?.type === "STRING" && (
                                <option value="empieza">Empieza por</option>
                              )}
                              {fil?.type === "STRING" && (
                                <option value="contiene">Contiene</option>
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="al-field">
                            <label htmlFor="exampleDropdownFormEmail1">
                              Seleccione
                            </label>
                            <input
                              type={
                                (fil.type === "DATETIME" && "date") ||
                                (fil.type === "FLOAT64INT64" && "number") ||
                                (fil.type === "STRING" && "text")
                              }
                              name="value"
                              value={value}
                              onChange={onChange}
                              id=""
                            />
                          </div>
                          {type === "entre" && (
                            <div className="al-field">
                              <label htmlFor="exampleDropdownFormEmail1">
                                Seleccione
                              </label>
                              <input
                                type={
                                  (fil.type === "DATETIME" && "date") ||
                                  (fil.type === "FLOAT64INT64" && "number") ||
                                  (fil.type === "STRING" && "text")
                                }
                                name="value2"
                                value={value2}
                                onChange={onChange}
                                id=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end filter-actions">
                        <button
                          onClick={() =>
                            handleSubmit({ value: "remove" }, index)
                          }
                          className="al-btn primary-btn"
                        >
                          Remover Filtro
                        </button>
                        <button
                          onClick={() => handleSubmit(fil, index)}
                          className="al-btn primary-btn"
                        >
                          Aplicar
                        </button>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default FiltrosDashboardMain;
