import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { uiCloseModal4 } from "../actions/ui";
import { FormEvent, useEffect, useState } from "react";
import { useForm } from "../hooks/useForm";
import { loadHojas, newSourceCreateSheet } from "../actions/source";

export const ModalSheets = () => {
  const initialValue = {
    url: "",
    hoja: "",
    rname: "",
  };
  const { mode } = useSelector((state: any) => state.ui);
  const { uid } = useSelector((state: any) => state.auth);
  const { modalOpen4 } = useSelector((state: any) => state.ui);
  const { tables, company, client, sourceActive } = useSelector(
    (state: any) => state.source
  );
  const [select, setSelect] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { FormData, onChange, resetForm, setFormData } = useForm(initialValue);

  const { url, hoja, rname } = FormData;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#ffff",

    border: "2px solid #000",
    boxShadow: 24,
  };

  const closeModal = () => {
    dispatch(uiCloseModal4());

    setSelect(false);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    await dispatch(loadHojas({ url, uid }));
    setLoading(false);
    setSelect(true);
  };

  const sendSource = async () => {
    setLoading(true);
    await dispatch(newSourceCreateSheet({ ...FormData }));
    setLoading(false);
    closeModal();
    resetForm();
  };

  useEffect(() => {}, [sourceActive]);

  return (
    <>
      <Modal
        show={modalOpen4}
        onHide={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Conectar con Google Sheets
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              closeModal();
            }}
            aria-label="Close"
          ></button>
        </div>
        {!select ? (
          <div className="modal_body">
            <form
              className="tab-content"
              onSubmit={onSubmit}
              id="nav-tabContent"
            >
              <div
                className="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                tabIndex={0}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="al-field">
                      <label htmlFor="">URL</label>
                      <input
                        name="url"
                        value={url}
                        onChange={onChange}
                        type="text"
                        placeholder="Escribe aquí"
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Host</label>
                                        <input type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div> */}
                </div>
              </div>

              <div className="modal-footer p-0 mt-4">
                <button
                  type="submit"
                  disabled={loading}
                  className="al-btn primary-btn show-datafont"
                >
                  {" "}
                  Guardar
                </button>
              </div>
            </form>
            <div></div>
          </div>
        ) : (
          <div className="modal_body">
            <div className="row">
              <div className="col-md-12">
                <div className="al-field">
                  <label htmlFor="">Nombre</label>
                  <input
                    name="rname"
                    value={rname}
                    onChange={onChange}
                    type="text"
                    placeholder="Escribe aquí el nombre que quieras "
                  />
                </div>
              </div>

              {/* <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Host</label>
                                        <input type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div> */}
            </div>
            <div className="internal-search">
              <h5>Selecciona hoja</h5>
              <select
                name="hoja"
                id=""
                onChange={onChange}
                className="input_search mb-4"
              >
                <option value="">Seleccione...</option>
                {tables &&
                  tables.map((table: any) => (
                    <option value={table.value}>{table.label}</option>
                  ))}
              </select>

              <div className="internal-search">
                <h5>Selecciona cliente</h5>
                <select
                  name="client"
                  id=""
                  onChange={onChange}
                  className="input_search mb-4"
                >
                  <option value="">Seleccione...</option>
                  {client &&
                    client.map((table: any) => (
                      <option value={table._id}>{table.name}</option>
                    ))}
                </select>
              </div>
              <div className="internal-search">
                <h5>Selecciona compañia</h5>
                <select
                  name="company"
                  id=""
                  onChange={onChange}
                  className="input_search mb-4"
                >
                  <option value="">Seleccione...</option>
                  {company &&
                    company.map((table: any) => (
                      <option value={table._id}>{table.name}</option>
                    ))}
                </select>
                <div className="modal-footer p-0 mt-4">
                  <button
                    type="button"
                    onClick={() => sendSource()}
                    disabled={loading}
                    className="al-btn primary-btn show-datafont"
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
