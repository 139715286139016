import React from 'react'

import Modal from 'react-bootstrap/Modal';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useEffect, useState } from 'react';
import { uiCloseModal, uiCloseModal3 } from '../actions/ui';
import { useSelector, useDispatch } from 'react-redux';
const baseUrl = process.env.REACT_APP_URL;
export const ModalShare = () => {


    const { modalOpen3 } = useSelector( (state:any) => state.ui );
    const {chartActive } = useSelector( (state:any) => state.chart );
    const dispatch = useDispatch();
    const [copy, setCopy] = useState<String>('Copiar Link')
    const { dashboardActive } = useSelector((state: any) => state.dashboard);
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: '#ffff',
      
        border: '2px solid #000',
        boxShadow: 24,
       
      };



    const closeModal = () => {
      
        dispatch( uiCloseModal3() )
      
        
     
    }
    
   const handleCopy =()=>{
     setCopy('Copiado')
     setTimeout(() => {
        setCopy('Copiar Link')
     }, 500);
   }
    useEffect(() => {
      
    
    }, [chartActive,dashboardActive])
    




    
  return (
    <>


      
      <Modal
        show={modalOpen3}
       onHide={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
      >
      
     
      
        <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"> {dashboardActive?._id?'Comparte tu dashboard': 'Comparte tus gráficas'}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>{closeModal()}} aria-label="Close"></button>
                </div>
             <div className="modal_body"> 
                           <div className=' row'>

                                <div className='col-6'><a target={'_blank'} href={`https://web.whatsapp.com/send?text=Mira%20mi%20${dashboardActive?._id?'Dashboard':'Gráfica'}%20${baseUrl}${dashboardActive?._id?'dashboard':'chart'}/${dashboardActive?._id?dashboardActive?._id:chartActive?._id}`} className='al-btn primary-btn line-btn w-100'><svg style={{marginRight:'6px'}} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-whatsapp" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#0070d6" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
  <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
</svg> Compartir wp</a> </div>
                                <div className='col-6'>
                                <CopyToClipboard text={`${ baseUrl}${dashboardActive?._id?'dashboard':'chart'}/${dashboardActive?._id?dashboardActive?._id:chartActive?._id}`}>
                                     <button onClick={()=>handleCopy()}  className='al-btn primary-btn w-100'  ><svg style={{marginRight:'6px'}} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <rect x="8" y="8" width="12" height="12" rx="2" />
                                    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                                    </svg> {copy}</button>   
                                   
       
         
                                    </CopyToClipboard>

                                
                                </div>

                            
                            
                            </div>      
                                        </div>
                                             
                    
                      
   
        
   
    
 
      </Modal>
   


    </>
  )
}
