
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { uiCloseModal, uiCloseModal2 } from '../actions/ui';
import { FormEvent, useEffect, useState } from 'react';
import { useForm } from '../hooks/useForm';
import { loadTables, newSourceCreate, sourceClearActive, updateSource } from '../actions/source';

export const ModalSource = () => {

    const initialValue={
        name:'',
        port:'',
        dbName:'',
        username:'',
        password:'',
        ssl:false,
        url:'',
        host:'',
        tableName:'',
        client:'',
        company:'',
    };
    
    const { uid } = useSelector( (state:any) => state.auth );
    const { modalOpen,modalOpen2 } = useSelector( (state:any) => state.ui );
    const { tables,company,client,sourceActive } = useSelector( (state:any) => state.source );
    const [select, setSelect] = useState(false)
    const dispatch = useDispatch();
    const [viewPassword, setViewPassword] = useState(false)
    const { FormData, onChange,resetForm,setFormData }= useForm(initialValue);

    const { name,port,dbName,username, password,ssl,host,url }= FormData;
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: '#ffff',
      
        border: '2px solid #000',
        boxShadow: 24,
       
      };



    const closeModal = () => {
        dispatch(sourceClearActive())
        dispatch( uiCloseModal() )
        dispatch( uiCloseModal2() )
        setSelect(false)
        
     
    }
    
   

    const onSubmit = async(event:FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        if (sourceActive._id) {
             dispatch(updateSource(FormData))
             closeModal();
        }else{
            await dispatch(loadTables({dbName,username,password,host,uid}))
        setSelect(true)
        }
        
    }

    const sendSource =async()=>{
        if (sourceActive._id) {
            dispatch(updateSource(FormData))
            closeModal();
       }else{
        await dispatch(newSourceCreate({...FormData,userid:uid}));
         closeModal();
         resetForm(); }

      
    }
 
    useEffect(() => {
      
    if (sourceActive._id) {
        setFormData(sourceActive)
        if (modalOpen2) {
            setSelect(true)
        }
    }
    else{
        setFormData(initialValue)
       
    }
    
    }, [sourceActive])
    
  return (
    <>


      
      <Modal
        show={modalOpen}
        onHide={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
      >
      
     

        <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{sourceActive._id ?' Editar fuente de datos':' Conectar fuente de datos'}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>{closeModal()}} aria-label="Close"></button>
                </div>
              {!select ? <div className="modal_body">
                    <div className="nav options-tabs" id="nav-tab" role="tablist">
                        <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                            aria-selected="true">Basica</button>
                        <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                            type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Url de
                            JDCB</button>
                    </div>
                    <form className="tab-content" onSubmit={onSubmit} id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel"
                            aria-labelledby="nav-home-tab" tabIndex={0}>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="al-field">
                                        <label htmlFor="">Nombre</label>
                                        <input name="name" value={name} onChange={onChange} type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="al-field">
                                        <label htmlFor="">Puerto</label>
                                        <input name="port" value={port} onChange={onChange} type="text"/>
                                    </div>
                                </div>
                                {/* <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Host</label>
                                        <input type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div> */}
                                <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Base de datos</label>
                                        <input name="dbName" value={dbName} onChange={onChange} type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div>
                               
                                <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Nombre de usuario</label>
                                        <input name="username" value={username} onChange={onChange} type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Contraseña</label>
                                        <div className='position-relative'>
                                        <input name="password" value={password} onChange={onChange} type={viewPassword ? 'text' :"password"} placeholder="Escribe aquí"/>
                                        <button className='viewPassword' type='button' onClick={()=> setViewPassword(!viewPassword)}>
                                    {viewPassword ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0070d6" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <line x1="3" y1="3" x2="21" y2="21" />
                                        <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                        <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                    </svg>
                                    )
                                    :(
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0070d6" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <circle cx="12" cy="12" r="2" />
                                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                    </svg>
                                    )
                                    }
                                
                            </button>
                            </div>  
                                    </div>
                                    {/* <div className="al-field checkbox">
                                        <input name="ssl" value={`ssl`} onChange={onChange} type="checkbox" id="ssl-1"/>
                                        <label htmlFor="ssl-1">Habilitar SSL</label>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"
                            tabIndex={0}>
                            <div className="row">

                                <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Url</label>
                                        <input name="url" value={url} onChange={onChange} type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Nombre de usuario</label>
                                  
                                        <input name="username" value={username} onChange={onChange} type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Contraseña</label>
                                        <div className='position-relative'>
                                        <input name="password" value={password} onChange={onChange} type={viewPassword ? 'text' :"password"} placeholder="Escribe aquí"/>
                                        <button className='viewPassword' type='button' onClick={()=> setViewPassword(!viewPassword)}>
                                    {viewPassword ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0070d6" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <line x1="3" y1="3" x2="21" y2="21" />
                                        <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                                        <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                                    </svg>
                                    )
                                    :(
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0070d6" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <circle cx="12" cy="12" r="2" />
                                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                    </svg>
                                    )
                                    }
                                
                            </button>
                            </div>  
                                    </div>
                                    {/* <div className="al-field checkbox">
                                        <input name="ssl" value={'ssl'} onChange={onChange} type="checkbox" id="ssl-2"/>
                                        <label htmlFor="ssl-2">Habilitar SSL</label>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="al-field mb-0">
                            <label htmlFor="">Host</label>
                            <input name="host" value={host} onChange={onChange} type="text" placeholder="Escribe aquí"/>
                        </div>
                        <div className="modal-footer p-0 mt-4">
              
                                <button type="submit" className="al-btn primary-btn show-datafont"
                                    >{sourceActive._id ?' Guardar':' Continuar'}</button>
                            </div>
                    </form>
                    <div>

                    </div>
                </div>:<div className="modal_body"> 
                                     {!modalOpen2 && <div className="internal-search">
                                        <h5 >Selecciona tabla</h5>
                                        <select name="tableName" id="" onChange={onChange} className='input_search mb-4'>
                                        <option value=''>Seleccione...</option>
                                        {tables && tables.map((table:any)=>(
                                            <option value={table}>{table}</option>
                                        ))}
                                        </select>
                                        <div className="modal-footer p-0 mt-4">
              
                          
                                        </div>
                                    </div> }
                                    <div className="internal-search">
                                        <h5 >Selecciona cliente</h5>
                                        <select name="client" id="" onChange={onChange} className='input_search mb-4'>
                                        <option value=''>Seleccione...</option>
                                        {client && client.map((table:any)=>(
                                            <option value={table._id}>{table.name}</option>
                                        ))}
                                        </select>
                                        <div className="modal-footer p-0 mt-4">
              

                                        </div>
                                    </div>  
                                    <div className="internal-search">
                                        <h5 >Selecciona compañia</h5>
                                        <select name="company" id="" onChange={onChange} className='input_search mb-4'>
                                        <option value=''>Seleccione...</option>
                                        {company && company.map((table:any)=>(
                                            <option value={table._id}>{table.name}</option>
                                        ))}
                                        </select>
                                        <div className="modal-footer p-0 mt-4">
              
                                        <button type="button" onClick={()=>sendSource()} className="al-btn primary-btn show-datafont"
                                        >Continuar</button>
                                        </div>
                                    </div>           
                    
                       </div>}
   
        
   
    
 
      </Modal>
   


    </>
  )
}
