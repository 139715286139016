import React, { useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";

function MenuFilterDashboard({
  namefilter,
  onChange,
  handleSelecttypeFilter,
  graficos,
  handleSelect,
  layout,
  typeFilter,
  handleRemoveGraf,
  handleAddGraf,
  handleSaveFilter,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Dropdown show={isOpen} onToggle={(isOpen) => setIsOpen(isOpen)}>
        <Dropdown.Toggle
          className="add-filter al-btn primary-btn line-btn"
          variant="none"
          id="dropdown-custom-components"
        >
          Nuevo filtro <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="ms-2"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </Dropdown.Toggle>
        {/* <button
        type="button"
        id="filter-button-set-1"
        className="filter-item px-2"
        onClick={toggleDropdown}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="12" y1="5" x2="12" y2="19" />
          <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
      </button> */}
        <Dropdown.Menu
          show={isOpen}
          ref={dropdownRef}
          className="filter-container mt-2"
          aria-labelledby="filter-button-set-1"
        >
          {/* Contenido del menú desplegable */}

          <div className="px-4 py-3">
            <h4>Nuevo filtro</h4>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="al-field">
                  <label htmlFor="exampleDropdownFormEmail1">
                    Nombre del filtro
                  </label>
                  <input
                    type="text"
                    name="namefilter"
                    placeholder="Escriba el nombre del filtro"
                    value={namefilter}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="al-field">
                  <label htmlFor="exampleDropdownFormEmail1">
                    Tipo de filtro
                  </label>
                  <select name="" id="" onChange={handleSelecttypeFilter}>
                    <option>Selecione...</option>
                    <option value="DATETIME">Fecha</option>
                    <option value="STRING">Texto</option>
                    <option value="FLOAT64INT64">Numero</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="al-field mb-2">
                  <label htmlFor="sss">
                    {`Selección de gráficos (${graficos.length})`}
                  </label>
                </div>
                <div className="graphic-selector">
                  {graficos.length > 0 &&
                    graficos.map((i, index) => (
                      <div className="d-flex select-graph-item align-items-end">
                        <div className="row w-100">
                          <div className="col-md-6">
                            <div className="al-field mb-0">
                              <label htmlFor="exampleDropdownFormEmail1">
                                Gráfico
                              </label>
                              <select
                                name="name"
                                id=""
                                onChange={(e) => handleSelect(e, index)}
                              >
                                <option>Selecione...</option>
                                {layout.length > 0 &&
                                  layout.map(
                                    (i, e) =>
                                      i?.chart?.name && (
                                        <option
                                          value={e}
                                          selected={
                                            graficos[index].name ===
                                            i?.chart?.name.toLocaleLowerCase()
                                          }
                                        >
                                          {i?.chart?.name.toLocaleLowerCase()}
                                        </option>
                                      )
                                  )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="al-field mb-0">
                              <label htmlFor="exampleDropdownFormEmail1">
                                Campo
                              </label>
                              <select
                                name="campo"
                                id=""
                                onChange={(e) => handleSelect(e, index, 1)}
                              >
                                <option value="">Seleccione...</option>

                                {layout[graficos[index].index]?.chart
                                  ?.selectFilter &&
                                  layout[
                                    graficos[index].index
                                  ]?.chart?.selectFilter.map(
                                    (i) =>
                                      i?.column_name &&
                                      typeFilter.includes(i?.data_type) && (
                                        <option value={i?.column_name}>
                                          {i?.column_name}
                                        </option>
                                      )
                                  )}
                              </select>
                            </div>
                          </div>
                        </div>

                        <button
                          type="button"
                          onClick={() => handleRemoveGraf(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#2c3e50"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                          </svg>
                        </button>
                      </div>
                    ))}
                  <hr />
                  <button
                    type="button"
                    className="al-btn secondary-btn"
                    onClick={() => handleAddGraf()}
                  >
                    Añadir gráfico
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end filter-actions">
              <button type="submit" className="al-btn tertiary-btn">
                Borrar filtro
              </button>
              <button
                onClick={() => {
                  handleSaveFilter();
                  handleCloseDropdown();
                }}
                className="al-btn primary-btn"
              >
                Guardar filtro
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default MenuFilterDashboard;
