import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  Filler,
  ArcElement,
} from "chart.js";
import { useRef, useState, MouseEvent, useEffect } from "react";
import { Bar, Doughnut, getElementAtEvent, Line, Pie } from "react-chartjs-2";
import { ColorModal } from "../components/ColorModal";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { handlePercent, totalValue } from "../../../helpers/handlePercent";
import { validateDateFormat } from "../../../helpers/validateDateFormat";
import { useHandleColorNotFound } from "../../../hooks/useHandleColorNotFound";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

interface Label {
  labelx: string;
  labely: string;
}

interface Props {
  propsData: any[];
  label: Label;
  typeGraphic:
    | "VerticalBarChart"
    | "HorizontalBarChart"
    | "LineChart"
    | "AreaChart"
    | "PieChart"
    | "DoughnutChart";
  arrayColors: string[];
  conditional: boolean;
  setColors?: (state: string[]) => void;
  data2?: any[];
  claves?: string[];
  colorDoble?: any[];
  setColorDoble?: (state: any) => void;
  valuesGraph?: any;
  ejexname?: string;
  ejeyname?: string;
}

export const Charts = ({
  propsData,
  label,
  typeGraphic,
  arrayColors,
  conditional,
  setColors,
  data2,
  claves,
  colorDoble,
  setColorDoble,
  valuesGraph,
  ejexname,
  ejeyname,
}: Props) => {
  if (!propsData?.[0]?.[label.labelx] && propsData.length > 0) {
    label.labelx = `${label.labelx}x`;
  }

  const [
    { color, index, title, modalIsOpen, datasetIndex },
    setPropsColorModal,
  ] = useState({
    color: "",
    index: 0,
    title: "",
    modalIsOpen: false,
    datasetIndex: null,
  });
  const [pipe, setPipe] = useState<any>();
  const chartRef: any = useRef();

  const cambiarData = () => {
    if (typeGraphic === "PieChart" || typeGraphic === "DoughnutChart") {
      const data = [...propsData];

      if (data?.length > 10 && label?.labely !== label.labelx) {
        data?.sort((a, b) => b[label?.labely] - a[label?.labely]);
        const top9 = data?.slice(0, 9);
        const otrosCantidad = data
          ?.slice(9)
          .reduce((total, obj) => total + obj[label?.labely], 0);

        const otros = {
          [`${label?.labelx}`]: "otros",
          [label?.labely]: otrosCantidad,
        };
        const result = [...top9, otros];
        setPipe(result?.sort((a, b) => b[label?.labely] - a[label?.labely]));
      } else {
        if (propsData?.length < 1) {
          setPipe([{ altumpipe: "" }]);
        } else {
          setPipe(propsData);
        }
      }
    }
  };

  useHandleColorNotFound(arrayColors.length, propsData, setColors);

  useEffect(() => {
    if (typeof setColorDoble !== "function") {
      return;
    }
    if (colorDoble.length < 1) {
      const colors = ["blue", "yellow", "red", "green", "orange", "purple"];
      const newColors = claves?.map((a, i) => data2.map(() => colors[i]));
      setColorDoble(newColors);
    }
  }, [1]);

  useEffect(() => {}, [claves, typeGraphic, arrayColors, pipe]);

  useEffect(() => {
    if (!data2 || data2?.length < 0) {
      cambiarData();
    }
  }, [typeGraphic, label.labelx, label.labely, propsData]);

  const options: any = {
    indexAxis:
      typeGraphic === "HorizontalBarChart" ? ("y" as const) : ("x" as const),
    plugins: {
      legend: {
        labels: {
          color: valuesGraph?.legendColor,
          font: {
            size:
              valuesGraph?.legendSize === "small"
                ? 13
                : valuesGraph?.legendSize === "medium"
                ? 18
                : 22,
            style:
              valuesGraph?.legendStyle === "oblique"
                ? valuesGraph?.legendStyle
                : "normal",
            weight:
              valuesGraph?.legendStyle === "oblique"
                ? "normal"
                : valuesGraph?.legendStyle,
          },
        },
        position:
          valuesGraph?.positionLabel === "bottom"
            ? ("bottom" as const)
            : valuesGraph?.positionLabel === "right"
            ? ("right" as const)
            : valuesGraph?.positionLabel === "left"
            ? ("left" as const)
            : ("top" as const),
      },
      datalabels: {
        display: valuesGraph?.positionValue !== "hidden",
        color: valuesGraph?.valueColor,
        font: {
          weight:
            valuesGraph?.valueStyle === "oblique"
              ? "normal"
              : valuesGraph?.valueStyle,
          size:
            valuesGraph?.valueSize === "small"
              ? 15
              : valuesGraph?.valueSize === "medium"
              ? 20
              : 24,
          style:
            valuesGraph?.valueStyle === "oblique"
              ? valuesGraph?.valueStyle
              : "normal",
        },
        anchor: valuesGraph?.positionValue === "center" ? "center" : "end",
        clamp: true,
        align:
          valuesGraph?.positionValue === "center"
            ? "center"
            : valuesGraph?.positionValue === "tangent"
            ? "start"
            : "end",
        formatter: (value) => {
          const isLessTo10Percent = handlePercent(
            value,
            totalValue(propsData, label.labely)
          );
          if (
            parseInt(isLessTo10Percent) <= 8 &&
            (typeGraphic === "PieChart" || typeGraphic === "DoughnutChart")
          )
            return "";
          if (!conditional) return value;

          return handlePercent(value, totalValue(propsData, label.labely));
        },
      },
    },
    layout: {
      autoPadding: true,
      padding: {
        right: 40,
        left: 10,
        top: 40,
        bottom: 20,
      },
    },
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 2,
        borderRadius: 4,
        borderSkipped: "bottom",
        borderColor: "transparent",
        borderCapStyle: "round",
        borderDashOffset: 0.0,
        borderJoinStyle: "round",
        fill: false,
        gradient: {
          // Especifica el tipo de gradiente a utilizar (en este caso, un gradiente vertical)
          orientation: "vertical",
          from: "rgba(255, 99, 132, 0.8)",
          to: "rgba(54, 162, 235, 0.8)",
        },
      },
    },
  };

  if (typeGraphic !== "DoughnutChart" && typeGraphic !== "PieChart") {
    options.scales = {
      x: {
        id: "x-axis-0",
        title: {
          display: true,
          text: ejexname,
          font: {
            size:
              valuesGraph?.axisSize === "small"
                ? 13
                : valuesGraph?.axisSize === "medium"
                ? 18
                : 22,
            weight:
              valuesGraph?.axisStyle === "oblique"
                ? "normal"
                : valuesGraph?.axisStyle,
            style:
              valuesGraph?.axisStyle === "oblique"
                ? valuesGraph?.axisStyle
                : "normal",
          },
          color: valuesGraph?.axisColor,
        },
        ticks: {
          font: {
            style:
              valuesGraph?.labelStyle === "oblique"
                ? valuesGraph?.labelStyle
                : "normal",
            size:
              valuesGraph?.labelSize === "small"
                ? 13
                : valuesGraph?.labelSize === "medium"
                ? 18
                : 22,
            weight:
              valuesGraph?.labelStyle === "oblique"
                ? "normal"
                : valuesGraph?.labelStyle,
          },
          color: valuesGraph?.labelColor,
        },
      },
      y: {
        id: "y-axis-0",
        title: {
          display: true,
          text: ejeyname,
          font: {
            size:
              valuesGraph?.axisSize === "small"
                ? 13
                : valuesGraph?.axisSize === "medium"
                ? 18
                : 22,
            weight:
              valuesGraph?.axisStyle === "oblique"
                ? "normal"
                : valuesGraph?.axisStyle,
            style:
              valuesGraph?.axisStyle === "oblique"
                ? valuesGraph?.axisStyle
                : "normal",
          },
          color: valuesGraph?.axisColor,
        },
      },
    };
  }

  const isDate =
    data2?.length >= 1
      ? data2.map((x: unknown) => x[label.labelx])[0]
      : propsData?.map((x: unknown) => x[label.labelx])[0];
  const axisX = validateDateFormat(
    isDate,
    data2?.length > 0
      ? data2
      : pipe?.length > 0
      ? !pipe[0][label.labelx]
        ? []
        : pipe
      : propsData,
    label.labelx,
    typeGraphic === "DoughnutChart" || typeGraphic === "PieChart" ? true : false
  );

  const data: any = {
    labels: axisX ? axisX : [],
    datasets: [
      {
        fill: typeGraphic === "AreaChart",
        label: label.labely,
        data:
          pipe?.length > 0
            ? pipe?.map((y) => y[label.labely])
            : propsData?.map((y) => y[label.labely]),
        borderColor:
          typeGraphic === "LineChart" || typeGraphic === "AreaChart"
            ? arrayColors?.[0]
            : "#e5e5e5",
        borderWidth: 2,
        backgroundColor: (context: any) => {
          const chart = context?.chart;
          const ctx = chart?.ctx;
          // Crear gradiente

          if (!valuesGraph.colorDegrade) {
            const index = context?.index;
            return typeGraphic === "LineChart" || typeGraphic === "AreaChart"
              ? arrayColors.map((x: string | null) => {
                  if (arrayColors[0] === null || arrayColors[0] === undefined)
                    return "#e5e5e5";
                  return arrayColors[0];
                })[index] ?? "#e5e5e5"
              : arrayColors.map((x: string | null) => {
                  if (x === null || x === undefined) return "#e5e5e5";
                  return x;
                })[index] ?? "#e5e5e5";
          }

          if (
            ctx &&
            chart?.chartArea?.left &&
            chart?.chartArea?.top &&
            chart?.chartArea?.right &&
            chart?.chartArea?.bottom
          ) {
            const gradient = ctx?.createLinearGradient(0, 0, 0, 400);

            const index = context?.index;
            const color =
              typeGraphic === "LineChart" || typeGraphic === "AreaChart"
                ? arrayColors[index] ?? "#e5e5e5"
                : arrayColors[index] ?? "#e5e5e5";

            function hexToRgba(hex, opacity) {
              function isRgbaColor(color) {
                return /^rgba/.test(color);
              }

              if (isRgbaColor(hex)) {
                const rgba = hex;
                const newRgba = rgba.replace(/[^,]+(?=\))/, opacity.toString());

                return newRgba;
              }
              hex = hex.replace("#", "");
              const r = parseInt(hex.substring(0, 2), 16);
              const g = parseInt(hex.substring(2, 4), 16);
              const b = parseInt(hex.substring(4, 6), 16);

              return `rgba(${r}, ${g}, ${b}, ${opacity})`;
            }

            gradient?.addColorStop(0, hexToRgba(color, 1));
            gradient?.addColorStop(0.2, hexToRgba(color, 1));
            gradient?.addColorStop(1, hexToRgba(color, 0.2));
            return gradient;
          }
          return "";
        },

        borderRadius: 5,
      },
    ],
  };
  let datasets2 = [];

  claves?.forEach((e, i) => {
    datasets2.push({
      fill: typeGraphic === "AreaChart",
      label: e.toLocaleLowerCase(),
      data: data2?.map((y) => y[e.toLocaleLowerCase()]),
      borderColor: "#e5e5e5",
      borderWidth: 2,
      backgroundColor: (context: any) => {
        const chart = context?.chart;
        const ctx = chart?.ctx;

        if (!valuesGraph.colorDegrade) {
          return colorDoble.length > 1 ? colorDoble[i] : "#e5e5e5";
        }

        if (
          ctx &&
          chart?.chartArea?.left &&
          chart?.chartArea?.top &&
          chart?.chartArea?.right &&
          chart?.chartArea?.bottom
        ) {
          const gradient = ctx?.createLinearGradient(0, 0, 0, 400);
          const index = context?.index;
          const color = colorDoble[i][index] ?? "#e5e5e5";

          function hexToRgba(hex, opacity) {
            function isRgbaColor(color) {
              return /^rgba/.test(color);
            }

            if (isRgbaColor(hex)) {
              const rgba = hex;
              const newRgba = rgba.replace(/[^,]+(?=\))/, opacity.toString());

              return newRgba;
            }
            hex = hex.replace("#", "");
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);

            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
          }

          gradient?.addColorStop(0, hexToRgba(color, 1));
          gradient?.addColorStop(0.2, hexToRgba(color, 1));
          gradient?.addColorStop(1, hexToRgba(color, 0.2));
          return gradient;
        }
      },
      borderRadius: 5,
    });
  });

  const dataPrueba = {
    labels: axisX,
    datasets: datasets2,
  };

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    let valuesGraphColorIndividual =
      data2.length >= 1 ? true : valuesGraph.colorIndividual;
    if (
      !valuesGraphColorIndividual ||
      typeGraphic === "AreaChart" ||
      typeGraphic === "LineChart"
    )
      return;

    const bar: any = getElementAtEvent(chartRef.current, event);
    if (bar[0]) {
      let color = bar[0].element.options.backgroundColor;
      if (typeof color !== "string") {
        color = arrayColors[bar[0].index];
      }
      const index = bar[0].index;
      const datasetIndex = bar[0].datasetIndex;

      setPropsColorModal({
        color,
        index,
        title: propsData[index][label.labelx],
        modalIsOpen: true,
        datasetIndex,
      });
    }
  };

  if (propsData === undefined) {
    return;
  }

  if (
    typeGraphic === "VerticalBarChart" ||
    typeGraphic === "HorizontalBarChart"
  )
    return (
      <>
        <Bar
          options={options}
          data={data2?.length >= 1 ? dataPrueba : data}
          ref={chartRef}
          onClick={onClick}
          plugins={[ChartDataLabels]}
        />
        <ColorModal
          modalIsOpen={modalIsOpen}
          title={title}
          color={color}
          setPropsColorModal={setPropsColorModal}
          setColors={setColors}
          index={index}
          barColors={arrayColors}
          doubleBar={data2?.length > 1}
          doubleColor={colorDoble}
          setDoubleColor={setColorDoble}
          datasetIndex={datasetIndex}
        />
      </>
    );

  if (typeGraphic === "LineChart" || typeGraphic === "AreaChart")
    return (
      <>
        <Line options={options} data={data} ref={chartRef} onClick={onClick} />{" "}
        <ColorModal
          modalIsOpen={modalIsOpen}
          title={title}
          color={color}
          setPropsColorModal={setPropsColorModal}
          setColors={setColors}
          index={index}
          barColors={arrayColors}
        />
      </>
    );

  if (typeGraphic === "PieChart")
    return (
      <>
        {" "}
        <Pie data={data} options={options} ref={chartRef} onClick={onClick} />
        <ColorModal
          modalIsOpen={modalIsOpen}
          color={color}
          title={title}
          setPropsColorModal={setPropsColorModal}
          setColors={setColors}
          index={index}
          barColors={arrayColors}
        />
      </>
    );

  if (typeGraphic === "DoughnutChart")
    return (
      <>
        <Doughnut
          data={data}
          options={options}
          ref={chartRef}
          onClick={onClick}
        />
        <ColorModal
          title={title}
          modalIsOpen={modalIsOpen}
          color={color}
          setPropsColorModal={setPropsColorModal}
          setColors={setColors}
          index={index}
          barColors={arrayColors}
        />
      </>
    );
};
