import { dispatch } from 'd3'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../actions/auth';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import logo from '../assets/img/logo-altum.png';
import { useState, useEffect } from 'react';
import { uimode } from '../actions/ui';
export const Header = () => {
    const { name,rol } = useSelector( (state:any) => state.auth );
    const { mode } = useSelector( (state:any) => state.ui );
    const dispatch = useDispatch();

    
     const handleMode = ()=>{
       
        localStorage.setItem('mode',`${!mode}`);
        dispatch(uimode(!mode))


     }
     useEffect(() => {
        
        const mode=localStorage.getItem('mode');
        if (mode) {
            const body = document.querySelector('body')
            if (mode==='true') {
                body.classList.add('dark-mode')
                dispatch(uimode(true))
            }else {
                body.classList.remove('dark-mode')
                dispatch(uimode(false))
            }
        }else{
            localStorage.setItem('mode',`false`);

        }
    
     
     
     }, [mode])
     
  return (
   <>
         <header  className={`${mode?`dark-mode`:''}`}>
        <div className="header-content">
           
            <form action="">
                {/* <input type="text" placeholder="Buscar..."/>
                <button><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="10" cy="10" r="7" />
                        <line x1="21" y1="21" x2="15" y2="15" />
                    </svg></button> */}
            </form>
            <div className="center-vertical">
            <div className="switch-mode">
                    <input id="v-mode" checked={mode} onChange={handleMode} type="checkbox"/>
                    <label htmlFor="v-mode"></label>
                </div>
                <div className="header-links">
                
                <button className='colorButton'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                            stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4" />
                            <line x1="12" y1="19" x2="12" y2="19.01" />
                        </svg>
                    </button>
                    <button className='colorButton'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                            stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                            <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                        </svg>
                    </button>
                    
                </div>
                <div className="header-profile">
                    <div className="profile-img img-fit">
                        <img src={logo}
                            alt=""/>
                    </div>
                    <div className="profile-data">
                        <p>{name}</p>
                        <span>{rol} </span>
                        {/* <span style={{cursor:'pointer', fontWeight:'bold',color:'#0070d6'}} onClick={()=>dispatch(startLogout())} >
                             log out
                        </span> */}
                    </div>
                </div>
            </div>

        </div>

    </header>
   </>
  )
}
