export const types={
    

    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authStartRegister: '[auth] Start Register',
   
    authStartStartTokenRenew: '[auth] Start token renew',
    authLogout: '[auth] Logout',

    tableLoad: '[table] table loaded',
    clientLoad: '[client] client loaded',
    globalSourcesLoad: '[global] globalSources loaded',
    viewLoad: '[view] view loaded',
    companyLoad: '[company] company loaded',
    newSource: '[source] new source',
    loadSource: '[source] load source2',
    sourceSetActive: '[source] Set Active',
    sourceClearActiveSource: '[source] Clear active ',
    sourceUpdated: '[source] source updated',
    sourceDeleted: '[source] source deleted',
    sourceLoaded: '[source] sources loaded',
    sourceSetTotalpages: '[source] Set Total pages',
    
    loadCharts: '[Chart] load charts',
    chartSetActive: '[chart] Set Active',
    chartClearActiveChart: '[chart] Clear active ',
    chartSetTotalpages: '[chart] Set Total pages',

    loadDashboard: '[Dashboard] load Dashboards',
    DashboardSetActive: '[Dashboard] Set Active',
    DashboardSetTotalpages: '[Dashboard] Set Total pages',
    DashboardClearActiveDashboardt: '[Dashboard] Clear active ',
    // sourceUpdated: '[source] source updated',
    // sourceDeleted: '[source] source deleted',
    // sourceLoaded: '[source] sources loaded',
    uimode: '[ui] mode',
    uiChangemode: '[ui] change mode',
    uiOpenModal: '[ui] Open modal',
    uiOpenModal2: '[ui] Open modal2',
    uiOpenModal3: '[ui] Open modal3',
    uiOpenModal4: '[ui] Open modal4',
    uiOpenModal5: '[ui] Open modal5',
    uiOpenModal6: '[ui] Open modal6',
    uiOpenModal7: '[ui] Open modal7',
    uiOpenModal8: '[ui] Open modal8',
    uiOpenModal9: '[ui] Open modal9',
    uiOpenModal10: '[ui] Open modal10',
    uiOpenModal11: '[ui] Open modal11',
    uiCloseModal: '[ui] Close modal',
    uiCloseModal2: '[ui] Close modal2',
    uiCloseModal3: '[ui] Close modal3',
    uiCloseModal4: '[ui] Close modal4',
    uiCloseModal5: '[ui] Close modal5',
    uiCloseModal6: '[ui] Close modal6',
    uiCloseModal7: '[ui] Close modal7',
    uiCloseModal8: '[ui] Close modal8',
    uiCloseModal9: '[ui] Close modal9',
    uiCloseModal10: '[ui] Close modal10',
    uiCloseModal11: '[ui] Close modal11',



    }