import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _, { forEach } from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import imp from "../../../assets/img/import-chart.png";
import { useDispatch, useSelector } from "react-redux";
import { uiOpenModal6 } from "../../../actions/ui";
import { Charts } from "../handleCharts/index";
import { TableChart } from "../TableChart";

import ReactQuill from "react-quill";
import { TextEditor } from "./TextEditor";
import { Dropdown } from "react-bootstrap";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const ShowcaseLayout = (props) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");

  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [layouts, setLayouts] = useState({ lg: props.initialLayout });
  const dispatch = useDispatch();

  const { dashboardActive } = useSelector((state: any) => state.dashboard);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {}, []);

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  };

  const onLayoutChange = (layout, layouts) => {
    const newLayout = [...layout];

    newLayout?.map((item, i) => {
      if (props?.initialLayout[i]?.chart) {
        item.chart = props?.initialLayout[i]?.chart;
      }
      if (props?.initialLayout[i]?.text) {
        item.text = props?.initialLayout[i]?.text;
      }
      if (props?.initialLayout[i]?.image) {
        item.image = props?.initialLayout[i]?.image;
      }
    });

    props.onLayoutChange(newLayout);
  };

  const removeNew = (index) => {
    props.onLayoutChange(props?.initialLayout.filter((i, e) => e !== index));
    setLayouts({ lg: layouts.lg });
  };
  const onFileChanges = (event: any, i) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function load() {
      const objectToModify = { ...props?.initialLayout[i] };

      objectToModify.image = reader.result;

      const newArray = [...props?.initialLayout];
      newArray[i] = objectToModify;
      props.onLayoutChange(newArray);
    };
  };

  return (
    <div>
      <div className="row">
        <div className="p-0">
          <ResponsiveReactGridLayout
            {...props}
            layouts={props.initialLayout}
            onBreakpointChange={onBreakpointChange}
            onLayoutChange={onLayoutChange}
            measureBeforeMount={false}
            useCSSTransforms={mounted}
            compactType={compactType}
            preventCollision={!compactType}
            margin={[25, 25]}
            draggableCancel=".no-drag"
          >
            {props?.initialLayout?.map((item, i) => (
              <div
                key={i}
                data-grid={item}
                className={item?.text ? "text-field-container" : ""}
              >
                {item?.chart?.data ? (
                  <div className={`h-100 `}>
                    {/* <div className='inner-structure'></div> */}
                    <div className="chart-item">
                      <div className="chart-headline">
                        <div className="chart-name">
                          <div className="graph-type">
                            {item.chart.typegraf === "barchart" && (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <rect
                                    x="3"
                                    y="12"
                                    width="6"
                                    height="8"
                                    rx="1"
                                  ></rect>
                                  <rect
                                    x="9"
                                    y="8"
                                    width="6"
                                    height="12"
                                    rx="1"
                                  ></rect>
                                  <rect
                                    x="15"
                                    y="4"
                                    width="6"
                                    height="16"
                                    rx="1"
                                  ></rect>
                                  <line x1="4" y1="20" x2="18" y2="20"></line>
                                </svg>
                              </>
                            )}
                            {item.chart.typegraf === "table" && (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <rect
                                    x="4"
                                    y="4"
                                    width="16"
                                    height="16"
                                    rx="2"
                                  ></rect>
                                  <line x1="4" y1="10" x2="20" y2="10"></line>
                                  <line x1="10" y1="4" x2="10" y2="20"></line>
                                </svg>
                              </>
                            )}
                            {item.chart.typegraf === "radiuschart" && (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M12 12l-6.5 5.5"></path>
                                  <path d="M12 3v9h9"></path>
                                  <circle cx="12" cy="12" r="9"></circle>
                                  <path d="M12 12l5 7.5"></path>
                                </svg>
                              </>
                            )}
                            {item.chart.typegraf === "linechart" && (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19"></polyline>
                                  <polyline points="4 12 7 8 11 10 16 4 20 8"></polyline>
                                </svg>
                              </>
                            )}
                            {item.chart.typegraf === "horizontalBarChart" && (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <line x1="3" y1="18" x2="17" y2="18"></line>
                                  <path d="M9 9l3 3l-3 3"></path>
                                  <path d="M14 15l3 3l-3 3"></path>
                                  <line x1="3" y1="3" x2="3" y2="21"></line>
                                  <line x1="3" y1="12" x2="12" y2="12"></line>
                                  <path d="M18 3l3 3l-3 3"></path>
                                  <line x1="3" y1="6" x2="21" y2="6"></line>
                                </svg>
                              </>
                            )}
                            {item.chart.typegraf === "donutchart" && (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M12 3v5m4 4h5"></path>
                                  <circle cx="12" cy="12" r="4"></circle>
                                  <circle cx="12" cy="12" r="9"></circle>
                                </svg>
                              </>
                            )}
                            {item.chart.typegraf === "lineChartWithNodes" && (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <line x1="4" y1="19" x2="20" y2="19"></line>
                                  <polyline points="4 15 8 9 12 11 16 6 20 10"></polyline>
                                </svg>
                              </>
                            )}
                          </div>
                          <p>{item.chart.name}</p>
                        </div>
                        {!props.libre && (
                          <div className="chart-actions no-drag">
                            <button
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#2c3e50"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <circle cx="12" cy="12" r="1" />
                                <circle cx="12" cy="19" r="1" />
                                <circle cx="12" cy="5" r="1" />
                              </svg>
                            </button>
                            <ul
                              className="dropdown-menu dropdown-menu-lg-end"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    props.modalOpen(i);
                                  }}
                                >
                                  Importar grafico
                                </button>
                              </li>
                              <li>
                                {" "}
                                <button
                                  className="dropdown-item"
                                  onClick={() => removeNew(i)}
                                >
                                  Eliminar
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="chart-container center-all share-live-data">
                        <div className="no-drag">
                          {item.chart?.typegraf === "barchart" && (
                            <Charts
                              typeGraphic="VerticalBarChart"
                              propsData={item.chart?.data}
                              label={item.chart?.label}
                              arrayColors={item.chart?.stateSectionPay}
                              conditional={item.chart?.typeData}
                              data2={item.chart?.doble}
                              colorDoble={item.chart?.doblecolor}
                              claves={item.chart?.clave}
                              valuesGraph={item?.chart?.valuesGraph}
                              ejeyname={item?.chart?.ejeyname}
                              ejexname={item?.chart?.ejexname}
                            />
                          )}
                          {item.chart?.typegraf === "radiuschart" && (
                            <Charts
                              typeGraphic="PieChart"
                              propsData={item.chart?.data}
                              label={item.chart?.label}
                              arrayColors={item.chart?.stateSectionPay}
                              conditional={item.chart?.typeData}
                              valuesGraph={item?.chart?.valuesGraph}
                              ejeyname={item?.chart?.ejeyname}
                              ejexname={item?.chart?.ejexname}
                            />
                          )}
                          {item.chart?.typegraf === "linechart" && (
                            <Charts
                              typeGraphic="AreaChart"
                              propsData={item.chart?.data}
                              label={item.chart?.label}
                              arrayColors={item.chart?.stateSectionPay}
                              conditional={item.chart?.typeData}
                              valuesGraph={item?.chart?.valuesGraph}
                              ejeyname={item?.chart?.ejeyname}
                              ejexname={item?.chart?.ejexname}
                            />
                          )}
                          {item.chart.typegraf === "donutchart" && (
                            <Charts
                              typeGraphic="DoughnutChart"
                              propsData={item.chart?.data}
                              label={item.chart?.label}
                              arrayColors={item.chart?.stateSectionPay}
                              conditional={item.chart?.typeData}
                              valuesGraph={item?.chart?.valuesGraph}
                              ejeyname={item?.chart?.ejeyname}
                              ejexname={item?.chart?.ejexname}
                            />
                          )}
                          {item.chart.typegraf === "table" && (
                            <TableChart
                              data={item.chart?.data}
                              label={item.chart?.label}
                              table={item.chart?.table}
                            />
                          )}
                          {item.chart.typegraf === "horizontalBarChart" && (
                            <Charts
                              typeGraphic="HorizontalBarChart"
                              propsData={item.chart?.data}
                              label={item.chart?.label}
                              arrayColors={item.chart?.stateSectionPay}
                              conditional={item.chart?.typeData}
                              valuesGraph={item?.chart?.valuesGraph}
                              ejeyname={item?.chart?.ejeyname}
                              ejexname={item?.chart?.ejexname}
                            />
                          )}
                          {item.chart.typegraf === "lineChartWithNodes" && (
                            <Charts
                              typeGraphic="LineChart"
                              propsData={item.chart?.data}
                              label={item.chart?.label}
                              arrayColors={item.chart?.stateSectionPay}
                              conditional={item.chart?.typeData}
                              valuesGraph={item?.chart?.valuesGraph}
                              ejeyname={item?.chart?.ejeyname}
                              ejexname={item?.chart?.ejexname}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : dashboardActive._id && item.chart ? (
                  <p className="placeholder-glow mb-0 h-100">
                    <span className="placeholder rounded-3 col-12 h-100"></span>
                  </p>
                ) : item?.text ? (
                  !props.libre ? (
                    <div className=" text-field">
                      <TextEditor
                        value={item?.text}
                        index={i}
                        rows={props.initialLayout}
                        setRows={props.onLayoutChange}
                      />
                    </div>
                  ) : (
                    <div
                      className=" text-field"
                      dangerouslySetInnerHTML={{ __html: item?.text }}
                    />
                  )
                ) : item?.image ? (
                  <div className="chart-item example squeleton-card">
                    {!props.libre && (
                      <button onClick={() => removeNew(i)} className="remove">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="18" y1="6" x2="6" y2="18" />
                          <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                      </button>
                    )}

                    <div className="h-100 center-all">
                      {!props.libre && (
                        <input
                          id={`image${i}`}
                          type="file"
                          accept=".png,.jpg,.jpeg,.svg,.wepb,.gif"
                          onChange={(e) => onFileChanges(e, i)}
                          className="d-none dd"
                        />
                      )}

                      {item?.image !== "escribe aqui..." ? (
                        <label
                          htmlFor={`image${i}`}
                          className="img-contain no-drag image-select h-100 center-all"
                        >
                          <img src={item?.image} alt="" />
                        </label>
                      ) : (
                        <label
                          htmlFor={`image${i}`}
                          className="img-contain no-drag center-all add-item rounded-3"
                          role="button"
                        >
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="65"
                              height="65"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#51627c"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="d-block m-auto cursor-pointer mb-2"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <line x1="15" y1="8" x2="15.01" y2="8" />
                              <rect x="4" y="4" width="16" height="16" rx="3" />
                              <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
                              <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
                            </svg>
                            <p className="text-center mb-0">Carga una imagen</p>
                          </div>
                        </label>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="chart-item example img-contain squeleton-card">
                    <button onClick={() => removeNew(i)} className="remove">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                      </svg>
                    </button>
                    <button
                        onClick={() => {
                          props.modalOpen(i);
                        }}
                        className="center-all flex-column add-item rounded-3"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 24 24" stroke-width="1.5" className="mb-2" stroke="#51627c" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M12 3v9h9" />
                      <circle cx="12" cy="12" r="9" />
                    </svg>
                        Importar grafico
                      </button>
                  </div>
                )}
              </div>
            ))}
            <div className="chart-item example p-3 img-contain">
              <div className="import-graph">
                <img src={imp} alt="" />
                <button className="al-btn tertiary-btn no-drag">
                  Importar grafico
                </button>
              </div>
            </div>
          </ResponsiveReactGridLayout>
        </div>
      </div>
    </div>
  );
};

ShowcaseLayout.defaultProps = {
  className: "layout",
  rowHeight: 100,
  onLayoutChange: function () {},
  minH: 3,
  cols: { lg: 6, md: 4, sm: 2, xs: 1, xxs: 1 },
};
