import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";

import { FormEvent, useEffect, useState } from "react";
import { useForm } from "../hooks/useForm";

import { uiCloseModal5 } from "../actions/ui";

export const ModalFilter = ({ data, select, setFiltros, filtros }) => {
  const initialValue: any = {
    filterType: "",
    type: "",
    value: 0,
    value2: 0,
    text: "",

    fecha: "",
    fecha2: "",
  };
  const { mode } = useSelector((state: any) => state.ui);
  const { uid } = useSelector((state: any) => state.auth);
  const { modalOpen5 } = useSelector((state: any) => state.ui);
  const { sourceActive } = useSelector((state: any) => state.source);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { FormData, onSelect, onChange, resetForm } = useForm(initialValue);

  const { filterType, type, value, value2, text, fecha, fecha2, key, limit1 } =
    FormData;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#ffff",

    border: "2px solid #000",
    boxShadow: 24,
  };

  const closeModal = () => {
    dispatch(uiCloseModal5());
    resetForm();
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    let consulta: string;
    event.preventDefault();

    if (select === true) {
    } else {
      if (filterType === "" || key === "") {
        closeModal();
      } else {
        if (
          text === "" &&
          fecha === "" &&
          fecha2 === "" &&
          filterType !== "=" &&
          filterType !== ">= && <=" &&
          filterType !== ">=" &&
          filterType !== ">" &&
          filterType !== "<=" &&
          filterType !== "<" &&
          filterType !== "Hoy" &&
          filterType !== "7" &&
          filterType !== "30" &&
          filterType !== "360" &&
          filterType !== "day" &&
          filterType !== "month" &&
          filterType !== "year"
        ) {
          closeModal();
        } else {
          switch (filterType) {
            case "=":
              consulta = `${key} = ${value}`;

              break;

            case ">= && <=":
              consulta = `${key} BETWEEN ${value} AND ${value2}`;
              break;

            case ">=":
              consulta = `${key} >= ${value}`;
              break;

            case ">":
              consulta = `${key} > ${value}`;
              break;

            case "<=":
              consulta = `${key} <= ${value}`;

              break;

            case "<":
              consulta = `${key} < ${value}`;
              break;

            case "Igual que":
              consulta = `${key} = '${text}'`;

              break;

            case "Empieza por":
              consulta = `${key} LIKE '${text}%'`;

              break;

            case "En":
              const arreglo = text.split(",");
              const arreglonuevo = arreglo.map((item) => `'${item}'`);

              consulta = `${key} IN (${arreglonuevo})`;

              break;

            case "Fecha igual ":
              consulta = `Date(${key}) = '${fecha}'`;
              break;

            case "Entre":
              consulta = `Date(${key}) BETWEEN '${fecha}' AND '${fecha2}'`;
              break;

            case "Hoy":
              consulta = `Date(${key}) = CURRENT_DATE()`;
              break;

            case "7":
              consulta = `Date(${key}) BETWEEN CURRENT_DATE() - INTERVAL 7 DAY AND CURRENT_DATE()`;
              break;

            case "30":
              consulta = `Date(${key}) BETWEEN DATE_SUB(CURRENT_DATE(), INTERVAL 1 MONTH) AND CURRENT_DATE()`;
              break;

            case "360":
              consulta = `Date(${key}) BETWEEN DATE_SUB(CURRENT_DATE(), INTERVAL 1 YEAR) AND CURRENT_DATE()`;
              break;

            case "year":
              consulta = `Date(${key}) BETWEEN DATE_SUB(CURRENT_DATE(), INTERVAL ${value} YEAR) AND CURRENT_DATE()`;
              break;

            case "month":
              consulta = `Date(${key}) BETWEEN DATE_SUB(CURRENT_DATE(), INTERVAL ${value} MONTH) AND CURRENT_DATE()`;
              break;

            case "day":
              consulta = `Date(${key}) BETWEEN CURRENT_DATE() - INTERVAL ${value} DAY AND CURRENT_DATE()`;
              break;
            default:
              break;
          }

          setFiltros([...filtros, { ...FormData, sql: consulta }]);
        }
      }
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {}, [sourceActive]);

  return (
    <>
      <Modal
        show={modalOpen5}
        onHide={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {select
              ? "Seleccione un limite para los datos"
              : "Selecciona un campo para el filtro"}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              closeModal();
            }}
            aria-label="Close"
          ></button>
        </div>
        {
          <div className="modal_body">
            <form
              className="tab-content"
              onSubmit={onSubmit}
              id="nav-tabContent"
            >
              {select ? (
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="al-field">
                        <label htmlFor="">Limit</label>

                        <input
                          onChange={onChange}
                          value={limit1}
                          name="limit1"
                          type="number"
                          placeholder="ejemplo: 1"
                        />
                      </div>
                    </div>
                  </div>{" "}
                </div>
              ) : (
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                  tabIndex={0}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="al-field">
                        <label htmlFor="">Campo</label>
                        <select
                          name="type"
                          id=""
                          onChange={onSelect}
                          className="input_search mb-4"
                        >
                          <option value="">Seleccione...</option>
                          {data &&
                            data.map((table: any) => (
                              <option id={table.key} value={table.data_type}>
                                {table.column_name}
                              </option>
                            ))}
                        </select>
                      </div>
                      {type && (
                        <div className="al-field">
                          <label htmlFor="">Filtro</label>
                          <select
                            name="filterType"
                            id=""
                            onChange={onSelect}
                            className="input_search mb-4"
                          >
                            <option value="">Seleccione...</option>
                            {(type === "INT64" || type === "FLOAT64") && (
                              <option value="="> Igual que (=) </option>
                            )}
                            {(type === "INT64" || type === "FLOAT64") && (
                              <option value=">= && <=">
                                {" "}
                                Entre ({">"}=&&{"<"}=){" "}
                              </option>
                            )}
                            {(type === "INT64" || type === "FLOAT64") && (
                              <option value=">=">
                                {" "}
                                Mayor o igual que ({">"}=){" "}
                              </option>
                            )}
                            {(type === "INT64" || type === "FLOAT64") && (
                              <option value=">"> Mayor que ({">"}) </option>
                            )}
                            {(type === "INT64" || type === "FLOAT64") && (
                              <option value="<=">
                                {" "}
                                Menor o igual que ({"<"}=){" "}
                              </option>
                            )}
                            {(type === "INT64" || type === "FLOAT64") && (
                              <option value="<"> Menor que ({"<"}) </option>
                            )}
                            {type === "STRING" && (
                              <option value="Igual que"> Igual que (=) </option>
                            )}
                            {type === "STRING" && (
                              <option value="Empieza por"> Empieza por </option>
                            )}
                            {/* { type==="STRING"    &&    <option value='Coincidencia con la expresión regular'>  Coincidencia con la expresión regular </option>}
                                { type==="STRING"    &&    <option value='La expresión regular contiene'>  La expresión regular contiene </option>} */}
                            {type === "STRING" && (
                              <option value="En"> En </option>
                            )}
                            {(type === "DATETIME" || type === "DATE") && (
                              <option value="Fecha igual ">
                                {" "}
                                Igual que (=){" "}
                              </option>
                            )}
                            {(type === "DATETIME" || type === "DATE") && (
                              <option value="Entre">
                                {" "}
                                Entre ({">"}=&&{"<"}=){" "}
                              </option>
                            )}
                            {(type === "DATETIME" || type === "DATE") && (
                              <option value="Hoy"> Registros de hoy </option>
                            )}
                            {(type === "DATETIME" || type === "DATE") && (
                              <option value="7"> Ultimos 7 dias </option>
                            )}
                            {(type === "DATETIME" || type === "DATE") && (
                              <option value="30"> Ultimos 30 dias </option>
                            )}
                            {(type === "DATETIME" || type === "DATE") && (
                              <option value="day"> Filtro por dias </option>
                            )}
                            {(type === "DATETIME" || type === "DATE") && (
                              <option value="month"> Filtro por meses </option>
                            )}
                            {(type === "DATETIME" || type === "DATE") && (
                              <option value="year"> Filtro por años </option>
                            )}
                          </select>
                        </div>
                      )}
                    </div>

                    {type === "INT64" && (
                      <div className="al-field">
                        <label htmlFor="">Valor 1</label>
                        <input
                          onChange={onChange}
                          value={value}
                          name="value"
                          type="number"
                          placeholder="ejemplo: 1"
                        />
                      </div>
                    )}
                    {type === "INT64" && filterType === ">= && <=" && (
                      <div className="al-field">
                        <label htmlFor="">Valor 2</label>
                        <input
                          onChange={onChange}
                          value={value2}
                          name="value2"
                          type="number"
                          placeholder="ejemplo: 1"
                        />
                      </div>
                    )}
                    {type === "STRING" && (
                      <div className="al-field">
                        <label htmlFor="">Texto </label>
                        <input
                          onChange={onChange}
                          value={text}
                          name="text"
                          type="text"
                          placeholder={
                            filterType === "En"
                              ? "ejemplo: texto 1,texto 2,texto3"
                              : "ejemplo: texto"
                          }
                        />
                      </div>
                    )}
                    {(type === "DATETIME" || type === "DATE") &&
                      filterType !== "Hoy" &&
                      filterType !== "7" &&
                      filterType !== "30" &&
                      filterType !== "360" &&
                      filterType !== "year" &&
                      filterType !== "month" &&
                      filterType !== "day" && (
                        <div className="al-field">
                          <label htmlFor="">Fecha </label>
                          <input
                            onChange={onChange}
                            value={fecha}
                            name="fecha"
                            type="date"
                          />
                        </div>
                      )}
                    {(filterType === "day" ||
                      filterType === "month" ||
                      filterType === "year") && (
                      <div className="al-field">
                        <label htmlFor="">
                          {` Seleccione la cantidad de ${
                            (filterType === "day" && "dias") ||
                            (filterType === "month" && "meses") ||
                            (filterType === "year" && "años")
                          }  `}{" "}
                        </label>
                        <input
                          onChange={onChange}
                          value={value}
                          name="value"
                          type="number"
                        />
                      </div>
                    )}
                    {(type === "DATETIME" || type === "DATE") &&
                      filterType === "Entre" && (
                        <div className="al-field">
                          <label htmlFor="">Fecha 2 </label>
                          <input
                            onChange={onChange}
                            value={fecha2}
                            name="fecha2"
                            type="date"
                          />
                        </div>
                      )}
                    {/* <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Host</label>
                                        <input type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div> */}
                  </div>
                </div>
              )}

              <div className="modal-footer p-0 mt-4">
                <a href="">ajustes de datos</a>
                <button
                  type="submit"
                  disabled={loading}
                  className="al-btn primary-btn show-datafont"
                >
                  Guardar
                </button>
              </div>
            </form>
            <div></div>
          </div>
        }
      </Modal>
    </>
  );
};
