import React from "react";
// import { Modal } from '@mui/material';
import Modal from "react-bootstrap/Modal";
// import Select from 'react-select'
import { useSelector, useDispatch } from "react-redux";
import { uiCloseModal3, uiCloseModal6 } from "../actions/ui";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { loadCharts } from "../actions/chart";
import { fetchSinToken } from "../helpers/fetch";
export const ModalCharts = ({ rows, index, setRows }) => {
  const { modalOpen6 } = useSelector((state: any) => state.ui);
  const { mode } = useSelector((state: any) => state.ui);
  const { charts } = useSelector((state: any) => state.chart);
  const [chart, setChart] = useState<any>();
  const { uid } = useSelector((state: any) => state.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(uiCloseModal6());
  };
  const handleChange = (value: any) => {
    setChart(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const objectToModify = { ...rows[index] };
    objectToModify.chart = chart;

    const data = await getData(chart.consulta);

    objectToModify.chart.data = data.data;

    const newArray = [...rows];
    newArray[index] = objectToModify;
    setRows(newArray);
    setLoading(false);
    closeModal();
  };

  const getData = async (consulta) => {
    const resp = await fetchSinToken(
      "query/q1",
      { consulta: consulta },
      "POST"
    );
    const body = await resp.json();

    return { data: body.data, table: body.table };
  };
  useEffect(() => {
    dispatch(loadCharts(uid, 0, 0));
  }, [index]);

  return (
    <>
      <Modal
        show={modalOpen6}
        onHide={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="xl"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title text-center" id="exampleModalLabel">
            Seleccione el grafico que quiere cargar
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => closeModal()}
          ></button>
        </div>
        <div className="modal_body">
          <div className="join-types charts">
            {charts.length > 1 &&
              charts.map((chart1, index) => (
                <div className="col position-relative">
                  <input
                    type="radio"
                    onClick={() => handleChange(chart1)}
                    name="jointype"
                    id={index}
                  />
                  <label className="join-type " htmlFor={index}>
                    <div className="img-contain">
                      <img src={chart1.img} alt="" />
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="graph-type">
                        {chart1.typegraf === "barchart" && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <rect
                                x="3"
                                y="12"
                                width="6"
                                height="8"
                                rx="1"
                              ></rect>
                              <rect
                                x="9"
                                y="8"
                                width="6"
                                height="12"
                                rx="1"
                              ></rect>
                              <rect
                                x="15"
                                y="4"
                                width="6"
                                height="16"
                                rx="1"
                              ></rect>
                              <line x1="4" y1="20" x2="18" y2="20"></line>
                            </svg>
                          </>
                        )}
                        {chart1.typegraf === "table" && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <rect
                                x="4"
                                y="4"
                                width="16"
                                height="16"
                                rx="2"
                              ></rect>
                              <line x1="4" y1="10" x2="20" y2="10"></line>
                              <line x1="10" y1="4" x2="10" y2="20"></line>
                            </svg>
                          </>
                        )}
                        {chart1.typegraf === "radiuschart" && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M12 12l-6.5 5.5"></path>
                              <path d="M12 3v9h9"></path>
                              <circle cx="12" cy="12" r="9"></circle>
                              <path d="M12 12l5 7.5"></path>
                            </svg>
                          </>
                        )}
                        {chart1.typegraf === "linechart" && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19"></polyline>
                              <polyline points="4 12 7 8 11 10 16 4 20 8"></polyline>
                            </svg>
                          </>
                        )}
                        {chart1.typegraf === "horizontalBarChart" && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <line x1="3" y1="18" x2="17" y2="18"></line>
                              <path d="M9 9l3 3l-3 3"></path>
                              <path d="M14 15l3 3l-3 3"></path>
                              <line x1="3" y1="3" x2="3" y2="21"></line>
                              <line x1="3" y1="12" x2="12" y2="12"></line>
                              <path d="M18 3l3 3l-3 3"></path>
                              <line x1="3" y1="6" x2="21" y2="6"></line>
                            </svg>
                          </>
                        )}
                        {chart1.typegraf === "donutchart" && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M12 3v5m4 4h5"></path>
                              <circle cx="12" cy="12" r="4"></circle>
                              <circle cx="12" cy="12" r="9"></circle>
                            </svg>
                          </>
                        )}
                        {chart1.typegraf === "lineChartWithNodes" && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <line x1="4" y1="19" x2="20" y2="19"></line>
                              <polyline points="4 15 8 9 12 11 16 6 20 10"></polyline>
                            </svg>
                          </>
                        )}
                      </div>
                      <p>{chart1.name}</p>
                    </div>
                  </label>
                  {loading && chart1._id === chart._id && (
                    <div className="position-absolute w-100 h-100 center-all loading-chart">
                      <CircularProgress />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className="modal-footer">
          {/* <!-- <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> --> */}
          <button
            type="button"
            className="al-btn primary-btn show-datafont"
            data-bs-dismiss="modal"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Cargando..." : "Cargar grafico"}
          </button>
        </div>
      </Modal>
    </>
  );
};
