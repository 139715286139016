import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";

import { FormEvent, useEffect, useState } from "react";
import { useForm } from "../hooks/useForm";

import { uiCloseModal10, uiCloseModal5 } from "../actions/ui";

export const ModalOrder = ({ data, setOrder, order }) => {
  const initialValue: any = {
    filterType: "",
    type: "",
    value: 0,
    value2: 0,
    text: "",

    fecha: "",
    fecha2: "",
  };
  const { mode } = useSelector((state: any) => state.ui);
  const { uid } = useSelector((state: any) => state.auth);
  const { modalOpen10 } = useSelector((state: any) => state.ui);
  const { sourceActive } = useSelector((state: any) => state.source);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#ffff",

    border: "2px solid #000",
    boxShadow: 24,
  };

  const closeModal = () => {
    dispatch(uiCloseModal10());
  };

  const handleSelect = (e, value) => {
    if (value === 0) {
      setOrder((prevOrder) => {
        return { ...prevOrder, orden: e.target.value };
      });
    }
    if (value === 1) {
      setOrder((prevOrder) => {
        return { ...prevOrder, campo: e.target.value };
      });
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    closeModal();
  };

  useEffect(() => {}, []);

  useEffect(() => {}, [sourceActive]);

  return (
    <>
      <Modal
        show={modalOpen10}
        onHide={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Seleccione el orden
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => {
              closeModal();
            }}
            aria-label="Close"
          ></button>
        </div>

        <div className="modal_body">
          <form className="tab-content" onSubmit={onSubmit} id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
              tabIndex={0}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="al-field">
                    <label htmlFor="">Orden</label>
                    <select
                      name="type"
                      id=""
                      onChange={(e) => handleSelect(e, 0)}
                      className="input_search mb-4"
                    >
                      <option value="">Seleccione...</option>
                      <option selected={order?.orden === "ASC"} value="ASC">
                        Ascendente
                      </option>
                      <option selected={order?.orden === "DESC"} value="DESC">
                        Descendente
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="al-field">
                    <label htmlFor="">Campo</label>
                    <select
                      name="type"
                      id=""
                      onChange={(e) => handleSelect(e, 1)}
                      className="input_search mb-4"
                    >
                      <option value="">Seleccione...</option>
                      <option value="cantidad">Cantidad</option>
                      {data &&
                        data.map((table: any) => {
                          const tableParts = table.split(".");

                          const lastPart = tableParts.pop();

                          return (
                            <option
                              selected={order?.campo === `${table}`}
                              id={table}
                              value={table}
                            >
                              {lastPart}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                {/* <div className="col-md-12">
                                    <div className="al-field">
                                        <label htmlFor="">Host</label>
                                        <input type="text" placeholder="Escribe aquí"/>
                                    </div>
                                </div> */}
              </div>
            </div>

            <div className="modal-footer p-0 mt-4">
              <button
                type="submit"
                disabled={loading}
                className="al-btn primary-btn show-datafont"
              >
                Guardar
              </button>
            </div>
          </form>
          <div></div>
        </div>
      </Modal>
    </>
  );
};
