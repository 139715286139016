import { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
export const TextEditor = ({ value, index, rows, setRows }) => {
  const [readOnly, setReadOnly] = useState(false);
  const editorRef = useRef(null);

  const handleChange = (newValue: string) => {
    const encontrarchart = rows.find((i) => {
      if (i.chart && !i.chart.data) {
        return i;
      }
    });

    if (encontrarchart) {
      return;
    } else {
      const objectToModify = { ...rows[index] };
      objectToModify.text = newValue;

      const newArray = [...rows];
      newArray[index] = objectToModify;
      setRows(newArray);
    }
  };
  const fontSizeArr = [
    "8px",
    "9px",
    "10px",
    "12px",
    "14px",
    "16px",
    "20px",
    "24px",
    "32px",
    "42px",
    "54px",
    "68px",
    "84px",
    "98px",
  ];
  const handleEditorClick = () => {
    setReadOnly(false);
  };

  var Size = Quill.import("attributors/style/size");
  Size.whitelist = fontSizeArr;
  Quill.register(Size, true);
  const handleClickOutside = (event) => {
    if (editorRef.current && !editorRef.current.contains(event.target)) {
      setReadOnly(true);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={editorRef}>
      {readOnly ? (
        <div
          className=" text-field"
          onClick={handleEditorClick}
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      ) : (
        <div className="no-drag">
          <ReactQuill
            value={value}
            onChange={handleChange}
            modules={{
              toolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: fontSizeArr }],
              ],
            }}
          />
        </div>
      )}
    </div>
  );
};
