import { useEffect } from "react";

export const useHandleColorNotFound = (
  arrayLength: number,
  data: unknown[],
  updateState: (state: string[] | [] | null[]) => void
) => {
  useEffect(() => {
    if (arrayLength <= 0) {
      const newListColors = data.map(() => "#e5e5e5");
      updateState(newListColors);
    }
  }, [arrayLength, data, updateState]);
};
