/* eslint-disable no-mixed-operators */
import grafica from "../../assets/img/grafica.jpg";
import { useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar";
import { Header } from "../../components/Header";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logo from "../../assets/img/logo-altum.jpg";
import { useForm } from "../../hooks/useForm";
import { fetchSinToken } from "../../helpers/fetch";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { loadSource } from "../../actions/source";
import { useDispatch, useSelector } from "react-redux";
import { newChartCreate, updateChart } from "../../actions/chart";
import { useNavigate } from "react-router-dom";
import {
  uiOpenModal10,
  uiOpenModal3,
  uiOpenModal5,
  uiOpenModal9,
} from "../../actions/ui";
import { ModalCombine } from "../../components/ModalCombine";
import { TableChart } from "./TableChart";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import { ModalFilter } from "../../components/ModalFilter";
import { Charts } from "./handleCharts";
import lodash from "lodash";
import { ModalVariables } from "../../components/ModalVariables";
import { ModalOrder } from "../../components/ModalOrder";

export const CreateChart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid } = useSelector((state: any) => state.auth);
  const { sources, sources2, views, globalSource } = useSelector(
    (state: any) => state.source
  );
  const [order, setOrder] = useState({ orden: "", campo: "" });
  const [loading1, setLoading1] = useState(false);
  const { mode } = useSelector((state: any) => state.ui);
  const { chartActive } = useSelector((state: any) => state.chart);
  const initialValue = {
    consulta: `SELECT *
FROM WHERE 1`,
    name: "",
    consulta1: "",
    key: [],
    limit: 1000,
    font: "",
    forms: "",
    sheets: "",
    sql: "",
    ejexname: "",
    ejeyname: "",
  };

  const [data, setData] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const [clave, setClaves] = useState([]);
  const [table, setTable] = useState<any>([]);
  const { FormData, onChange, setFormData } = useForm(initialValue);
  const [axisxlabel, setAxisxlabel] = useState([]);
  const [axisylabel, setAxisylabel] = useState([]);
  const [label, setlabel] = useState<any>({
    labelx: "",
    labely: "",
  });

  const {
    consulta,
    name,
    consulta1,
    limit,
    font,
    forms,
    sheets,
    sql,
    ejexname,
    ejeyname,
  } = FormData;
  const [stateSectionPay, setStateSectionPay] = useState([]);

  const [selected, setSelected] = useState<any>([]);
  const [state, setState] = useState("table");
  const [changeSql, setChangeSql] = useState(true);
  const [typeJoin, setTypeJoin] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [graf, setGraf] = useState<any>([]);
  const [keys, setKeys] = useState<any>([]);
  const [typeData, setTypeDate] = useState(false);
  const [combine, setCombine] = useState<any>();
  const [select, setSelect] = useState(false);
  const [color, setColor] = useState(
    "linear-gradient(90deg, rgba(0, 194, 204, 0.6) 0%, rgba(0, 194, 204, 0.19) 100%)"
  );
  const [doblecolor, setDoblecolor] = useState([]);

  const [selectFilter, setselectFilter] = useState<any>([]);
  const [filtros, setFiltros] = useState<any>([]);
  const [extra, setExtra] = useState<any>([]);

  const [valuesGraph, setValuesGraph] = useState({
    positionValue: "center",
    positionLabel: "bottom",
    axisSize: "small",
    axisStyle: "normal",
    axisColor: "black",
    valueSize: "small",
    valueStyle: "normal",
    valueColor: "black",
    labelSize: "small",
    labelStyle: "normal",
    labelColor: "black",
    legendSize: "small",
    legendStyle: "normal",
    legendColor: "black",
    colorIndividual: true,
    colorDegrade: false,
  });

  const handleChange = (event, axi) => {
    if (axi === "x") {
      axisxlabel.forEach((x: any) => {
        if (x === event.target.value) {
          getsqllabel(false, x);
          setlabel({ ...label, labelx: x });
          agruparData(label.labely);
        }
      });
    } else if (axi === "y") {
      axisxlabel.forEach((y: any) => {
        if (y === event.target.value) {
          setlabel({ ...label, labely: y });
          setLoading(true);
          agruparData(event.target.value);
        }
      });

      setTimeout(() => {
        setLoading(false);
      }, 1);
    }
  };

  const handleRadioGraphs = (e) => {
    if (e.target.name === "valueSize") {
      setValuesGraph((prev) => ({
        ...prev,
        valueSize: e.target.value,
      }));
    }
    if (e.target.name === "valueStyle") {
      setValuesGraph((prev) => ({
        ...prev,
        valueStyle: e.target.value,
      }));
    }

    if (e.target.name === "labelSize") {
      setValuesGraph((prev) => ({
        ...prev,
        labelSize: e.target.value,
      }));
    }
    if (e.target.name === "labelStyle") {
      setValuesGraph((prev) => ({
        ...prev,
        labelStyle: e.target.value,
      }));
    }

    if (e.target.name === "legendSize") {
      setValuesGraph((prev) => ({
        ...prev,
        legendSize: e.target.value,
      }));
    }

    if (e.target.name === "legendStyle") {
      setValuesGraph((prev) => ({
        ...prev,
        legendStyle: e.target.value,
      }));
    }

    if (e.target.name === "positionLabel") {
      setValuesGraph((prev) => ({
        ...prev,
        positionLabel: e.target.value,
      }));
    }

    if (e.target.name === "TypeOfValue") {
      setLoading(true);
      if (e.target.value === "percent") {
        setTypeDate(true);
        // setLoading(true);
      } else {
        setTypeDate(false);
        // setLoading(true);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1);
    }

    if (e.target.name === "positionValue") {
      setValuesGraph((prev) => ({
        ...prev,
        positionValue: e.target.value,
      }));
    }

    if (e.target.name === "positionValue") {
      setValuesGraph((prev) => ({
        ...prev,
        positionValue: e.target.value,
      }));
    }

    if (e.target.name === "axisSize") {
      setValuesGraph((prev) => ({
        ...prev,
        axisSize: e.target.value,
      }));
    }
    if (e.target.name === "axisStyle") {
      setValuesGraph((prev) => ({
        ...prev,
        axisStyle: e.target.value,
      }));
    }

    if (e.target.name === "colorIndividual") {
      if (e.target.value === "individual") {
        setValuesGraph((prev) => ({
          ...prev,
          colorIndividual: true,
        }));
      } else {
        setValuesGraph((prev) => ({
          ...prev,
          colorIndividual: false,
        }));
      }
    }

    if (e.target.name === "colorDegrade") {
      if (e.target.value === "solid") {
        setValuesGraph((prev) => ({
          ...prev,
          colorDegrade: false,
        }));
      } else {
        setValuesGraph((prev) => ({
          ...prev,
          colorDegrade: true,
        }));
      }
    }
  };

  const colorTextGraphs = lodash.debounce((e) => {
    if (e.target.name === "valueColor") {
      setValuesGraph((prev) => ({
        ...prev,
        valueColor: e.target.value,
      }));
    }
    if (e.target.name === "labelColor") {
      setValuesGraph((prev) => ({
        ...prev,
        labelColor: e.target.value,
      }));
    }
    if (e.target.name === "legendColor") {
      setValuesGraph((prev) => ({
        ...prev,
        legendColor: e.target.value,
      }));
    }

    if (e.target.name === "axisColor") {
      setValuesGraph((prev) => ({
        ...prev,
        axisColor: e.target.value,
      }));
    }

    if (e.target.name === "allColors") {
      if (state === "linechart") {
        const newBarColors = stateSectionPay.map((a: any, i) => {
          if (i === 0) return e.target.value;

          return a;
        });
        setStateSectionPay(newBarColors);
        return;
      }
      const newBarColors = stateSectionPay.map(() => e.target.value);
      setStateSectionPay(newBarColors);
    }
  }, 300);

  const agruparData = (value1) => {
    let value = selectFilter.find((item) => item.column_name === value1); //valor en y
    let value2 = selectFilter.find((item) => item.column_name === label.labelx); //valor en x

    if (value?.data_type === "STRING") {
      const result = [];

      const agrupados = new Set();
      let agrupar = "";
      const claves: any = [
        ...new Set(
          data?.map((item) => {
            if (item[value1] === null) {
              return "null";
            } else {
              return item[value1];
            }
          })
        ),
      ];

      data?.forEach((e) => {
        if (value2?.data_type === "DATETIME" || value2?.data_type === "DATE") {
          if (e[label.labelx] === null) {
            agrupar = "null";
          } else {
            agrupar = e[label.labelx].split("T")[0];
          }
        } else {
          if (e[label.labelx] === null) {
            agrupar = "null";
          } else {
            agrupar = e[label.labelx];
          }
        }

        if (agrupados.has(agrupar)) {
          result.forEach((r) => {
            if (
              r[label.labelx].split("T")[0] === agrupar ||
              r[label.labelx] === agrupar
            ) {
              r[e[value1]?.toLowerCase()] += e.cantidad;
            }
          });
        } else {
          agrupados.add(agrupar);

          const obj = { ...e };
          claves.forEach((item) => {
            obj[item?.toLowerCase()] = obj[value1] === item ? obj?.cantidad : 0;
          });
          result.push(obj);
        }
      });

      setData2(result);
      setClaves(claves);
    } else {
      setData2([]);
      setClaves([]);
    }
  };

  const downloadToPdf = async () => {
    if (state === "table") {
      const fileName = `${name}.xlsx`;
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `Hoja 1`);
      XLSX.writeFile(wb, fileName);
    } else {
      const printArea: any = document.getElementById("printWrapper");
      html2canvas(printArea).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "landscape",
          format: "letter",
        });

        const imgProps = pdf.getImageProperties(imgData);

        pdf.addImage(`${logo}`, "JPG", 5, 5, 40, 13);
        // pdf.addImage(`${fondo}`, "JPG", 5, 5, 221,71 );
        pdf.setFontSize(15);
        pdf.setFont("helvetica", "bold");
        pdf.text("Marcaciones por cliente", 5, 35);

        const pdfWidth = pdf.internal.pageSize.getWidth() - 10;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(
          imgData,
          "PNG",
          7,
          107 - pdfHeight / 2,
          pdfWidth,
          pdfHeight
        );

        pdf.line(5, 190, 275, 190);
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(9);
        pdf.text(
          "Gráfica correspondiente al Dashboard Altum Analytics",
          5,
          200
        );
        pdf.text("Derechos reservados VISE", 5, 205);
        pdf.text("www.altumanalytics.com", 230, 200);

        pdf.save("Grafica.pdf");
      });
    }
  };

  const getsql = async (state: boolean, obj?: any) => {
    setLoading(true);

    if (state) {
      const resp = await fetchSinToken("query/q1", { consulta }, "POST");
      const body = await resp.json();

      if (body.ok) {
        if (body.msg) {
          setLoading(false);
          setData([]);
        } else {
          setData(body.data);

          setAxisxlabel(body.x);
          setAxisylabel(body.y);
          setlabel({ labelx: body.x[1], labely: body.y[0] });
          setTable(body.table);
          setLoading(false);
        }
      } else {
        Swal.fire("Error", body.msg.message, "error");
        setData([]);
        setLoading(false);
      }
    } else {
      let consulta1 = "select count(*) as cantidad ,";
      let group = "";
      let limite = `limit ${limit}`;
      const orden =
        order.campo === "" || order.orden === ""
          ? ""
          : `ORDER BY ${order.campo} ${order.orden}`;
      if (obj) {
        setKeys([...keys, obj]);
      }
      if (selected.length > 0) {
        selected.forEach((item) => {
          if (consulta1 === "select count(*) as cantidad ,") {
            consulta1 = consulta1 + ` ${item}`;
          } else {
            consulta1 = consulta1 + ` , ${item}`;
          }
        });
        group = consulta1.replace("select count(*) as cantidad ,", "group by");
      } else {
        consulta1 = consulta1 + " *";
      }

      let where = "";
      filtros.forEach((element, index) => {
        if (index === 0) {
          where = `WHERE ${element.sql} `;
        } else {
          where = `${where} AND ${element.sql}`;
        }
      });
      if (keys?.length >= 1) {
        keys?.forEach((element) => {
          consulta1 = `${consulta1} ${element.from}`;
        });
        if (obj) {
          consulta1 = `${consulta1} ${obj.from}`;
        }
        consulta1 = `${consulta1} ${where} ${group} ${orden} ${
          limit === 0 || !limit ? "" : limite
        }`;
      } else {
        if (obj) {
          consulta1 = `${consulta1} ${obj.from}`;
        }
        consulta1 = `${consulta1} ${where} ${group} ${orden} ${
          limit === 0 || !limit ? "" : limite
        }`;
      }

      if (label) {
      }
      const resp = await fetchSinToken(
        "query/q1",
        { consulta: consulta1 },
        "POST"
      );
      const body = await resp.json();
      setFormData({ ...FormData, consulta: consulta1, consulta1: consulta1 });
      if (body.ok) {
        if (body.msg) {
          setLoading(false);
          setData([]);
        } else {
          setData(body.data);

          setAxisxlabel(body.x);
          setAxisylabel(body.y);
          setlabel({ labelx: body.x[1], labely: body.y[0] });
          setTable(body.table);
          setLoading(false);
        }
      } else {
        Swal.fire(
          "Error",
          body.msg.message ? body.msg.message : body.msg,
          "error"
        );
        setLoading(false);
        setData([]);
      }
    }
  };

  const getsqllabel = async (state: boolean, label) => {
    setLoading(true);

    if (state) {
      const resp = await fetchSinToken("query/q1", { consulta }, "POST");
      const body = await resp.json();

      if (body.ok) {
        setData(body.data);

        setAxisxlabel(body.x);
        setAxisylabel(body.y);
        setlabel({ labelx: body.x[1], labely: body.y[0] });
        setTable(body.table);

        setLoading(false);
      } else {
        Swal.fire("Error", body.msg.message, "error");
        setData([]);
        setLoading(false);
      }
    } else {
      let consulta1 = "select count(*) as cantidad ,";
      let group = "";
      let limite = `limit ${limit}`;
      const orden =
        order.campo === "" || order.orden === ""
          ? ""
          : `ORDER BY ${order.campo} ${order.orden}`;
      if (selected.length > 0) {
        selected.forEach((item) => {
          if (consulta1 === "select count(*) as cantidad ,") {
            consulta1 = consulta1 + ` ${item}`;
          } else {
            consulta1 = consulta1 + ` , ${item}`;
          }
        });
        group = consulta1.replace("select count(*) as cantidad ,", "group by");
      } else {
        consulta1 = consulta1 + " *";
      }

      let where = "";
      filtros.forEach((element, index) => {
        if (index === 0) {
          where = `WHERE ${element.sql} `;
        } else {
          where = `${where} AND ${element.sql}`;
        }
      });

      if (keys?.length >= 1) {
        keys?.forEach((element) => {
          consulta1 = `${consulta1} ${element.from}`;
        });

        consulta1 = `${consulta1} ${where} ${group}  ${orden} ${
          limit === 0 || !limit ? "" : limite
        }`;

        if (label) {
          let sumas = ``;
          axisylabel.forEach(
            (item) => (sumas = `${sumas} , sum(${item}) as ${item}`)
          );
          consulta1 = `select ${label} as ${label}x ${sumas} from (${consulta1}) s group by ${label}x  ${orden}`;
        }
      } else {
        consulta1 = `${consulta1} from \`vise-192912.altum_hist.${
          graf[0].tableName ? graf[0].tableName : graf[0].view
        }${
          graf[0].tableName && !graf[0]?.typeSync ? graf[0]._id : ""
        }\` ${where} ${group} ${orden} ${limit === 0 || !limit ? "" : limite}`;
      }

      const resp = await fetchSinToken(
        "query/q1",
        { consulta: consulta1 },
        "POST"
      );
      const body = await resp.json();
      setFormData({ ...FormData, consulta: consulta1 });
      if (body.ok) {
        if (body.msg) {
          setLoading(false);
          setData([]);
        } else {
          if (body.data.length <= 0) {
            setData([]);
          } else {
            setData(body.data);

            setLoading(false);
          }
        }
      } else {
        Swal.fire("Error", body.msg.message, "error");
        setLoading(false);
        setData([]);
      }
    }
  };
  const getsql2 = async () => {
    setLoading(true);
    let consulta1 = "select count(*) as cantidad ,";
    let group = "";
    let limite = `limit ${limit}`;
    const orden =
      order.campo === "" || order.orden === ""
        ? ""
        : `ORDER BY ${order.campo} ${order.orden}`;
    if (selected.length > 0) {
      selected.forEach((item) => {
        if (consulta1 === "select count(*) as cantidad ,") {
          consulta1 = consulta1 + ` ${item}`;
        } else {
          consulta1 = consulta1 + ` , ${item}`;
        }
      });

      group = consulta1.replace("select count(*) as cantidad ,", "group by");

      if (extra.length > 0) {
        extra.forEach((element) => {
          if (element.key.includes("count") || element.key.includes("sum")) {
            consulta1 = consulta1 + ` , ${element.key}`;
          } else {
            consulta1 = consulta1 + ` , ${element.key}`;
            group = group + ` , ${element.name}`;
          }
        });
      }
    } else {
      consulta1 = consulta1 + " *";
    }

    let where = "";
    filtros.forEach((element, index) => {
      if (index === 0) {
        where = `WHERE ${element.sql} `;
      } else {
        where = `${where} AND ${element.sql}`;
      }
    });
    if (keys?.length >= 1) {
      keys?.forEach((element) => {
        consulta1 = `${consulta1} ${element.from}`;
      });
    } else {
      consulta1 = `${consulta1} from \`vise-192912.altum_hist.${
        graf[0].tableName ? graf[0].tableName : graf[0].view
      }${graf[0].tableName && !graf[0]?.typeSync ? graf[0]._id : ""}\``;
    }

    consulta1 = `${consulta1} ${where} ${group} ${orden} ${
      limit === 0 || !limit ? "" : limite
    }`;

    if (label) {
    }
    const resp = await fetchSinToken(
      "query/q1",
      { consulta: consulta1 },
      "POST"
    );

    const body = await resp.json();
    setFormData({ ...FormData, consulta: consulta1, consulta1: consulta1 });
    if (body.ok) {
      if (body.msg) {
        setLoading(false);
        setData([]);
      } else {
        setData(body.data);

        setAxisxlabel(body.x);
        setAxisylabel(body.y);
        setlabel({ labelx: body.x[1], labely: body.y[0] });
        setTable(body.table);
        setLoading(false);
      }
    } else {
      Swal.fire(
        "Error",
        body.msg.message ? body.msg.message : body.msg,
        "error"
      );
      setLoading(false);
      setData([]);
    }
  };

  const handleCheckbox = async (e: any, index: any, type: number, source) => {
    if (changeSql) {
      if (graf?.length > 0) {
        const graf1 = graf.find((item) => item._id === source._id);

        if (graf1) {
          setGraf(graf.filter((item) => item._id !== source._id));
          setselectFilter(
            selectFilter.filter(
              (item) => item.column_name !== source.column_name
            )
          );
          if (source.typeSync === true) {
            setSelected(
              selected.filter(
                (item) => !item.includes(`${source.tableName}`) && item
              )
            );
            setselectFilter(
              selectFilter.filter(
                (item) => !item.key.includes(`${source.tableName}`) && item
              )
            );
          } else {
            setSelected(
              selected.filter(
                (item) =>
                  !item.includes(
                    `${source.tableName ? source.tableName : source.view}${
                      source.tableName ? source._id : ""
                    }`
                  ) && item
              )
            );
            setselectFilter(
              selectFilter.filter(
                (item) =>
                  !item.key.includes(
                    `${source.tableName ? source.tableName : source.view}${
                      source.tableName ? source._id : ""
                    }`
                  ) && item
              )
            );
          }
        } else {
          setGraf([...graf, source]);

          // opcion para agregar todos los campos de cada tabla seleccionada al creador de filtros y de campos variables
          const nuevo = [];
          source?.data.forEach((element) => {
            if (source?.typeSync) {
              nuevo.push({
                ...element,
                key: `\`vise-192912.altum_hist.${source.tableName}\`.${element.column_name}`,
              });
            } else {
              nuevo.push({
                ...element,
                key: `\`vise-192912.altum_hist.${
                  source.tableName ? source.tableName : source.view
                }${source.tableName ? source._id : ""}\`.${
                  element.column_name
                }`,
              });
            }
          });
          setselectFilter([...selectFilter, ...nuevo]);
        }
      } else {
        setGraf([...graf, source]);
        const nuevo = [];
        source?.data.forEach((element) => {
          if (source?.typeSync) {
            nuevo.push({
              ...element,
              key: `\`vise-192912.altum_hist.${source.tableName}\`.${element.column_name}`,
            });
          } else {
            nuevo.push({
              ...element,
              key: `\`vise-192912.altum_hist.${
                source?.tableName ? source?.tableName : source.view
              }${source?.tableName ? source?._id : ""}\`.${
                element?.column_name
              }`,
            });
          }
        });

        setselectFilter([...selectFilter, ...nuevo]);
      }
    } else {
      consulta.toLocaleUpperCase();
      if (type === 0) {
        sources[index].check = !sources[index].check;

        if (sources[index].check) {
          const text = consulta.split("WHERE");
          setFormData({
            ...FormData,
            consulta: `${text[0]} vise-192912.altum_hist.${sources[index].tableName}${sources[index]._id} WHERE
                  ${text[1]}`,
          });
        } else {
          setFormData({
            ...FormData,
            consulta: consulta.replace(
              `vise-192912.altum_hist.${sources[index].tableName}${sources[index]._id}`,
              ""
            ),
          });
        }
      } else {
        sources2[index].check = !sources2[index].check;

        if (sources2[index].check) {
          const text = consulta.split("WHERE");
          setFormData({
            ...FormData,
            consulta: `${text[0]} vise-192912.altum_hist.${sources2[index].tableName} WHERE ${text[1]}`,
          });
        } else {
          setFormData({
            ...FormData,
            consulta: consulta.replace(
              `vise-192912.altum_hist.${sources2[index].tableName}`,
              ""
            ),
          });
        }
      }
    }
  };
  const handleCheckbox2 = async (source: any, graf: any) => {
    if (graf.typeSync === true) {
      if (
        selected?.includes(
          `\`vise-192912.altum_hist.${graf.tableName}\`.${source.column_name}`
        )
      ) {
        setSelected(
          selected.filter(
            (item) =>
              item !==
              `\`vise-192912.altum_hist.${graf.tableName}\`.${source.column_name}`
          )
        );
      } else {
        setSelected([
          ...selected,
          `\`vise-192912.altum_hist.${graf.tableName}\`.${source.column_name}`,
        ]);
      }
    } else {
      if (
        selected?.includes(
          `\`vise-192912.altum_hist.${
            graf.tableName ? graf.tableName : graf.view
          }${graf.tableName ? graf._id : ""}\`.${source.column_name}`
        )
      ) {
        setSelected(
          selected.filter(
            (item) =>
              item !==
              `\`vise-192912.altum_hist.${
                graf.tableName ? graf.tableName : graf.view
              }${graf.tableName ? graf._id : ""}\`.${source.column_name}`
          )
        );
      } else {
        setSelected([
          ...selected,
          `\`vise-192912.altum_hist.${
            graf.tableName ? graf.tableName : graf.view
          }${graf.tableName ? graf._id : ""}\`.${source.column_name}`,
        ]);
      }
    }
  };
  const handleSave = () => {
    setLoading1(true);
    if (name.length < 1) {
      alert("tienes que ponerle un nombre a tu grafica");
    } else {
      if (chartActive._id) {
        if (
          chartActive.name !== name ||
          chartActive.consulta !== consulta ||
          chartActive.typegraf !== state ||
          chartActive.label !== label ||
          chartActive?.doblecolor !== doblecolor ||
          chartActive?.stateSectionPay !== stateSectionPay ||
          chartActive?.doble !== data2
        ) {
          if (
            chartActive.consulta !== consulta ||
            chartActive.typegraf !== state ||
            chartActive.label !== label ||
            chartActive?.doblecolor !== doblecolor ||
            chartActive?.stateSectionPay !== stateSectionPay ||
            chartActive?.doble !== data2 ||
            chartActive?.valuesGraph !== valuesGraph ||
            chartActive?.ejexname !== ejexname ||
            chartActive?.ejeyname !== ejeyname ||
            chartActive?.table !== table
          ) {
            const printArea: any = document.getElementById("printWrapper");
            html2canvas(printArea).then((canvas) => {
              const imgData = canvas.toDataURL("image/jpg");
              // uploadAndGetPublicFile('prueba',imgData)

              dispatch(
                updateChart(
                  {
                    _id: chartActive._id,
                    consulta,
                    consulta1,
                    name,
                    label,
                    typegraf: state,
                    imgData,
                    selected,
                    typeJoin,
                    graf,
                    keys,
                    filtros,
                    color,
                    selectFilter,
                    stateSectionPay,
                    typeData,
                    limit,
                    doblecolor,
                    clave,
                    doble: data2,
                    valuesGraph,
                    ejexname,
                    ejeyname,
                    table,
                  },
                  navigate
                )
              );
            });
          } else {
            dispatch(
              updateChart(
                {
                  _id: chartActive._id,
                  consulta,
                  name,
                  label,
                  typegraf: state,
                  selected,
                  typeJoin,
                  graf,
                  keys,
                  typeData,
                },
                navigate
              )
            );
          }
        } else {
          navigate("/charts");
        }
      } else {
        const printArea: any = document.getElementById("printWrapper");
        html2canvas(printArea).then((canvas) => {
          const imgData = canvas.toDataURL("image/jpg");
          // uploadAndGetPublicFile('prueba',imgData)

          dispatch(
            newChartCreate(
              {
                consulta,
                consulta1,
                name,
                label,
                state,
                imgData,
                uid,
                selected,
                typeJoin,
                graf,
                keys,
                filtros,
                selectFilter,
                color,
                stateSectionPay,
                typeData,
                limit,
                doblecolor,
                clave,
                doble: data2,
                valuesGraph,
                ejexname,
                ejeyname,
                table,
              },
              navigate
            )
          );
        });
      }
    }
  };
  const openFilter = () => {
    setSelect(false);
    dispatch(uiOpenModal5());
  };
  const openVariable = () => {
    dispatch(uiOpenModal9());
  };
  const openCombine = (graft, index) => {
    dispatch(uiOpenModal3());
    setCombine({ ...graft, index });
  };
  useEffect(() => {
    if (chartActive._id) {
      // setChangeSql(false)
      setGraf(chartActive?.graf);
      setSelected(chartActive?.select);
      setLoading(true);
      setTypeJoin(chartActive?.typeJoin);
      setKeys(chartActive?.keys);
      setlabel(chartActive?.label);
      if (chartActive?.selectFilter) {
        setselectFilter(chartActive?.selectFilter);
      }
      let consulta;
      if (chartActive?.filtros) {
        setFiltros(chartActive?.filtros);
      }
      setState(chartActive?.typegraf);
      setFormData(chartActive);
      if (chartActive?.color) {
        setColor(chartActive?.color);
      }
      if (chartActive?.doblecolor) {
        setDoblecolor(chartActive?.doblecolor);
      }
      if (chartActive?.stateSectionPay) {
        setStateSectionPay(chartActive?.stateSectionPay);
      }

      if (chartActive?.typeData) {
        setTypeDate(chartActive?.typeData);
      }
      consulta = chartActive?.consulta;

      fetch(`${process.env.REACT_APP_API_URL}query/q1`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ consulta }),
      })
        .then((response) => response.json())
        .then((data) => {
          const dataa = data.data;

          setData(dataa);

          setAxisxlabel(data.x);
          setAxisylabel(data.y);
          setTable(data.table);

          setLoading(false);
        });
      if (chartActive?.valuesGraph) {
        setValuesGraph(chartActive?.valuesGraph);
      }
      if (chartActive?.table) {
        setTable(chartActive?.table);
      }
    }
  }, [chartActive]);
  useEffect(() => {}, [data, state, isLoading]);
  useEffect(() => {
    dispatch(loadSource(uid, 0, 0));
  }, []);
  useEffect(() => {
    agruparData(label.labely);
  }, [data]);

  const coloresFrios = [
    "#22396B",
    "#94B0EE",
    "#4B7EEB",
    "#47526B",
    "#3B63B7",
    "#2B1D73",
    "#6656DB",
    "#4E9CEA",
    "#465766",
    "#4637CC",
  ];

  const coloresCalidos = [
    "#6B2226",
    "#EE9499",
    "#EB4B54",
    "#891111",
    "#B73B43",
    "#C72B2E",
    "#B63152",
    "#EA4E72",
    "#A90D4E",
    "#AF4661",
  ];
  const handleColor = (event) => {
    const gamaDeColores = event.target.value;
    const numData = data.length;
    let arrayColores = [];
    let numColor = 0;

    if (gamaDeColores === "none") {
      if (chartActive?.stateSectionPay) {
        setStateSectionPay(chartActive?.stateSectionPay);
      }
      return;
    }

    for (let i = 0; i <= numData; i++) {
      arrayColores.push(
        gamaDeColores === "coloresCalidos"
          ? coloresCalidos[numColor]
          : coloresFrios[numColor]
      );

      numColor += 1;

      if (numColor >= 10) {
        numColor = 0;
      }
    }
    setStateSectionPay(arrayColores);
  };
  return (
    <>
      <div className={`dashboard ${mode ? `dark-mode` : ""}`}>
        <Navbar />

        <Header />

        <main className="container-fluid p-0 d-flex">
          <div className="page-content w-100">
            <div className="row">
              <div
                className={`col-md-12 headline-sticky ${
                  mode ? `dark-mode` : "col-md-12 headline-sticky"
                }`}
              >
                <div className="headline">
                  <input
                    type="text"
                    className="graph-title"
                    name="name"
                    value={name}
                    onChange={onChange}
                    placeholder="Escriba el nombre de la grafica"
                  />

                  <div className="actions">
                    {/* <button className="al-btn secondary-btn">Compartir</button> */}

                    <div className="post-box d-flex">
                      <button
                        onClick={handleSave}
                        className={"al-btn primary-btn"}
                        disabled={loading1}
                      >
                        {" "}
                        {loading1 ? "Guardando.." : "Guardar"}
                      </button>
                      <button
                        className="al-btn primary-btn"
                        onClick={() => downloadToPdf()}
                      >
                        Exportar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <button
                  onClick={() => {
                    setChangeSql(!changeSql);
                  }}
                  className="al-btn primary-btn line-btn ml-auto mb-2 "
                >
                  {changeSql ? "Escritor de sql" : "Interfaz de combinacion"}
                </button>
                <div className="combine-data">
                  {changeSql ? (
                    <div className="combine-panel">
                      <div className="container-bordered">
                        {graf.length === 0 ? (
                          <>
                            <div className="datafont-item">
                              <div className="datafont-headline">
                                <p></p>
                                <button>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#2c3e50"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                  </svg>
                                </button>
                              </div>
                              <div className="data-rows-container center-vertical text-center">
                                <p>Selecciona una base de datos</p>
                              </div>
                            </div>
                            <div>
                              <button className="al-btn primary-btn" disabled>
                                Unir
                              </button>
                              {/* <button className="al-btn primary-btn line-btn" disabled>
                                            Consultar
                                          </button> */}
                            </div>
                            <div className="datafont-item">
                              <div className="datafont-headline">
                                <p></p>
                                <button>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#2c3e50"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                  </svg>
                                </button>
                              </div>
                              <div className="data-rows-container center-vertical text-center">
                                <p>Selecciona una base de datos</p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {graf.length > 0 &&
                              graf?.map((graf: any, index: any) => (
                                <>
                                  {index > 0 && (
                                    <div>
                                      <button
                                        className="al-btn primary-btn"
                                        onClick={() => openCombine(graf, index)}
                                      >
                                        Unir
                                      </button>
                                      {/* <button onClick={()=> getsql(false)}
                                              className="al-btn primary-btn line-btn"
                                              >
                                              Consultar
                                            </button> */}
                                    </div>
                                  )}

                                  <div className="datafont-item">
                                    <div className="datafont-headline">
                                      <p>{graf.name}</p>
                                      <button>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <circle cx="12" cy="12" r="1" />
                                          <circle cx="12" cy="19" r="1" />
                                          <circle cx="12" cy="5" r="1" />
                                        </svg>
                                      </button>
                                    </div>
                                    <div
                                      className={
                                        graf?.data.length === 0
                                          ? "data-rows-container center-all"
                                          : "data-rows-container"
                                      }
                                    >
                                      <ul>
                                        {graf?.data.length === 0 ? (
                                          <CircularProgress />
                                        ) : (
                                          graf?.data?.map((item) => (
                                            <li>
                                              <FormControlLabel
                                                value="end"
                                                control={
                                                  graf?.typeSync === true ? (
                                                    <Checkbox
                                                      checked={selected.includes(
                                                        `\`vise-192912.altum_hist.${graf.tableName}\`.${item.column_name}`
                                                      )}
                                                    />
                                                  ) : (
                                                    <Checkbox
                                                      checked={selected.includes(
                                                        `\`vise-192912.altum_hist.${
                                                          graf.tableName
                                                            ? graf.tableName
                                                            : graf.view
                                                        }${
                                                          graf.tableName
                                                            ? graf._id
                                                            : ""
                                                        }\`.${item.column_name}`
                                                      )}
                                                    />
                                                  )
                                                }
                                                label={item.column_name}
                                                title={item.column_name}
                                                onChange={() =>
                                                  handleCheckbox2(item, graf)
                                                }
                                                labelPlacement="end"
                                              />
                                            </li>
                                          ))
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              ))}
                            {graf.length === 1 && (
                              <>
                                <div>
                                  <button
                                    className="al-btn primary-btn"
                                    disabled
                                  >
                                    Unir
                                  </button>
                                  {/* <button className="al-btn primary-btn line-btn" onClick={()=> getsql2()}
                                              >
                                              Consultar
                                            </button> */}
                                </div>
                                <div className="datafont-item">
                                  <div className="datafont-headline">
                                    <p></p>
                                    <button>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#2c3e50"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        ></path>
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                      </svg>
                                    </button>
                                  </div>
                                  <div className="data-rows-container center-vertical text-center">
                                    <p>Selecciona una base de datos</p>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className="d-flex data-filter">
                        <div>
                          <button
                            onClick={() => openFilter()}
                            className="filter-btn"
                          >
                            Filtros
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <circle cx="14" cy="6" r="2" />
                              <line x1="4" y1="6" x2="12" y2="6" />
                              <line x1="16" y1="6" x2="20" y2="6" />
                              <circle cx="8" cy="12" r="2" />
                              <line x1="4" y1="12" x2="6" y2="12" />
                              <line x1="10" y1="12" x2="20" y2="12" />
                              <circle cx="17" cy="18" r="2" />
                              <line x1="4" y1="18" x2="15" y2="18" />
                              <line x1="19" y1="18" x2="20" y2="18" />
                            </svg>
                          </button>
                          <button
                            onClick={() => openVariable()}
                            className="filter-btn"
                          >
                            Campos Variables
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <circle cx="14" cy="6" r="2" />
                              <line x1="4" y1="6" x2="12" y2="6" />
                              <line x1="16" y1="6" x2="20" y2="6" />
                              <circle cx="8" cy="12" r="2" />
                              <line x1="4" y1="12" x2="6" y2="12" />
                              <line x1="10" y1="12" x2="20" y2="12" />
                              <circle cx="17" cy="18" r="2" />
                              <line x1="4" y1="18" x2="15" y2="18" />
                              <line x1="19" y1="18" x2="20" y2="18" />
                            </svg>
                          </button>
                          <button
                            onClick={() => dispatch(uiOpenModal10())}
                            className="filter-btn"
                          >
                            Orden
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <circle cx="14" cy="6" r="2" />
                              <line x1="4" y1="6" x2="12" y2="6" />
                              <line x1="16" y1="6" x2="20" y2="6" />
                              <circle cx="8" cy="12" r="2" />
                              <line x1="4" y1="12" x2="6" y2="12" />
                              <line x1="10" y1="12" x2="20" y2="12" />
                              <circle cx="17" cy="18" r="2" />
                              <line x1="4" y1="18" x2="15" y2="18" />
                              <line x1="19" y1="18" x2="20" y2="18" />
                            </svg>
                          </button>
                        </div>
                        <div>
                          {/* <button
                            onClick={() => openFilter()}
                            className="filter-btn"
                          >
                            Filtros
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <circle cx="14" cy="6" r="2" />
                              <line x1="4" y1="6" x2="12" y2="6" />
                              <line x1="16" y1="6" x2="20" y2="6" />
                              <circle cx="8" cy="12" r="2" />
                              <line x1="4" y1="12" x2="6" y2="12" />
                              <line x1="10" y1="12" x2="20" y2="12" />
                              <circle cx="17" cy="18" r="2" />
                              <line x1="4" y1="18" x2="15" y2="18" />
                              <line x1="19" y1="18" x2="20" y2="18" />
                            </svg>
                          </button> */}
                          <ul className="filter-list">
                            {filtros.length > 0 &&
                              filtros?.map((item) => (
                                <li>
                                  {item?.key?.split(".")[3].toUpperCase()}
                                  <button
                                    onClick={() =>
                                      setFiltros(
                                        filtros.filter(
                                          (filtro) => filtro.sql !== item.sql
                                        )
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="#2c3e50"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <line x1="18" y1="6" x2="6" y2="18" />
                                      <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                  </button>
                                </li>
                              ))}
                          </ul>
                          <ul className="filter-list">
                            {extra.length > 0 &&
                              extra?.map((item) => (
                                <li>
                                  {item?.name.toUpperCase()}
                                  <button
                                    onClick={() => {
                                      setExtra(
                                        extra.filter(
                                          (extra) => extra.key !== item.key
                                        )
                                      );

                                      setselectFilter(
                                        selectFilter?.filter(
                                          (select) => select.key !== item.key
                                        )
                                      );
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="#2c3e50"
                                      fill="none"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <line x1="18" y1="6" x2="6" y2="18" />
                                      <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </div>

                        <div className="mt-auto">
                          <label htmlFor="">Limite de datos </label>
                          <input
                            type="number"
                            className="w-100"
                            onChange={onChange}
                            name="limit"
                            value={
                              limit > 0 ? limit : "no tiene limite de datos"
                            }
                          />
                          <button
                            onClick={() => getsql2()}
                            className="al-btn primary-btn mt-4 line-btn w-100"
                          >
                            Consultar
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="sql-graph">
                      <textarea
                        name="consulta"
                        value={consulta}
                        onChange={onChange}
                        id=""
                        cols={50}
                        rows={7}
                      ></textarea>
                      <button
                        className="al-btn primary-btn line-btn"
                        onClick={() => getsql(true)}
                      >
                        Consultar
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="internal-search">
                  <h5>Fuentes de datos</h5>
                </div>

                <div className="database-list">
                  <Accordion
                    className={`database-item ${
                      mode ? `dark-mode` : "database-item"
                    }`}
                    defaultExpanded={false}
                  >
                    <AccordionSummary
                      expandIcon={<i className="icon-arrow-down"></i>}
                      aria-controls="panel1a-content"
                      className="database-headline"
                      id="panel1a-header"
                    >
                      Altum Forms
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul>
                        <li className="pt-3 pb-3">
                          <div className="position-relative w-100">
                            <input
                              type="text"
                              value={font}
                              name="font"
                              placeholder="Buscar tabla"
                              className="w-100"
                              onChange={onChange}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <circle cx="10" cy="10" r="7" />
                              <line x1="21" y1="21" x2="15" y2="15" />
                            </svg>
                          </div>
                        </li>
                        {globalSource.length >= 1 &&
                          globalSource.map(
                            (source: any, index: any) =>
                              (source?.name?.toLowerCase().includes(font) ||
                                !font) && (
                                <li>
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label={source?.name}
                                    onChange={(e) => {
                                      handleCheckbox(e, index, 0, source);
                                    }}
                                    checked={
                                      graf.find(
                                        (item) => item._id === source._id
                                      )
                                        ? true
                                        : false
                                    }
                                    labelPlacement="end"
                                  />
                                </li>
                              )
                          )}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    className={`database-item ${
                      mode ? `dark-mode` : "database-item"
                    }`}
                    defaultExpanded={false}
                  >
                    <AccordionSummary
                      expandIcon={<i className="icon-arrow-down"></i>}
                      aria-controls="panel1a-content"
                      className="database-headline"
                      id="panel1a-header"
                    >
                      Formularios
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul>
                        <li className="pt-3 pb-3">
                          <div className="position-relative w-100">
                            <input
                              type="text"
                              value={forms}
                              name="forms"
                              placeholder="Buscar tabla"
                              className="w-100"
                              onChange={onChange}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <circle cx="10" cy="10" r="7" />
                              <line x1="21" y1="21" x2="15" y2="15" />
                            </svg>
                          </div>
                        </li>
                        {views.length >= 1 &&
                          views.map(
                            (source: any, index: any) =>
                              (source?.name?.toLowerCase().includes(forms) ||
                                source?.id
                                  ?.toString()
                                  .toLowerCase()
                                  .includes(forms) ||
                                !forms) && (
                                <li>
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label={`${source?.name} - ${source?.id}`}
                                    onChange={(e) => {
                                      handleCheckbox(e, index, 1, source);
                                    }}
                                    checked={
                                      graf.find(
                                        (item) => item._id === source._id
                                      )
                                        ? true
                                        : false
                                    }
                                    labelPlacement="end"
                                  />
                                </li>
                              )
                          )}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    className={`database-item ${
                      mode ? `dark-mode` : "database-item"
                    }`}
                    defaultExpanded={false}
                  >
                    <AccordionSummary
                      expandIcon={<i className="icon-arrow-down"></i>}
                      aria-controls="panel1a-content"
                      className="database-headline"
                      id="panel1a-header"
                    >
                      Google Sheets
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul>
                        <li className="pt-3 pb-3">
                          <div className="position-relative w-100">
                            <input
                              type="text"
                              value={sheets}
                              name="sheets"
                              placeholder="Buscar tabla"
                              className="w-100"
                              onChange={onChange}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <circle cx="10" cy="10" r="7" />
                              <line x1="21" y1="21" x2="15" y2="15" />
                            </svg>
                          </div>
                        </li>
                        {sources.length >= 1 &&
                          sources.map(
                            (source: any, index: any) =>
                              source.sourceTypeId ===
                                "62ecfeb3596600004a002b04" &&
                              (source?.name?.toLowerCase().includes(sheets) ||
                                source?.rname?.toLowerCase().includes(sheets) ||
                                !sheets) && (
                                <li>
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label={
                                      source?.rname
                                        ? source?.rname
                                        : source?.name
                                    }
                                    onChange={(e) => {
                                      handleCheckbox(e, index, 0, source);
                                    }}
                                    checked={
                                      graf.find(
                                        (item) => item._id === source._id
                                      )
                                        ? true
                                        : false
                                    }
                                    labelPlacement="end"
                                  />
                                </li>
                              )
                          )}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    className={`database-item ${
                      mode ? `dark-mode` : "database-item"
                    }`}
                    defaultExpanded={false}
                  >
                    <AccordionSummary
                      expandIcon={<i className="icon-arrow-down"></i>}
                      aria-controls="panel1a-content"
                      className="database-headline"
                      id="panel1a-header"
                    >
                      SQL
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul>
                        <li className="pt-3 pb-3">
                          <div className="position-relative w-100">
                            <input
                              type="text"
                              value={sql}
                              name="sql"
                              placeholder="Buscar tabla"
                              className="w-100"
                              onChange={onChange}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <circle cx="10" cy="10" r="7" />
                              <line x1="21" y1="21" x2="15" y2="15" />
                            </svg>
                          </div>
                        </li>
                        {sources.length >= 1 &&
                          sources.map(
                            (source: any, index: any) =>
                              source.sourceTypeId ===
                                "62ecfeb3596600004a002b05" &&
                              (source?.name?.toLowerCase().includes(sql) ||
                                !sql) && (
                                <li>
                                  <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label={source?.name}
                                    onChange={(e) => {
                                      handleCheckbox(e, index, 0, source);
                                    }}
                                    checked={
                                      graf.find(
                                        (item) => item._id === source._id
                                      )
                                        ? true
                                        : false
                                    }
                                    labelPlacement="end"
                                  />
                                </li>
                              )
                          )}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className={
                    data?.length < 1 && !isLoading
                      ? "dashboard-content graphic-container  center-all"
                      : "dashboard-content graphic-container d-flex flex-column"
                  }
                >
                  {data?.length < 1 && !isLoading ? (
                    <p className="nodata">Sin datos a procesar</p>
                  ) : (
                    <div className="d-flex flex-column h-100">
                      <div className="graph-creation-container">
                        <div
                          id="charts"
                          className="center-all main-chart-container p-3"
                        >
                          {isLoading ? (
                            <CircularProgress />
                          ) : (
                            (data?.length > 0 || data2?.length > 0) && (
                              <>
                                {state === "" && <img src={grafica} alt="" />}
                                {state === "barchart" && (
                                  <Charts
                                    typeGraphic="VerticalBarChart"
                                    propsData={data}
                                    label={label}
                                    arrayColors={stateSectionPay}
                                    setColors={setStateSectionPay}
                                    conditional={typeData}
                                    data2={data2}
                                    claves={clave}
                                    colorDoble={doblecolor}
                                    setColorDoble={setDoblecolor}
                                    valuesGraph={valuesGraph}
                                    ejeyname={ejeyname}
                                    ejexname={ejexname}
                                  />
                                )}
                                {state === "radiuschart" && (
                                  <Charts
                                    typeGraphic="PieChart"
                                    propsData={data}
                                    label={label}
                                    arrayColors={stateSectionPay}
                                    setColors={setStateSectionPay}
                                    conditional={typeData}
                                    valuesGraph={valuesGraph}
                                    ejeyname={ejeyname}
                                    ejexname={ejexname}
                                  />
                                )}
                                {state === "linechart" && (
                                  <Charts
                                    typeGraphic="AreaChart"
                                    propsData={data}
                                    label={label}
                                    arrayColors={stateSectionPay}
                                    setColors={setStateSectionPay}
                                    conditional={typeData}
                                    valuesGraph={valuesGraph}
                                    ejeyname={ejeyname}
                                    ejexname={ejexname}
                                  />
                                )}
                                {state === "donutchart" && (
                                  <Charts
                                    typeGraphic="DoughnutChart"
                                    propsData={data}
                                    label={label}
                                    arrayColors={stateSectionPay}
                                    setColors={setStateSectionPay}
                                    conditional={typeData}
                                    valuesGraph={valuesGraph}
                                    ejeyname={ejeyname}
                                    ejexname={ejexname}
                                  />
                                )}
                                {state === "table" && (
                                  <TableChart
                                    data={data}
                                    label={label}
                                    table={table}
                                  />
                                )}
                                {state === "horizontalBarChart" && (
                                  <Charts
                                    typeGraphic="HorizontalBarChart"
                                    propsData={data}
                                    label={label}
                                    arrayColors={stateSectionPay}
                                    setColors={setStateSectionPay}
                                    conditional={typeData}
                                    valuesGraph={valuesGraph}
                                    ejeyname={ejeyname}
                                    ejexname={ejexname}
                                  />
                                )}
                                {state === "lineChartWithNodes" && (
                                  <Charts
                                    typeGraphic="LineChart"
                                    propsData={data}
                                    label={label}
                                    arrayColors={stateSectionPay}
                                    setColors={setStateSectionPay}
                                    conditional={typeData}
                                    valuesGraph={valuesGraph}
                                    ejeyname={ejeyname}
                                    ejexname={ejexname}
                                  />
                                )}
                                {/* {state === "linear" && (
                                              <DonutChartExaple data={data} label={label} />
                                              )} */}
                              </>
                            )
                          )}
                        </div>
                        <div className="p-3">
                          <div className="graph-settings ">
                            <div className="set-item">
                              <div className="set-headline">Gráfico</div>
                              <div className="set-children">
                                <div className="chart-type">
                                  <p className="set-item-title">
                                    Tipo de gráfica
                                  </p>
                                  <p className="set-item-label"></p>

                                  <div className="options">
                                    <button
                                      className={
                                        state === "table"
                                          ? "option active"
                                          : "option"
                                      }
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="Type chart name"
                                      onClick={() => setState("table")}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#2c3e50"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <rect
                                          x="4"
                                          y="4"
                                          width="16"
                                          height="16"
                                          rx="2"
                                        />
                                        <line x1="4" y1="10" x2="20" y2="10" />
                                        <line x1="10" y1="4" x2="10" y2="20" />
                                      </svg>
                                    </button>
                                    <button
                                      className={
                                        state === "barchart"
                                          ? "option active"
                                          : "option"
                                      }
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="Type chart name"
                                      onClick={() => setState("barchart")}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#2c3e50"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <rect
                                          x="3"
                                          y="12"
                                          width="6"
                                          height="8"
                                          rx="1"
                                        />
                                        <rect
                                          x="9"
                                          y="8"
                                          width="6"
                                          height="12"
                                          rx="1"
                                        />
                                        <rect
                                          x="15"
                                          y="4"
                                          width="6"
                                          height="16"
                                          rx="1"
                                        />
                                        <line x1="4" y1="20" x2="18" y2="20" />
                                      </svg>
                                    </button>
                                    <button
                                      className={
                                        state === "linechart"
                                          ? "option active"
                                          : "option"
                                      }
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="Type chart name"
                                      onClick={() => {
                                        setState("linechart");
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#2c3e50"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19" />
                                        <polyline points="4 12 7 8 11 10 16 4 20 8" />
                                      </svg>
                                    </button>
                                    <button
                                      className={
                                        state === "lineChartWithNodes"
                                          ? "option active"
                                          : "option"
                                      }
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="Type chart name"
                                      onClick={() =>
                                        setState("lineChartWithNodes")
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#2c3e50"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <line x1="4" y1="19" x2="20" y2="19" />
                                        <polyline points="4 15 8 9 12 11 16 6 20 10" />
                                      </svg>
                                    </button>
                                    <button
                                      className={
                                        state === "donutchart"
                                          ? "option active"
                                          : "option"
                                      }
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="Type chart name"
                                      onClick={() => setState("donutchart")}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#2c3e50"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M12 3v5m4 4h5" />
                                        <circle cx="12" cy="12" r="4" />
                                        <circle cx="12" cy="12" r="9" />
                                      </svg>
                                    </button>
                                    <button
                                      className={
                                        state === "radiuschart"
                                          ? "option active"
                                          : "option"
                                      }
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="Type chart name"
                                      onClick={() => setState("radiuschart")}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#2c3e50"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M12 12l-6.5 5.5" />
                                        <path d="M12 3v9h9" />
                                        <circle cx="12" cy="12" r="9" />
                                        <path d="M12 12l5 7.5" />
                                      </svg>
                                    </button>
                                    <button
                                      className={
                                        state === "horizontalBarChart"
                                          ? "option active"
                                          : "option"
                                      }
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="Type chart name"
                                      onClick={() =>
                                        setState("horizontalBarChart")
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#2c3e50"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <line x1="3" y1="18" x2="17" y2="18" />
                                        <path d="M9 9l3 3l-3 3" />
                                        <path d="M14 15l3 3l-3 3" />
                                        <line x1="3" y1="3" x2="3" y2="21" />
                                        <line x1="3" y1="12" x2="12" y2="12" />
                                        <path d="M18 3l3 3l-3 3" />
                                        <line x1="3" y1="6" x2="21" y2="6" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="set-children">
                                <p className="set-item-title">
                                  Valores y Labels
                                </p>
                                <div className="set-children-item">
                                  <p className="set-item-label">Tipo de dato</p>
                                  <div>
                                    <div className="options-switch items-2 text-tabs">
                                      <input
                                        defaultChecked={typeData ? true : false}
                                        type="radio"
                                        value="percent"
                                        id="percent"
                                        name="TypeOfValue"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        defaultChecked={typeData ? false : true}
                                        type="radio"
                                        id="cuantity"
                                        value="cuantity"
                                        name="TypeOfValue"
                                        onChange={handleRadioGraphs}
                                      />
                                      <div>
                                        <label htmlFor="percent" role="button">
                                          Porcentaje
                                        </label>
                                        <label htmlFor="cuantity" role="button">
                                          Cantidad
                                        </label>
                                      </div>
                                      <div className="slider">
                                        <div className="indicator"></div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <select
                                    onChange={(e) => handleChange(e, "type")}
                                    name=""
                                    id=""
                                  >
                                    <option selected={true} value="cantidad">
                                      cantidad
                                    </option>
                                    <option value="porcentaje">
                                      Porcentaje
                                    </option>
                                  </select> */}
                                </div>
                                <div className="set-children-item">
                                  <p className="set-item-label">
                                    Posición valor
                                  </p>
                                  <div>
                                    <div className="options-switch chart-styles items-4">
                                      <input
                                        defaultChecked={
                                          valuesGraph.positionValue === "center"
                                            ? true
                                            : false
                                        }
                                        type="radio"
                                        id="positionValue1"
                                        name="positionValue"
                                        value="center"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        defaultChecked={
                                          valuesGraph.positionValue ===
                                          "tangent"
                                            ? true
                                            : false
                                        }
                                        id="positionValue2"
                                        name="positionValue"
                                        value="tangent"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        defaultChecked={
                                          valuesGraph.positionValue ===
                                          "outside"
                                            ? true
                                            : false
                                        }
                                        id="positionValue3"
                                        name="positionValue"
                                        value="outside"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="positionValue4"
                                        name="positionValue"
                                        defaultChecked={
                                          valuesGraph.positionValue === "hidden"
                                            ? true
                                            : false
                                        }
                                        value="hidden"
                                        onChange={handleRadioGraphs}
                                      />
                                      <div>
                                        <label
                                          htmlFor="positionValue1"
                                          role="button"
                                          title="Pequeño"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M12 3v6l3 -3m-6 0l3 3" />
                                            <path d="M12 21v-6l3 3m-6 0l3 -3" />
                                            <line
                                              x1="4"
                                              y1="12"
                                              x2="5"
                                              y2="12"
                                            />
                                            <line
                                              x1="9"
                                              y1="12"
                                              x2="10"
                                              y2="12"
                                            />
                                            <line
                                              x1="14"
                                              y1="12"
                                              x2="15"
                                              y2="12"
                                            />
                                            <line
                                              x1="19"
                                              y1="12"
                                              x2="20"
                                              y2="12"
                                            />
                                          </svg>
                                          <span>Centro</span>
                                        </label>
                                        <label
                                          htmlFor="positionValue2"
                                          role="button"
                                          title="Mediano"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <line
                                              x1="12"
                                              y1="10"
                                              x2="12"
                                              y2="20"
                                            />
                                            <line
                                              x1="12"
                                              y1="10"
                                              x2="16"
                                              y2="14"
                                            />
                                            <line
                                              x1="12"
                                              y1="10"
                                              x2="8"
                                              y2="14"
                                            />
                                            <line
                                              x1="4"
                                              y1="4"
                                              x2="20"
                                              y2="4"
                                            />
                                          </svg>
                                          <span>Tangente</span>
                                        </label>
                                        <label
                                          htmlFor="positionValue3"
                                          role="button"
                                          title="Grande"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <line
                                              x1="12"
                                              y1="4"
                                              x2="12"
                                              y2="14"
                                            />
                                            <line
                                              x1="12"
                                              y1="4"
                                              x2="16"
                                              y2="8"
                                            />
                                            <line
                                              x1="12"
                                              y1="4"
                                              x2="8"
                                              y2="8"
                                            />
                                            <line
                                              x1="4"
                                              y1="20"
                                              x2="20"
                                              y2="20"
                                            />
                                          </svg>
                                          <span>Fuera</span>
                                        </label>
                                        <label
                                          htmlFor="positionValue4"
                                          role="button"
                                          title="Pequeño"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <line
                                              x1="18"
                                              y1="6"
                                              x2="6"
                                              y2="18"
                                            />
                                            <line
                                              x1="6"
                                              y1="6"
                                              x2="18"
                                              y2="18"
                                            />
                                          </svg>
                                          <span>Oculto</span>
                                        </label>
                                      </div>
                                      <div className="slider">
                                        <div className="indicator"></div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <select
                                    onChange={handleSelectGraphs}
                                    name="positionValue"
                                  >
                                    <option value="center">Centro</option>
                                    <option value="tangent">Tangente</option>
                                    <option value="outside">
                                      Fuera del grafico
                                    </option>
                                    <option value="hidden">Oculto</option>
                                  </select> */}
                                </div>

                                <div className="set-children-item">
                                  <p className="set-item-label">
                                    Posición label
                                  </p>
                                  <div className="options-switch chart-styles items-4">
                                    <input
                                      defaultChecked={
                                        valuesGraph.positionLabel === "bottom"
                                          ? true
                                          : false
                                      }
                                      type="radio"
                                      id="positionLabel1"
                                      name="positionLabel"
                                      value="bottom"
                                      onChange={handleRadioGraphs}
                                    />
                                    <input
                                      defaultChecked={
                                        valuesGraph.positionLabel === "right"
                                          ? true
                                          : false
                                      }
                                      type="radio"
                                      id="positionLabel2"
                                      name="positionLabel"
                                      value="right"
                                      onChange={handleRadioGraphs}
                                    />
                                    <input
                                      type="radio"
                                      defaultChecked={
                                        valuesGraph.positionLabel === "top"
                                          ? true
                                          : false
                                      }
                                      id="positionLabel3"
                                      name="positionLabel"
                                      value="top"
                                      onChange={handleRadioGraphs}
                                    />
                                    <input
                                      type="radio"
                                      defaultChecked={
                                        valuesGraph.positionLabel === "left"
                                          ? true
                                          : false
                                      }
                                      id="positionLabel4"
                                      name="positionLabel"
                                      value="left"
                                      onChange={handleRadioGraphs}
                                    />
                                    <div>
                                      <label
                                        htmlFor="positionLabel1"
                                        role="button"
                                        title="Pequeño"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <line
                                            x1="4"
                                            y1="20"
                                            x2="20"
                                            y2="20"
                                          />
                                          <rect
                                            x="9"
                                            y="4"
                                            width="6"
                                            height="12"
                                            rx="2"
                                          />
                                        </svg>
                                        <span>Abajo</span>
                                      </label>

                                      <label
                                        htmlFor="positionLabel2"
                                        role="button"
                                        title="Mediano"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <line
                                            x1="20"
                                            y1="4"
                                            x2="20"
                                            y2="20"
                                          />
                                          <rect
                                            x="4"
                                            y="9"
                                            width="12"
                                            height="6"
                                            rx="2"
                                          />
                                        </svg>
                                        <span>Derecha</span>
                                      </label>
                                      <label
                                        htmlFor="positionLabel3"
                                        role="button"
                                        title="Grande"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <line x1="4" y1="4" x2="20" y2="4" />
                                          <rect
                                            x="9"
                                            y="8"
                                            width="6"
                                            height="12"
                                            rx="2"
                                          />
                                        </svg>
                                        <span>Arriba</span>
                                      </label>
                                      <label
                                        htmlFor="positionLabel4"
                                        role="button"
                                        title="Pequeño"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <line x1="4" y1="4" x2="4" y2="20" />
                                          <rect
                                            x="8"
                                            y="9"
                                            width="12"
                                            height="6"
                                            rx="2"
                                          />
                                        </svg>
                                        <span>Izquierda</span>
                                      </label>
                                    </div>
                                    <div className="slider">
                                      <div className="indicator"></div>
                                    </div>
                                  </div>
                                  {/* <select
                                    onChange={handleSelectGraphs}
                                    name="positionLabel"
                                  >
                                    <option value="bottom">Abajo</option>
                                    <option value="rigth">Izquierda</option>
                                  </select> */}
                                </div>
                              </div>
                              <div className="set-children">
                                <div className="chart-type">
                                  <p className="set-item-title">COLOR</p>

                                  {data2.length >= 1 ||
                                    (state !== "linechart" &&
                                      state !== "lineChartWithNodes" && (
                                        <div className="options-switch items-2 text-tabs mb-2">
                                          <input
                                            defaultChecked={
                                              valuesGraph?.colorIndividual
                                                ? true
                                                : false
                                            }
                                            type="radio"
                                            value="individual"
                                            id="individual"
                                            name="colorIndividual"
                                            onChange={handleRadioGraphs}
                                          />
                                          <input
                                            defaultChecked={
                                              !valuesGraph?.colorIndividual
                                                ? true
                                                : false
                                            }
                                            type="radio"
                                            id="grupal"
                                            value="grupal"
                                            name="colorIndividual"
                                            onChange={handleRadioGraphs}
                                          />
                                          <div>
                                            <label
                                              htmlFor="individual"
                                              role="button"
                                            >
                                              Color individal
                                            </label>
                                            <label
                                              htmlFor="grupal"
                                              role="button"
                                            >
                                              Color grupal
                                            </label>
                                          </div>
                                          <div className="slider">
                                            <div className="indicator"></div>
                                          </div>
                                        </div>
                                      ))}

                                  {data2.legth >= 1 ? (
                                    <i className="mb-2">
                                      *Selecciona dato y cambia su color*
                                    </i>
                                  ) : (
                                    !valuesGraph.colorIndividual ||
                                    (state !== "linechart" &&
                                      state !== "lineChartWithNodes" && (
                                        <i className="mb-2">
                                          *Selecciona dato y cambia su color*
                                        </i>
                                      ))
                                  )}

                                  {data2.length >= 1 ? (
                                    ""
                                  ) : valuesGraph.colorIndividual &&
                                    state !== "linechart" &&
                                    state !== "lineChartWithNodes" ? (
                                    <>
                                      <p className="set-item-title">
                                        Gama de Color
                                      </p>
                                      <select
                                        name="handleColor"
                                        className="mb-2"
                                        onChange={handleColor}
                                      >
                                        <option value="none">
                                          Personalizado
                                        </option>
                                        <option value="coloresCalidos">
                                          Calidos
                                        </option>
                                        <option value="coloresFrios">
                                          Frios
                                        </option>
                                      </select>
                                    </>
                                  ) : (
                                    <div className="set-children-item">
                                      <p className="set-item-label">Color</p>
                                      <input
                                        type="color"
                                        className="colorSelect"
                                        name="allColors"
                                        onChange={colorTextGraphs}
                                        defaultValue={stateSectionPay?.[0]}
                                      />
                                    </div>
                                  )}

                                  <div className="set-children-item">
                                    <p className="set-item-label">
                                      Tipo de color
                                    </p>
                                    <div>
                                      <div className="options-switch items-2 text-tabs">
                                        <input
                                          defaultChecked={
                                            !valuesGraph?.colorDegrade
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          value="solid"
                                          id="solid"
                                          name="colorDegrade"
                                          onChange={handleRadioGraphs}
                                        />
                                        <input
                                          defaultChecked={
                                            valuesGraph?.colorDegrade
                                              ? true
                                              : false
                                          }
                                          type="radio"
                                          id="degrade"
                                          value="degrade"
                                          name="colorDegrade"
                                          onChange={handleRadioGraphs}
                                        />
                                        <div>
                                          <label htmlFor="solid" role="button">
                                            Solido
                                          </label>
                                          <label
                                            htmlFor="degrade"
                                            role="button"
                                          >
                                            Degradado
                                          </label>
                                        </div>
                                        <div className="slider">
                                          <div className="indicator"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {state !== "table" && (
                              <div className="set-item">
                                <div className="set-headline">Ejes</div>
                                <div className="set-children">
                                  <p className="set-item-title">
                                    Nombre de los ejes
                                  </p>
                                  <div className="set-children-item">
                                    <p className="set-item-label">Eje X</p>
                                    <input
                                      type="text"
                                      value={ejexname}
                                      name="ejexname"
                                      onChange={onChange}
                                      className="w-100"
                                    />
                                  </div>
                                  <div className="set-children-item">
                                    <p className="set-item-label">Eje Y</p>
                                    <input
                                      type="text"
                                      value={ejeyname}
                                      onChange={onChange}
                                      name="ejeyname"
                                      className="w-100"
                                    />
                                  </div>
                                </div>
                                <div className="set-children">
                                  <p className="set-item-title">
                                    Selección de ejes
                                  </p>
                                  <div className="set-children-item">
                                    <p className="set-item-label">Eje X</p>
                                    <select
                                      onChange={(e) => handleChange(e, "x")}
                                      name=""
                                      id=""
                                    >
                                      <option value="">Seleccione...</option>
                                      {axisxlabel &&
                                        axisxlabel?.map((nameAxis: any) =>
                                          chartActive ? (
                                            <option
                                              selected={
                                                nameAxis === label?.labelx
                                              }
                                              value={nameAxis}
                                            >
                                              {nameAxis}
                                            </option>
                                          ) : (
                                            <option value={nameAxis}>
                                              {nameAxis}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </div>
                                  <div className="set-children-item">
                                    <p className="set-item-label">Eje Y</p>
                                    <select
                                      onChange={(e) => handleChange(e, "y")}
                                      name=""
                                      id=""
                                    >
                                      <option value="">Seleccione...</option>
                                      {/* {axisylabel &&
                                        axisylabel.map((nameAxis: any) => (
                                          <option
                                            selected={nameAxis === label?.labely}
                                            value={nameAxis}
                                          >
                                            {nameAxis}
                                          </option>
                                        ))} */}
                                      {axisxlabel &&
                                        axisxlabel?.map((nameAxis: any) =>
                                          chartActive ? (
                                            <option
                                              selected={
                                                nameAxis === label?.labely
                                              }
                                              value={nameAxis}
                                            >
                                              {nameAxis}
                                            </option>
                                          ) : (
                                            <option value={nameAxis}>
                                              {nameAxis}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="set-item">
                              <div className="set-headline">Textos</div>
                              <div className="set-children">
                                <p className="set-item-title">Estilo ejes</p>
                                <div className="set-children-item">
                                  <p className="set-item-label">Tamaño</p>
                                  <div>
                                    <div className="options-switch chart-styles items-3">
                                      <input
                                        defaultChecked={
                                          valuesGraph.axisSize === "small"
                                            ? true
                                            : false
                                        }
                                        type="radio"
                                        id="sizeAxis1"
                                        name="axisSize"
                                        value="small"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        defaultChecked={
                                          valuesGraph.axisSize === "medium"
                                            ? true
                                            : false
                                        }
                                        id="sizeAxis2"
                                        name="axisSize"
                                        value="medium"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        defaultChecked={
                                          valuesGraph.axisSize === "large"
                                            ? true
                                            : false
                                        }
                                        id="sizeAxis3"
                                        name="axisSize"
                                        value="large"
                                        onChange={handleRadioGraphs}
                                      />
                                      <div>
                                        <label
                                          htmlFor="sizeAxis1"
                                          role="button"
                                          title="Pequeño"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24px"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="4" />
                                            <path d="M16,8v8" />
                                          </svg>
                                          <span>Pequeño</span>
                                        </label>

                                        <label
                                          htmlFor="sizeAxis2"
                                          role="button"
                                          title="Mediano"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="5.3" />
                                            <path d="M17.3,6.8v10.5" />
                                          </svg>
                                          <span>Mediano</span>
                                        </label>
                                        <label
                                          htmlFor="sizeAxis3"
                                          role="button"
                                          title="Grande"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24px"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="6.2" />
                                            <path d="M18.2,5.8v12.4" />
                                          </svg>
                                          <span>Grande</span>
                                        </label>
                                      </div>
                                      <div className="slider">
                                        <div className="indicator"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="set-children-item">
                                  <p className="set-item-label">Estilo</p>
                                  <div>
                                    <div className="options-switch chart-styles items-3">
                                      <input
                                        type="radio"
                                        id="axisStyle1"
                                        defaultChecked={
                                          valuesGraph.axisStyle === "normal"
                                            ? true
                                            : false
                                        }
                                        name="axisStyle"
                                        value="normal"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="axisStyle2"
                                        defaultChecked={
                                          valuesGraph.axisStyle === "bold"
                                            ? true
                                            : false
                                        }
                                        name="axisStyle"
                                        value="bold"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="axisStyle3"
                                        defaultChecked={
                                          valuesGraph.axisStyle === "oblique"
                                            ? true
                                            : false
                                        }
                                        name="axisStyle"
                                        value="oblique"
                                        onChange={handleRadioGraphs}
                                      />
                                      <div>
                                        <label
                                          htmlFor="axisStyle1"
                                          role="button"
                                          title="Pequeño"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M7 20v-12a4 4 0 0 1 4 -4h2a4 4 0 0 1 4 4v12" />
                                            <line
                                              x1="7"
                                              y1="13"
                                              x2="17"
                                              y2="13"
                                            />
                                          </svg>
                                          <span>Normal</span>
                                        </label>
                                        <label
                                          htmlFor="axisStyle2"
                                          role="button"
                                          title="Mediano"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="3"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M7 5h6a3.5 3.5 0 0 1 0 7h-6z" />
                                            <path d="M13 12h1a3.5 3.5 0 0 1 0 7h-7v-7" />
                                          </svg>
                                          <span>Bold</span>
                                        </label>
                                        <label
                                          htmlFor="axisStyle3"
                                          role="button"
                                          title="Grande"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <line
                                              x1="11"
                                              y1="5"
                                              x2="17"
                                              y2="5"
                                            />
                                            <line
                                              x1="7"
                                              y1="19"
                                              x2="13"
                                              y2="19"
                                            />
                                            <line
                                              x1="14"
                                              y1="5"
                                              x2="10"
                                              y2="19"
                                            />
                                          </svg>
                                          <span>Italic</span>
                                        </label>
                                      </div>

                                      <div className="slider">
                                        <div className="indicator"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="set-children-item">
                                  <p className="set-item-label">Color</p>
                                  <input
                                    type="color"
                                    className="colorSelect"
                                    name="axisColor"
                                    onChange={colorTextGraphs}
                                    defaultValue={valuesGraph?.axisColor}
                                  />
                                </div>
                              </div>
                              <div className="set-children">
                                <p className="set-item-title">Estilo valores</p>
                                <div className="set-children-item">
                                  <p className="set-item-label">Tamaño</p>
                                  <div>
                                    <div className="options-switch chart-styles items-3">
                                      <input
                                        type="radio"
                                        id="sizeValue1"
                                        defaultChecked={
                                          valuesGraph.valueSize === "small"
                                            ? true
                                            : false
                                        }
                                        name="valueSize"
                                        value="small"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="sizeValue2"
                                        defaultChecked={
                                          valuesGraph.valueSize === "medium"
                                            ? true
                                            : false
                                        }
                                        name="valueSize"
                                        value="medium"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="sizeValue3"
                                        defaultChecked={
                                          valuesGraph.valueSize === "large"
                                            ? true
                                            : false
                                        }
                                        name="valueSize"
                                        value="large"
                                        onChange={handleRadioGraphs}
                                      />
                                      <div>
                                        <label
                                          htmlFor="sizeValue1"
                                          role="button"
                                          title="Pequeño"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24px"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="4" />
                                            <path d="M16,8v8" />
                                          </svg>
                                          <span>Pequeño</span>
                                        </label>

                                        <label
                                          htmlFor="sizeValue2"
                                          role="button"
                                          title="Mediano"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="5.3" />
                                            <path d="M17.3,6.8v10.5" />
                                          </svg>
                                          <span>Mediano</span>
                                        </label>
                                        <label
                                          htmlFor="sizeValue3"
                                          role="button"
                                          title="Grande"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24px"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="6.2" />
                                            <path d="M18.2,5.8v12.4" />
                                          </svg>
                                          <span>Grande</span>
                                        </label>
                                      </div>
                                      <div className="slider">
                                        <div className="indicator"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="set-children-item">
                                  <p className="set-item-label">Estilo</p>
                                  <div>
                                    <div className="options-switch chart-styles items-3">
                                      <input
                                        type="radio"
                                        id="sizeStyle1"
                                        defaultChecked={
                                          valuesGraph.valueStyle === "normal"
                                            ? true
                                            : false
                                        }
                                        name="valueStyle"
                                        value="normal"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="sizeStyle2"
                                        defaultChecked={
                                          valuesGraph.valueStyle === "bold"
                                            ? true
                                            : false
                                        }
                                        name="valueStyle"
                                        value="bold"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="sizeStyle3"
                                        defaultChecked={
                                          valuesGraph.valueStyle === "oblique"
                                            ? true
                                            : false
                                        }
                                        name="valueStyle"
                                        value="oblique"
                                        onChange={handleRadioGraphs}
                                      />
                                      <div>
                                        <label
                                          htmlFor="sizeStyle1"
                                          role="button"
                                          title="Pequeño"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M7 20v-12a4 4 0 0 1 4 -4h2a4 4 0 0 1 4 4v12" />
                                            <line
                                              x1="7"
                                              y1="13"
                                              x2="17"
                                              y2="13"
                                            />
                                          </svg>
                                          <span>Normal</span>
                                        </label>
                                        <label
                                          htmlFor="sizeStyle2"
                                          role="button"
                                          title="Mediano"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="3"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M7 5h6a3.5 3.5 0 0 1 0 7h-6z" />
                                            <path d="M13 12h1a3.5 3.5 0 0 1 0 7h-7v-7" />
                                          </svg>
                                          <span>Bold</span>
                                        </label>
                                        <label
                                          htmlFor="sizeStyle3"
                                          role="button"
                                          title="Grande"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <line
                                              x1="11"
                                              y1="5"
                                              x2="17"
                                              y2="5"
                                            />
                                            <line
                                              x1="7"
                                              y1="19"
                                              x2="13"
                                              y2="19"
                                            />
                                            <line
                                              x1="14"
                                              y1="5"
                                              x2="10"
                                              y2="19"
                                            />
                                          </svg>
                                          <span>Italic</span>
                                        </label>
                                      </div>

                                      <div className="slider">
                                        <div className="indicator"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="set-children-item">
                                  <p className="set-item-label">Color</p>
                                  <input
                                    type="color"
                                    className="colorSelect"
                                    name="valueColor"
                                    onChange={colorTextGraphs}
                                    defaultValue={valuesGraph?.valueColor}
                                  />
                                </div>
                              </div>
                              <div className="set-children">
                                <p className="set-item-title">Estilo labels</p>
                                <div className="set-children-item">
                                  <p className="set-item-label">Tamaño</p>
                                  <div>
                                    <div className="options-switch chart-styles items-3">
                                      <input
                                        type="radio"
                                        id="sizeLabel1"
                                        defaultChecked={
                                          valuesGraph.labelSize === "small"
                                            ? true
                                            : false
                                        }
                                        name="labelSize"
                                        value="small"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="sizeLabel2"
                                        defaultChecked={
                                          valuesGraph.labelSize === "medium"
                                            ? true
                                            : false
                                        }
                                        name="labelSize"
                                        value="medium"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="sizeLabel3"
                                        defaultChecked={
                                          valuesGraph.labelSize === "large"
                                            ? true
                                            : false
                                        }
                                        name="labelSize"
                                        value="large"
                                        onChange={handleRadioGraphs}
                                      />
                                      <div>
                                        <label
                                          htmlFor="sizeLabel1"
                                          role="button"
                                          title="Pequeño"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24px"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="4" />
                                            <path d="M16,8v8" />
                                          </svg>
                                          <span>Pequeño</span>
                                        </label>

                                        <label
                                          htmlFor="sizeLabel2"
                                          role="button"
                                          title="Mediano"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="5.3" />
                                            <path d="M17.3,6.8v10.5" />
                                          </svg>
                                          <span>Mediano</span>
                                        </label>
                                        <label
                                          htmlFor="sizeLabel3"
                                          role="button"
                                          title="Grande"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24px"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="6.2" />
                                            <path d="M18.2,5.8v12.4" />
                                          </svg>
                                          <span>Grande</span>
                                        </label>
                                      </div>
                                      <div className="slider">
                                        <div className="indicator"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="set-children-item">
                                  <p className="set-item-label">Estilo</p>
                                  <div>
                                    <div className="options-switch chart-styles items-3">
                                      <input
                                        type="radio"
                                        id="sizeStyleLabel1"
                                        defaultChecked={
                                          valuesGraph.labelStyle === "normal"
                                            ? true
                                            : false
                                        }
                                        name="labelStyle"
                                        value="normal"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="sizeStyleLabel2"
                                        defaultChecked={
                                          valuesGraph.labelStyle === "bold"
                                            ? true
                                            : false
                                        }
                                        name="labelStyle"
                                        value="bold"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="sizeStyleLabel3"
                                        defaultChecked={
                                          valuesGraph.labelStyle === "oblique"
                                            ? true
                                            : false
                                        }
                                        name="labelStyle"
                                        value="oblique"
                                        onChange={handleRadioGraphs}
                                      />
                                      <div>
                                        <label
                                          htmlFor="sizeStyleLabel1"
                                          role="button"
                                          title="Pequeño"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M7 20v-12a4 4 0 0 1 4 -4h2a4 4 0 0 1 4 4v12" />
                                            <line
                                              x1="7"
                                              y1="13"
                                              x2="17"
                                              y2="13"
                                            />
                                          </svg>
                                          <span>Normal</span>
                                        </label>
                                        <label
                                          htmlFor="sizeStyleLabel2"
                                          role="button"
                                          title="Mediano"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="3"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M7 5h6a3.5 3.5 0 0 1 0 7h-6z" />
                                            <path d="M13 12h1a3.5 3.5 0 0 1 0 7h-7v-7" />
                                          </svg>
                                          <span>Bold</span>
                                        </label>
                                        <label
                                          htmlFor="sizeStyleLabel3"
                                          role="button"
                                          title="Grande"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <line
                                              x1="11"
                                              y1="5"
                                              x2="17"
                                              y2="5"
                                            />
                                            <line
                                              x1="7"
                                              y1="19"
                                              x2="13"
                                              y2="19"
                                            />
                                            <line
                                              x1="14"
                                              y1="5"
                                              x2="10"
                                              y2="19"
                                            />
                                          </svg>
                                          <span>Italic</span>
                                        </label>
                                      </div>

                                      <div className="slider">
                                        <div className="indicator"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="set-children-item">
                                  <p className="set-item-label">Color</p>
                                  <input
                                    className="colorSelect"
                                    type="color"
                                    name="labelColor"
                                    onChange={colorTextGraphs}
                                    defaultValue={valuesGraph?.labelColor}
                                  />
                                </div>
                              </div>
                              <div className="set-children">
                                <p className="set-item-title">
                                  Estilo leyendas
                                </p>
                                <div className="set-children-item">
                                  <p className="set-item-label">Tamaño</p>
                                  <div>
                                    <div className="options-switch chart-styles items-3">
                                      <input
                                        type="radio"
                                        id="sizeLeyend1"
                                        defaultChecked={
                                          valuesGraph.legendSize === "small"
                                            ? true
                                            : false
                                        }
                                        name="legendSize"
                                        value="small"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="sizeLeyend2"
                                        defaultChecked={
                                          valuesGraph.legendSize === "medium"
                                            ? true
                                            : false
                                        }
                                        name="legendSize"
                                        value="medium"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="sizeLeyend3"
                                        defaultChecked={
                                          valuesGraph.legendSize === "large"
                                            ? true
                                            : false
                                        }
                                        name="legendSize"
                                        value="large"
                                        onChange={handleRadioGraphs}
                                      />
                                      <div>
                                        <label
                                          htmlFor="sizeLeyend1"
                                          role="button"
                                          title="Pequeño"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24px"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="4" />
                                            <path d="M16,8v8" />
                                          </svg>
                                          <span>Pequeño</span>
                                        </label>

                                        <label
                                          htmlFor="sizeLeyend2"
                                          role="button"
                                          title="Mediano"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="5.3" />
                                            <path d="M17.3,6.8v10.5" />
                                          </svg>
                                          <span>Mediano</span>
                                        </label>
                                        <label
                                          htmlFor="sizeLeyend3"
                                          role="button"
                                          title="Grande"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24px"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <circle cx="12" cy="12" r="6.2" />
                                            <path d="M18.2,5.8v12.4" />
                                          </svg>
                                          <span>Grande</span>
                                        </label>
                                      </div>
                                      <div className="slider">
                                        <div className="indicator"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="set-children-item">
                                  <p className="set-item-label">Estilo</p>
                                  <div>
                                    <div className="options-switch chart-styles items-3">
                                      <input
                                        type="radio"
                                        id="legenStyle1"
                                        defaultChecked={
                                          valuesGraph.legendStyle === "normal"
                                            ? true
                                            : false
                                        }
                                        name="legendStyle"
                                        value="normal"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="legenStyle2"
                                        defaultChecked={
                                          valuesGraph.legendStyle === "bold"
                                            ? true
                                            : false
                                        }
                                        name="legendStyle"
                                        value="bold"
                                        onChange={handleRadioGraphs}
                                      />
                                      <input
                                        type="radio"
                                        id="legenStyle3"
                                        defaultChecked={
                                          valuesGraph.legendStyle === "oblique"
                                            ? true
                                            : false
                                        }
                                        name="legendStyle"
                                        value="oblique"
                                        onChange={handleRadioGraphs}
                                      />
                                      <div>
                                        <label
                                          htmlFor="legenStyle1"
                                          role="button"
                                          title="Pequeño"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M7 20v-12a4 4 0 0 1 4 -4h2a4 4 0 0 1 4 4v12" />
                                            <line
                                              x1="7"
                                              y1="13"
                                              x2="17"
                                              y2="13"
                                            />
                                          </svg>
                                          <span>Normal</span>
                                        </label>
                                        <label
                                          htmlFor="legenStyle2"
                                          role="button"
                                          title="Mediano"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="3"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <path d="M7 5h6a3.5 3.5 0 0 1 0 7h-6z" />
                                            <path d="M13 12h1a3.5 3.5 0 0 1 0 7h-7v-7" />
                                          </svg>
                                          <span>Bold</span>
                                        </label>
                                        <label
                                          htmlFor="legenStyle3"
                                          role="button"
                                          title="Grande"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#2c3e50"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            />
                                            <line
                                              x1="11"
                                              y1="5"
                                              x2="17"
                                              y2="5"
                                            />
                                            <line
                                              x1="7"
                                              y1="19"
                                              x2="13"
                                              y2="19"
                                            />
                                            <line
                                              x1="14"
                                              y1="5"
                                              x2="10"
                                              y2="19"
                                            />
                                          </svg>
                                          <span>Italic</span>
                                        </label>
                                      </div>

                                      <div className="slider">
                                        <div className="indicator"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="set-children-item">
                                  <p className="set-item-label">Color</p>
                                  <input
                                    className="colorSelect"
                                    type="color"
                                    name="legendColor"
                                    onChange={colorTextGraphs}
                                    defaultValue={valuesGraph?.legendColor}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="template-download-pdf">
            <div className="logo-template">
              {" "}
              <img src={logo} /> grupo altum
            </div>
            <div id="printWrapper" className="barchar-pdf main-chart-container">
              {isLoading ? (
                <CircularProgress />
              ) : (
                (data?.length > 0 || data2.length >= 1) && (
                  <>
                    {state === "" && <img src={grafica} alt="" />}
                    {state === "barchart" && (
                      <Charts
                        typeGraphic="VerticalBarChart"
                        propsData={data}
                        label={label}
                        arrayColors={stateSectionPay}
                        setColors={setStateSectionPay}
                        conditional={typeData}
                        data2={data2}
                        colorDoble={doblecolor}
                        claves={clave}
                        valuesGraph={valuesGraph}
                        ejeyname={ejeyname}
                        ejexname={ejexname}
                      />
                    )}
                    {state === "radiuschart" && (
                      <Charts
                        typeGraphic="PieChart"
                        propsData={data}
                        label={label}
                        arrayColors={stateSectionPay}
                        setColors={setStateSectionPay}
                        conditional={typeData}
                        valuesGraph={valuesGraph}
                        ejeyname={ejeyname}
                        ejexname={ejexname}
                      />
                    )}
                    {state === "linechart" && (
                      <Charts
                        typeGraphic="AreaChart"
                        propsData={data}
                        label={label}
                        arrayColors={stateSectionPay}
                        setColors={setStateSectionPay}
                        conditional={typeData}
                        valuesGraph={valuesGraph}
                        ejeyname={ejeyname}
                        ejexname={ejexname}
                      />
                    )}
                    {state === "donutchart" && (
                      <Charts
                        typeGraphic="DoughnutChart"
                        propsData={data}
                        label={label}
                        arrayColors={stateSectionPay}
                        setColors={setStateSectionPay}
                        conditional={typeData}
                        valuesGraph={valuesGraph}
                        ejeyname={ejeyname}
                        ejexname={ejexname}
                      />
                    )}
                    {state === "table" && (
                      <TableChart data={data} label={label} table={table} />
                    )}
                    {state === "horizontalBarChart" && (
                      <Charts
                        typeGraphic="HorizontalBarChart"
                        propsData={data}
                        label={label}
                        arrayColors={stateSectionPay}
                        setColors={setStateSectionPay}
                        conditional={typeData}
                        valuesGraph={valuesGraph}
                        ejeyname={ejeyname}
                        ejexname={ejexname}
                      />
                    )}
                    {state === "lineChartWithNodes" && (
                      <Charts
                        typeGraphic="LineChart"
                        propsData={data}
                        label={label}
                        arrayColors={stateSectionPay}
                        setColors={setStateSectionPay}
                        conditional={typeData}
                        valuesGraph={valuesGraph}
                        ejeyname={ejeyname}
                        ejexname={ejexname}
                      />
                    )}
                  </>
                )
              )}
            </div>
            <div className="footer-template">
              <div>
                <p>Gráfica correspondiente al Dashboard Altum Analytics</p>
                <h6>Derechos reservados VISE</h6>
              </div>
              <div>
                <h6>www.altumanalytics.com</h6>
              </div>
            </div>
          </div>
        </main>

        <ModalCombine
          data={graf}
          typeJoin={typeJoin}
          setKeys={setKeys}
          keys={keys}
          source={combine}
          setTypeJoin={setTypeJoin}
          getSql={getsql}
        />
        <ModalFilter
          data={selectFilter}
          setFiltros={setFiltros}
          filtros={filtros}
          select={select}
        />
        <ModalOrder data={selected} setOrder={setOrder} order={order} />
        <ModalVariables
          data={selectFilter}
          setSelect={setSelected}
          setData={setselectFilter}
          selected={selected}
          setExtra={setExtra}
          extra={extra}
        />
      </div>
    </>
  );
};
