import { types } from '../types/types';
const initialState = {
  
    dashboards: [],
    dashboardActive:{},
    totalPages:0
}

export const dashboardReducer = ( state = initialState, action:any ) => {

    switch ( action.type ) {

        case types.loadDashboard:
            return {
                ...state,
               dashboards: [ ...action.payload ]
                
            }

        // case types.newSource:
        //     return {
        //         ...state,
        //         sources: [ ...action.payload ]
                
        //     }
        // case types.loadSource:
        //     return {
        //         ...state,
        //         sources2: [ ...action.payload ]
                
        //     }    
        // case types.clientLoad:
        //         return {
        //             ...state,
        //             client: [ ...action.payload ]
                    
        //         }
        // case types.companyLoad:
        //             return {
        //                 ...state,
        //                 company: [ ...action.payload ]
                        
        //             }    

        
        case types.DashboardSetActive:
            return {
                ...state,
                dashboardActive: action.payload
            }

        case types.DashboardClearActiveDashboardt:
            return {
                ...state,
                dashboardActive: {}
            }
            
            case types.DashboardSetTotalpages:
                return {
                    ...state,
                    totalPages: action.payload
                }
        // case types.sourceUpdated:
        //     return {
        //         ...state,
        //         sources: [ ...action.payload ]
        //     }
        
        //     case types.sourceDeleted:
        //         return {
        //             ...state,
        //            sources:[ ...action.payload ]
                  
        //         }

        // case types.sourceLoaded:
        //     return {
        //         ...state,
        //         users: [ ...action.payload ]
        //     } 

        default:
            return state;
    }

}