
import { NavLink } from 'react-router-dom';
import logo from '../assets/img/logo-altum.png';
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../actions/auth';
import { useEffect, useState } from 'react';

export const Navbar = () => {
    const dispatch = useDispatch();

    const { mode } = useSelector( (state:any) => state.ui );
    useEffect(() => {
        
    }, [])
    
  return (
  <>
  
        
  <aside className={`${mode?`dark-mode`:''}`}>
        <NavLink to="/home" className="aside-logo img-contain"><img src={logo} alt=""/></NavLink>
        <div className="dashboard-nav">
            <NavLink to="/home"  className={({isActive})=> isActive ? 'active' :''}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <polyline points="5 12 3 12 12 3 21 12 19 12" />
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                  </svg>
                  <p>Inicio</p>
                  </NavLink>
            <NavLink to="/datafont"  className={({isActive})=> isActive ? 'active' :''}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <ellipse cx="12" cy="6" rx="8" ry="3"></ellipse>
                    <path d="M4 6v6a8 3 0 0 0 16 0v-6" />
                    <path d="M4 12v6a8 3 0 0 0 16 0v-6" />
                </svg>
                <p>Fuentes de datos</p>
            </NavLink>
            <NavLink to="/charts"  className={({isActive})=> isActive ? 'active' :''}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 3v9h9" />
                    <circle cx="12" cy="12" r="9" />
                </svg>
                <p>Graficos</p>
                </NavLink>
                <NavLink to="/dashboards"  className={({isActive})=> isActive ? 'active' :''}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="4" y="4" width="6" height="5" rx="2" />
                    <rect x="4" y="13" width="6" height="7" rx="2" />
                    <rect x="14" y="4" width="6" height="7" rx="2" />
                    <rect x="14" y="15" width="6" height="5" rx="2" />
                </svg>
                <p>Dashboards</p>
                </NavLink>
        </div>
        <button  onClick={()=>dispatch(startLogout())}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                <path d="M7 12h14l-3 -3m0 6l3 -3" />
            </svg>
            <p>Cerrar sesión</p>
        </button>
    </aside>
  
  </>
  )
}
