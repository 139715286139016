import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadChartbyid } from "../../actions/chart";
import { useParams } from "react-router-dom";
import logo from "../../assets/img/logo-altum.jpg";
import { TableChart } from "../home/TableChart";
import { Charts } from "../home/handleCharts/index";

export const CompartidoMovil = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { charts } = useSelector((state: any) => state.chart);
  const [stateSectionPay, setStateSectionPay] = useState([]);

  const colors = charts[0]?.charts?.color?.split(/,(.+)/)[1];
  const color1 = colors
    ?.split("%, ")[0]
    ?.substring(0, colors?.split("%, ")[0].length - 3);

  const color2 = colors
    ?.split("%, ")[1]
    ?.substring(0, colors?.split("%, ")[1].length - 5);
  useEffect(() => {
    dispatch(loadChartbyid(id));
  }, []);
  useEffect(() => {
    if (charts.length > 0) {
      setStateSectionPay(charts[0]?.charts?.stateSectionPay);
    }
  }, [charts]);

  return (
    <div>
      {
        <div className="share-content">
          {charts[0]?.charts.typegraf === "barchart" && (
            <Charts
              typeGraphic="VerticalBarChart"
              propsData={charts[0]?.data}
              label={charts[0]?.charts.label}
              arrayColors={stateSectionPay}
              setColors={setStateSectionPay}
              data2={charts[0]?.chart?.doble}
              colorDoble={charts[0]?.chart?.doblecolor}
              claves={charts[0]?.chart?.clave}
              conditional={charts[0]?.charts.typeData}
              valuesGraph={charts[0]?.charts.valuesGraph}
              ejeyname={charts[0]?.charts?.ejeyname}
              ejexname={charts[0]?.charts?.ejexname}
            />
          )}
          {charts[0]?.charts.typegraf === "radiuschart" && (
            <Charts
              typeGraphic="PieChart"
              propsData={charts[0]?.data}
              label={charts[0]?.charts.label}
              arrayColors={stateSectionPay}
              setColors={setStateSectionPay}
              conditional={charts[0]?.charts.typeData}
              valuesGraph={charts[0]?.charts.valuesGraph}
              ejeyname={charts[0]?.charts?.ejeyname}
              ejexname={charts[0]?.charts?.ejexname}
            />
          )}
          {charts[0]?.charts.typegraf === "linechart" && (
            <Charts
              typeGraphic="AreaChart"
              propsData={charts[0]?.data}
              label={charts[0]?.charts.label}
              arrayColors={stateSectionPay}
              setColors={setStateSectionPay}
              conditional={charts[0]?.charts.typeData}
              valuesGraph={charts[0]?.charts.valuesGraph}
              ejeyname={charts[0]?.charts?.ejeyname}
              ejexname={charts[0]?.charts?.ejexname}
            />
          )}
          {charts[0]?.charts.typegraf === "donutchart" && (
            <Charts
              typeGraphic="DoughnutChart"
              propsData={charts[0]?.data}
              label={charts[0]?.charts.label}
              arrayColors={stateSectionPay}
              setColors={setStateSectionPay}
              conditional={charts[0]?.charts.typeData}
              valuesGraph={charts[0]?.charts?.valuesGraph}
              ejeyname={charts[0]?.charts?.ejeyname}
              ejexname={charts[0]?.charts?.ejexname}
            />
          )}
          {charts[0]?.charts.typegraf === "table" && (
            <TableChart
              data={charts[0]?.data}
              label={charts[0]?.charts.label}
              table={charts[0]?.table}
            />
          )}
          {charts[0]?.charts.typegraf === "horizontalBarChart" && (
            <Charts
              typeGraphic="HorizontalBarChart"
              propsData={charts[0]?.data}
              label={charts[0]?.charts.label}
              arrayColors={stateSectionPay}
              setColors={setStateSectionPay}
              conditional={charts[0]?.charts.typeData}
              valuesGraph={charts[0]?.charts?.valuesGraph}
              ejeyname={charts[0]?.charts?.ejeyname}
              ejexname={charts[0]?.charts?.ejexname}
            />
          )}
          {charts[0]?.charts.typegraf === "lineChartWithNodes" && (
            <Charts
              typeGraphic="LineChart"
              propsData={charts[0]?.data}
              label={charts[0]?.charts.label}
              arrayColors={stateSectionPay}
              setColors={setStateSectionPay}
              conditional={charts[0]?.charts.typeData}
              valuesGraph={charts[0]?.charts?.valuesGraph}
              ejeyname={charts[0]?.charts?.ejeyname}
              ejexname={charts[0]?.charts?.ejexname}
            />
          )}
        </div>
      }
    </div>
  );
};
