import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { types } from '../types/types';

export const newDashboardCreate= (dashboard:any,navigate:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( 'dashboard/new',dashboard,'POST' );
         const body = await resp.json();
     
      
        if( body.ok ) {
            
       
            navigate(`/dashboards`)
            
        } else {
            console.log(body)
        }
    }
}

export const loadDashboards= (id:string,limit:number,page:number,search?:string,value?:any):any => {

    return async(dispatch:any) => {
        try {
          
         
            const resp = await fetchConToken( `dashboard/${id}?limit=${limit}&page=${page}&search=${search?search:''}&dateinit=${value &&value[0] !==null?new Date(value[0]['$d']).getTime():''}&datefin=${value &&value[1] !==null?new Date(value[1]['$d']).getTime():''}` );
            const body = await resp.json();
            
    
            
         
      
        if( body.ok ) {
            const dashboards=body.dashboards.docs
            dispatch(dashboardsTotalpages(body.dashboards.totalPages));
            dispatch(dashboardsLoaded(dashboards))
 
         
        } else {
            console.log(body)
        }
        } catch (error) {
            console.log(error)
        }
        
    }
}
export const loadDashboardbyid= (id:any):any => {
  
    return async(dispatch:any) => {
        try {
           
         
            const resp = await fetchSinToken( `dashboard/by/${id}` );
            const body = await resp.json();
       
    
            
    
      
        if( body.ok ) {
            const dashboard=body.dashboard
         
            dispatch(dashboardSetActive(dashboard))
 
         
        } else {
            console.log(body)
        }
        } catch (error) {
            console.log(error)
        }
        
    }
}
export const dashboardSetActive = (dashboard:any) => ({
    type: types.DashboardSetActive,
    payload: dashboard
});

export const updateDashboard= (dashboard:any,navigate:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( `dashboard/update/${dashboard._id}`,dashboard,'PUT' );
         const body = await resp.json();
     
      
        if( body.ok ) {
            
            dispatch(dashboardsLoaded(body.Dashboards.reverse()))
            navigate(`/dashboards`)
            
        } else {
            console.log(body)
        }
    }
}


export const dashboardClearActive = () => ({ type: types.DashboardClearActiveDashboardt });
const dashboardsLoaded = (dashboards) => ({ type: types.loadDashboard,payload:dashboards });
const dashboardsTotalpages = (total) => ({ type: types.DashboardSetTotalpages,payload:total });


export const deleteDashboard= (dashboard:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( `dashboard/delete/${dashboard}`,{},'DELETE' );
         const body = await resp.json();
     
      
        if( body.ok ) {
            
            dispatch(dashboardsLoaded(body.Dashboards.reverse()))
           
            
        } else {
            console.log(body)
        }
    }
}