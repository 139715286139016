import { types } from '../types/types';
const initialState = {
  
    charts: [],
    chartActive:{},
    totalPages:0
}

export const chartReducer = ( state = initialState, action:any ) => {

    switch ( action.type ) {

        case types.loadCharts:
            return {
                ...state,
               charts: [ ...action.payload ]
                
            }

        case types.chartSetTotalpages:
                return {
                    ...state,
                    totalPages: action.payload
                }            

        
        case types.chartSetActive:
            return {
                ...state,
                chartActive: action.payload
            }

        case types.chartClearActiveChart:
            return {
                ...state,
                chartActive: {}
            }
            

        // case types.sourceUpdated:
        //     return {
        //         ...state,
        //         sources: [ ...action.payload ]
        //     }
        
        //     case types.sourceDeleted:
        //         return {
        //             ...state,
        //            sources:[ ...action.payload ]
                  
        //         }

        // case types.sourceLoaded:
        //     return {
        //         ...state,
        //         users: [ ...action.payload ]
        //     } 

        default:
            return state;
    }

}