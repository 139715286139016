import { types } from "../types/types";

export const uimode = (mode) => ( { type: types.uimode,payload:mode });
export const uiOpenModal = () => ({ type: types.uiOpenModal });

export const uiCloseModal = () => ({ type: types.uiCloseModal });
export const uiOpenModal2 = () => ({ type: types.uiOpenModal2 });
export const uiCloseModal2 = () => ({ type: types.uiCloseModal2 });
export const uiOpenModal3 = () => ({ type: types.uiOpenModal3 });
export const uiCloseModal3 = () => ({ type: types.uiCloseModal3 });
export const uiOpenModal4 = () => ({ type: types.uiOpenModal4 });
export const uiCloseModal4 = () => ({ type: types.uiCloseModal4 });
export const uiOpenModal5 = () => ({ type: types.uiOpenModal5 });
export const uiCloseModal5 = () => ({ type: types.uiCloseModal5 });
export const uiOpenModal6 = () => ({ type: types.uiOpenModal6 });
export const uiCloseModal6 = () => ({ type: types.uiCloseModal6 });
export const uiOpenModal7 = () => ({ type: types.uiOpenModal7 });
export const uiCloseModal7 = () => ({ type: types.uiCloseModal7 });
export const uiOpenModal8 = () => ({ type: types.uiOpenModal8 });
export const uiCloseModal8 = () => ({ type: types.uiCloseModal8 });
export const uiOpenModal9 = () => ({ type: types.uiOpenModal9 });
export const uiCloseModal9 = () => ({ type: types.uiCloseModal9 });
export const uiOpenModal10 = () => ({ type: types.uiOpenModal10 });
export const uiCloseModal10 = () => ({ type: types.uiCloseModal10 });
export const uiOpenModal11 = () => ({ type: types.uiOpenModal11 });
export const uiCloseModal11 = () => ({ type: types.uiCloseModal11 });