import { handleText } from "./handleText";

export const validateDateFormat = (
  isDate: string,
  data: unknown[],
  label: string,
  type
) => {
  const optionsFormat: any = {
    month: "short",
    day: "numeric",
  };

  let date = new Date(isDate).toLocaleDateString("de-DE", optionsFormat);

  let isValidDate = Date.parse(date);

  if (isNaN(isValidDate)) {
    return data?.map((x: unknown) => handleText(x[label], data, type));
  } else {
    return data?.map((x: unknown) => {

      let newDate = new Date(x[label]);
      newDate.setHours(newDate.getHours() + 5);
      // newDate.toLocaleDateString("de-DE", optionsFormat)
      return newDate.toLocaleDateString("de-DE", optionsFormat);
    });
  }
};
