import React from 'react'
import { Provider } from 'react-redux'
import { Navigation } from './routes/navigate'
import { store } from './store/store'

export const AltumApp = () => {
  return (
    <>
    <Provider store={store}>

    <Navigation/>
    </Provider>
    </>
  )
}
