import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";

import { AuthComponent } from "../pages/auth/AuthComponent";
import { Charts } from "../pages/home/Charts";
import { CreateChart } from "../pages/home/CreateChart";
import { CreateDashboards } from "../pages/home/CreateDashboards";
import { Dashboards } from "../pages/home/Dashboards";
import { Datafont } from "../pages/home/Datafont";

import { Home } from "../pages/home/Home";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { startChecking } from "../actions/auth";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Compartido } from "../pages/public/Compartido";
import { BarChartHorizontal } from "../pages/home/components/BarChartHorizontal";
import { DonutChartExaple } from "../pages/home/components/DonutChartExaple";
import { LineChartWithNodes } from "../pages/home/components/LineChartWithNodes";
import { CompartirDashboard } from "../pages/public/CompartirDashboard";
import { CompartidoMovil } from "../pages/public/CompartidoMovil";
import { CompartirDashboardMovil } from "../pages/public/CompartirDashboardMovil";

export const Navigation = () => {
  const dispatch = useDispatch();
  const { uid } = useSelector((state: any) => state.auth);

  useEffect(() => {
    dispatch(startChecking());
  }, [dispatch, uid]);

  return (
    <>
      <BrowserRouter>
        <div className="main-layout">
          <Routes>
            <Route
              path="home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="datafont"
              element={
                <PrivateRoute>
                  <Datafont />
                </PrivateRoute>
              }
            />
            <Route
              path="charts"
              element={
                <PrivateRoute>
                  <Charts />
                </PrivateRoute>
              }
            />
            <Route
              path="create-chart"
              element={
                <PrivateRoute>
                  <CreateChart />
                </PrivateRoute>
              }
            />
            <Route
              path="dashboards"
              element={
                <PrivateRoute>
                  <Dashboards />
                </PrivateRoute>
              }
            />
            <Route
              path="create-dashboards"
              element={
                <PrivateRoute>
                  <CreateDashboards />
                </PrivateRoute>
              }
            />

            <Route
              path="auth"
              element={
                <PublicRoute>
                  <AuthComponent />
                </PublicRoute>
              }
            />

            <Route
              path="chart/:id"
              element={
                <PublicRoute>
                  <Compartido />
                </PublicRoute>
              }
            />
            <Route
              path="mchart/:id"
              element={
                <PublicRoute>
                  <CompartidoMovil />
                </PublicRoute>
              }
            />
            <Route
              path="mdashboard/:id"
              element={
                <PublicRoute>
                  <CompartirDashboardMovil />
                </PublicRoute>
              }
            />
            <Route path="/*" element={<Navigate to="/auth" replace />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
};
