import { useEffect, useState } from "react";
// import { population as data } from '../../types/data';
import {
  DataGridPro,
  GridRowModel,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-pro";

function updateRowPosition(
  initialIndex: number,
  newIndex: number,
  rows: Array<GridRowModel>
): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, Math.random() * 500 + 100); // simulate network latency
  });
}
export const TableChart = ({ data, label, table }) => {
  const [rows, setRows] = useState(data);
  // const [loading, setLoading] = useState(true);

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    setRows(newRows);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);

  return (
    <div style={{ width: "100%" }} className="h-100">
      <div className="dashboard-table p-0 h-100">
        <div className="table-container dash-table">
          {/*         
       <DataGridPro
        loading={loading}
        rows={data}
        getRowId={data => data.tableid}
        columns={table}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
      /> */}

          <DataGridPro
            rows={rows}
            columns={table}
            getRowId={(rows) => rows?.tableid}
            disableChildrenFiltering
            onRowOrderChange={handleRowOrderChange}
            disableColumnSelector
          />

          {/* <table>
            <thead>
              <tr>
                {table?.map((item) => (
                  <th>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((data: any) => (
                <tr>
                  {table?.map((item) => (
                    <td>
                      {" "}
                      {data[item]?.value
                        ? data[item]?.value
                        : data[item]
                        ? data[item]
                        : " "}{" "}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
      </div>
    </div>
  );
};
