import logo from "../../assets/img/logo-altum.png";
import logogoogle from "../../assets/img/logo-google-sheets.png";
import database from "../../assets/img/database-icon.jpg";
import { Navbar } from "../../components/Navbar";
import { Header } from "../../components/Header";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSource,
  downloadSourceData,
  loadSource,
  sourceSetActive,
} from "../../actions/source";
import {
  uiOpenModal,
  uiOpenModal2,
  uiOpenModal4,
  uiOpenModal8,
} from "../../actions/ui";
import { ModalSource } from "../../components/ModalSource";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import { ModalSheets } from "../../components/ModalSheets";
import { ModalDatafont } from "../../components/ModalDatafont";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  DateRangePicker,
  DateRange,
} from "@mui/x-date-pickers-pro/DateRangePicker";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
export const Datafont = () => {
  const dispatch = useDispatch();
  const limit: number = 14;
  const [page, setPage] = useState(1);
  const { mode } = useSelector((state: any) => state.ui);
  const { uid } = useSelector((state: any) => state.auth);
  const { sources, sources2, totalPages } = useSelector(
    (state: any) => state.source
  );
  const [value, setValue] = useState<DateRange<Dayjs>>([null, null]);
  const divStyle = { height: "15px", marginRight: "12px" };
  const [compartidos, setCompartidos] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(loadSource(uid, limit, page, searchTerm, value));
  }, [page]);

  const handleChangePage = (e, value) => {
    if (value === 0) {
      if (parseInt(e.target.value) === 0) {
        setPage(1);
      }
      if (parseInt(e.target.value) > totalPages) {
        setPage(totalPages);
      } else {
        setPage(parseInt(e.target.value));
      }
    }
    if (value === -1) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
    if (value === 1) {
      if (page < totalPages) {
        setPage(page + 1);
      }
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleChangeDate = (newValue: any) => {
    setValue(newValue);
    if (newValue[0] && newValue[1]) {
      setPage(1);

      dispatch(loadSource(uid, limit, 1, searchTerm, newValue));
    }
  };
  const handleDownload = async (id: string, table: string) => {
    const data = await downloadSourceData(id);

    const fileName = `${table}.xlsx`;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${table}`);
    XLSX.writeFile(wb, fileName);
  };

  const handleEditSource = (source) => {
    dispatch(sourceSetActive(source));
    dispatch(uiOpenModal());
  };
  const handleOpenSource = (source) => {
    dispatch(sourceSetActive(source));
    dispatch(uiOpenModal8());
  };
  const handleEditClient = (source) => {
    dispatch(sourceSetActive(source));
    dispatch(uiOpenModal2());
    dispatch(uiOpenModal());
  };
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setPage(1);
      dispatch(loadSource(uid, limit, 1, searchTerm, value));
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchTerm]);
  const handleDelete = (source) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "buttonsAlert",
        cancelButton: "buttonCancel",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Delete Datafont",
        text: "Are you sure you want to delete this datafont?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your datafont has been deleted.",
            "success"
          );
          dispatch(deleteSource(source));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelled", "", "error");
        }
      });
  };
  return (
    <>
      <div className="dashboard">
        <Navbar />

        <Header />

        <main className={`${mode ? `dark-mode` : ""}`}>
          <div className="page-content">
            <div className="row">
              <div className="col-md-12">
                <div className="main-col">
                  <div className="headline">
                    <h3 className="title-dashboard">Fuentes de datos</h3>
                    <div className="actions">
                      <button
                        className="al-btn primary-btn"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Nueva fuente
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => dispatch(uiOpenModal())}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#2c3e50"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <ellipse cx="12" cy="6" rx="8" ry="3" />
                              <path d="M4 6v8m5.009 .783c.924 .14 1.933 .217 2.991 .217c4.418 0 8 -1.343 8 -3v-6" />
                              <path d="M11.252 20.987c.246 .009 .496 .013 .748 .013c4.418 0 8 -1.343 8 -3v-6m-18 7h7m-3 -3l3 3l-3 3" />
                            </svg>
                            MySQL
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>

                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => dispatch(uiOpenModal4())}
                          >
                            <img src={logogoogle} style={divStyle} alt="" />{" "}
                            Google Sheets{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="dashboard-content center-all">
                    {sources.length >= 1 ? (
                      <div className="dashboard-table ">
                        <div className="table-actions">
                          <div className="filtering">
                            <FormControl sx={{ m: 1, minWidth: 180 }}>
                              <InputLabel id="demo-simple-select-helper-label">
                                Origen
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Origen"
                                defaultValue={"propio"}
                              >
                                <MenuItem value={"propio"}>Propio</MenuItem>
                              </Select>
                            </FormControl>
                            <TextField
                              id="outlined-basic"
                              label="Buscar"
                              variant="outlined"
                              value={searchTerm}
                              onChange={(e) => handleSearch(e)}
                            />
                            <div className="data-range d-flex">
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                localeText={{
                                  start: "Fecha de inicio",
                                  end: "Fecha de fin",
                                }}
                                className="data-range"
                              >
                                <DateRangePicker
                                  value={value}
                                  onChange={handleChangeDate}
                                  className="data-range"
                                  renderInput={(startProps, endProps) => (
                                    <>
                                      <TextField {...startProps} />
                                      <Box sx={{ mx: 2 }}> Hasta </Box>
                                      <TextField {...endProps} />
                                    </>
                                  )}
                                />
                              </LocalizationProvider>
                              <IconButton
                                aria-label="delete"
                                className="ms-2"
                                onClick={() => {
                                  dispatch(
                                    loadSource(uid, limit, 1, searchTerm, [
                                      null,
                                      null,
                                    ])
                                  );
                                  setValue([null, null]);
                                  setPage(1);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28"
                                  height="28"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="#2c3e50"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                  <path d="M9 12l6 0" />
                                </svg>
                              </IconButton>
                            </div>
                          </div>
                          {/* <div className="tabs">
                            <button
                              onClick={() => setCompartidos(false)}
                              className={
                                compartidos
                                  ? "colorButton"
                                  : "colorButton active"
                              }
                            >
                              {" "}
                              Propios
                            </button>
                          </div> */}

                          <div className="al-pagination justify-content-end">
                            <button
                              disabled={page == 1 ? true : false}
                              onClick={(e) => handleChangePage(e, -1)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#2c3e50"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <polyline points="15 6 9 12 15 18" />
                              </svg>
                            </button>
                            <div className="pages">
                              <input
                                type="number"
                                value={page}
                                min={1}
                                max={totalPages && totalPages}
                                onChange={(e) => handleChangePage(e, 0)}
                              />
                              <span>de {totalPages && totalPages}</span>
                            </div>
                            <button
                              disabled={page == totalPages ? true : false}
                              onClick={(e) => handleChangePage(e, 1)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#2c3e50"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <polyline points="9 6 15 12 9 18" />
                              </svg>
                            </button>
                          </div>
                        </div>

                        <div className="table-container dash-table">
                          <table>
                            <thead>
                              <tr>
                                <th style={{ width: "50px" }}>
                                  <div className="al-checkbox">
                                    <input type="checkbox" />
                                  </div>
                                </th>
                                <th style={{ paddingLeft: "5px" }}>Nombre</th>
                                <th>Tipo</th>
                                <th className="">Estado</th>
                                <th className="">Cliente</th>
                                <th className="">Compañia</th>
                                <th className="">Fecha de creación</th>

                                <th className="">Opciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!compartidos &&
                                sources.length >= 1 &&
                                sources.map((source: any) => (
                                  <tr>
                                    <td>
                                      <div className="al-checkbox">
                                        <input type="checkbox" />
                                      </div>
                                    </td>
                                    <td className="df-name">
                                      <a
                                        href="#"
                                        onClick={() => handleOpenSource(source)}
                                      >
                                        <img
                                          src={
                                            source?.sourceTypeId ===
                                            "62ecfeb3596600004a002b04"
                                              ? logogoogle
                                              : logo
                                          }
                                          alt=""
                                        />
                                        {source?.rname
                                          ? source?.rname
                                          : source?.name}
                                      </a>
                                    </td>

                                    <td>
                                      {" "}
                                      {source?.sourceTypeId ===
                                      "62ecfeb3596600004a002b04"
                                        ? "Google Sheets"
                                        : "Mysql"}{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      {source?.loading
                                        ? "Cargando"
                                        : "Completado"}{" "}
                                    </td>
                                    <td>{source?.client?.name}</td>
                                    <td>{source?.company?.name}</td>
                                    <td className="date">
                                      {new Date(
                                        source.createdAt
                                      ).toLocaleString()}
                                    </td>
                                    <td>
                                      <button
                                        id="dropdownMenuButton"
                                        className="graph-actions"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <circle cx="12" cy="12" r="1" />
                                          <circle cx="12" cy="19" r="1" />
                                          <circle cx="12" cy="5" r="1" />
                                        </svg>
                                      </button>
                                      <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={() =>
                                              handleOpenSource(source)
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#2c3e50"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <rect
                                                x="4"
                                                y="4"
                                                width="16"
                                                height="16"
                                                rx="2"
                                              />
                                              <line
                                                x1="4"
                                                y1="10"
                                                x2="20"
                                                y2="10"
                                              />
                                              <line
                                                x1="10"
                                                y1="4"
                                                x2="10"
                                                y2="20"
                                              />
                                            </svg>
                                            Abrir
                                          </a>
                                        </li>
                                        {source?.sourceTypeId !==
                                          "62ecfeb3596600004a002b04" && (
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              onClick={() =>
                                                handleEditSource(source)
                                              }
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#2c3e50"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                />
                                                <circle
                                                  cx="9.5"
                                                  cy="9.5"
                                                  r="6.5"
                                                />
                                                <rect
                                                  x="10"
                                                  y="10"
                                                  width="11"
                                                  height="11"
                                                  rx="2"
                                                />
                                              </svg>
                                              Editar
                                            </a>
                                          </li>
                                        )}
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleEditClient(source)
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#2c3e50"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <circle cx="9" cy="7" r="4" />
                                              <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                              <path d="M16 11h6m-3 -3v6" />
                                            </svg>
                                            Cambiar Cliente
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleDownload(
                                                source?._id,
                                                source?.tableName
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#2c3e50"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                              <polyline points="7 11 12 16 17 11" />
                                              <line
                                                x1="12"
                                                y1="4"
                                                x2="12"
                                                y2="16"
                                              />
                                            </svg>
                                            Descargar
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleDelete(source?._id)
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#2c3e50"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <line
                                                x1="4"
                                                y1="7"
                                                x2="20"
                                                y2="7"
                                              />
                                              <line
                                                x1="10"
                                                y1="11"
                                                x2="10"
                                                y2="17"
                                              />
                                              <line
                                                x1="14"
                                                y1="11"
                                                x2="14"
                                                y2="17"
                                              />
                                              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                            Eliminar
                                          </a>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                ))}

                              {compartidos &&
                                sources2.length >= 1 &&
                                sources2.map((source: any) => (
                                  <tr>
                                    <td>
                                      <div className="al-checkbox">
                                        <input type="checkbox" />
                                      </div>
                                    </td>
                                    <td className="df-name">
                                      <a href="datafont-combine.html">
                                        <img src={logo} alt="" />
                                        {source?.name}
                                      </a>
                                    </td>
                                    <td>
                                      {" "}
                                      {source?.loading
                                        ? "Cargando"
                                        : "Cargado"}{" "}
                                    </td>
                                    <td>{source?.client?.name}</td>
                                    <td>{source?.company?.name}</td>
                                    <td className="date">
                                      {new Date(
                                        source.createdAt
                                      ).toLocaleString()}
                                    </td>
                                    <td>
                                      <button
                                        id="dropdownMenuButton"
                                        className="graph-actions"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#2c3e50"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <circle cx="12" cy="12" r="1" />
                                          <circle cx="12" cy="19" r="1" />
                                          <circle cx="12" cy="5" r="1" />
                                        </svg>
                                      </button>
                                      <ul
                                        className="dropdown-menu dropdown-menu-end"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <li>
                                          <a className="dropdown-item" href="#">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#2c3e50"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <rect
                                                x="4"
                                                y="4"
                                                width="16"
                                                height="16"
                                                rx="2"
                                              />
                                              <line
                                                x1="4"
                                                y1="10"
                                                x2="20"
                                                y2="10"
                                              />
                                              <line
                                                x1="10"
                                                y1="4"
                                                x2="10"
                                                y2="20"
                                              />
                                            </svg>
                                            Abrir
                                          </a>
                                        </li>
                                        {/* <li><a className="dropdown-item" onClick={()=>handleEditSource(source)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="#2c3e50" fill="none" stroke-linecap="round"
                                                                    stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <circle cx="9.5" cy="9.5" r="6.5" />
                                                                    <rect x="10" y="10" width="11" height="11" rx="2" />
                                                                </svg>Editar</a></li>
                                                        <li><a className="dropdown-item" href="#">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="#2c3e50" fill="none" stroke-linecap="round"
                                                                    stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <circle cx="9" cy="7" r="4" />
                                                                    <path
                                                                        d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                                    <path d="M16 11h6m-3 -3v6" />
                                                                </svg>Cambiar Cliente</a></li> */}
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleDownload(
                                                source?._id,
                                                source?.tableName
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#2c3e50"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                              <polyline points="7 11 12 16 17 11" />
                                              <line
                                                x1="12"
                                                y1="4"
                                                x2="12"
                                                y2="16"
                                              />
                                            </svg>
                                            Descargar
                                          </a>
                                        </li>
                                        {/* <li><a className="dropdown-item" href="#">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="#2c3e50" fill="none" stroke-linecap="round"
                                                                    stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <line x1="4" y1="7" x2="20" y2="7" />
                                                                    <line x1="10" y1="11" x2="10" y2="17" />
                                                                    <line x1="14" y1="11" x2="14" y2="17" />
                                                                    <path
                                                                        d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                    <path
                                                                        d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                </svg>Eliminar</a></li> */}
                                      </ul>
                                    </td>
                                  </tr>
                                ))}
                              {/*                                       
                                            <tr>
                                                <td>
                                                    <div className="al-checkbox"><input type="checkbox"/></div>
                                                </td>
                                                <td className="df-name"><a href="datafont-combine.html"><img
                                                            src={logogoogle} alt=""/> Desempeño unitario
                                                        de puntos de operación</a></td>


                                                <td>Abrand name</td>
                                                <td className="date">28/04/22 - 9:50pm</td>
                                                <td>
                                                    <button id="dropdownMenuButton" className="graph-actions"
                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50"
                                                            fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <circle cx="12" cy="12" r="1" />
                                                            <circle cx="12" cy="19" r="1" />
                                                            <circle cx="12" cy="5" r="1" />
                                                        </svg>
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end"
                                                        aria-labelledby="dropdownMenuButton">
                                                        <li><a className="dropdown-item" href="#"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="#2c3e50" fill="none" stroke-linecap="round"
                                                                    stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <rect x="4" y="4" width="16" height="16" rx="2" />
                                                                    <line x1="4" y1="10" x2="20" y2="10" />
                                                                    <line x1="10" y1="4" x2="10" y2="20" />
                                                                </svg>Abrir</a></li>
                                                        <li><a className="dropdown-item" href="datafont-combine.html">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="#2c3e50" fill="none" stroke-linecap="round"
                                                                    stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <circle cx="9.5" cy="9.5" r="6.5" />
                                                                    <rect x="10" y="10" width="11" height="11" rx="2" />
                                                                </svg>Combinar</a></li>
                                                        <li><a className="dropdown-item" href="#">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="#2c3e50" fill="none" stroke-linecap="round"
                                                                    stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <circle cx="9" cy="7" r="4" />
                                                                    <path
                                                                        d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                                    <path d="M16 11h6m-3 -3v6" />
                                                                </svg>Asignar cliente</a></li>
                                                        <li><a className="dropdown-item" href="#">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="#2c3e50" fill="none" stroke-linecap="round"
                                                                    stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path
                                                                        d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                                                    <polyline points="7 11 12 16 17 11" />
                                                                    <line x1="12" y1="4" x2="12" y2="16" />
                                                                </svg>Descargar</a></li>
                                                        <li><a className="dropdown-item" href="#">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" stroke-width="1.5"
                                                                    stroke="#2c3e50" fill="none" stroke-linecap="round"
                                                                    stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <line x1="4" y1="7" x2="20" y2="7" />
                                                                    <line x1="10" y1="11" x2="10" y2="17" />
                                                                    <line x1="14" y1="11" x2="14" y2="17" />
                                                                    <path
                                                                        d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                    <path
                                                                        d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                </svg>Eliminar</a></li>
                                                    </ul>
                                                </td>
                                            </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center conect-options">
                        <h4 className="content-title">
                          Conecta una fuente de datos
                        </h4>
                        <p className="mb-4">
                          Aún no has conectado ninguna fuente, selecciona la
                          fuente de datos que vas a usar.
                        </p>
                        <div
                          className="datatypes-options"
                          onClick={() => dispatch(uiOpenModal4())}
                        >
                          <div className="datatype-card">
                            <img src={logogoogle} alt="" />
                            Google sheets
                          </div>

                          <div
                            className="datatype-card"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => dispatch(uiOpenModal())}
                          >
                            <img src={database} alt="" />
                            Otras fuentes
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ModalSource />
      <ModalSheets />
      <ModalDatafont />
    </>
  );
};
