import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { types } from '../types/types';

export const newChartCreate= (chart:any,navigate:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( 'chart/new',chart,'POST' );
         const body = await resp.json();
     
      
        if( body.ok ) {
            
       
            navigate(`/charts`)
            
        } else {
            console.log(body)
        }
    }
}

export const loadCharts= (id:string,limit:number,page:number,search?:string,value?:any):any => {
    return async(dispatch:any) => {
        try {
           
            const resp = await fetchConToken( `chart/${id}?limit=${limit}&page=${page}&search=${search?search:''}&dateinit=${value && value[0] !==null?new Date(value[0]['$d']).getTime():''}&datefin=${value && value[1] !==null?new Date(value[1]['$d']).getTime():''}` );
            const body = await resp.json();
           
         
       
    
            
   
      
        if( body.ok ) {
            
            const charts=body.charts.docs
            dispatch(chartTotalpages(body.charts.totalPages));
            dispatch(chartsLoaded(charts))
 
         
        } else {
            console.log(body)
        }
        } catch (error) {
            console.log(error)
        }
        
    }
}
export const loadChartbyid= (id:any):any => {
    return async(dispatch:any) => {
        try {
           
            const resp = await fetchSinToken( `chart/by/${id}` );
            const body = await resp.json();
       
    
            
    
      
        if( body.ok ) {
            const charts=body.charts
 
            dispatch(chartsLoaded(charts.reverse()))
 
         
        } else {
            console.log(body)
        }
        } catch (error) {
            console.log(error)
        }
        
    }
}
export const chartSetActive = (chart:any) => ({
    type: types.chartSetActive,
    payload: chart
});
const chartTotalpages = (total) => ({ type: types.chartSetTotalpages,payload:total });
export const updateChart= (chart:any,navigate:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( `chart/update/${chart._id}`,chart,'PUT' );
         const body = await resp.json();
     
      
        if( body.ok ) {
            
            dispatch(chartsLoaded(body.charts.reverse()))
            navigate(`/charts`)
            
        } else {
            console.log(body)
        }
    }
}


export const chartClearActive = () => ({ type: types.chartClearActiveChart });
const chartsLoaded = (chart) => ({ type: types.loadCharts,payload:chart });



export const deleteChart= (chart:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( `chart/delete/${chart}`,{},'DELETE' );
         const body = await resp.json();
     
      
        if( body.ok ) {
            
            dispatch(chartsLoaded(body.charts.reverse()))
           
            
        } else {
            console.log(body)
        }
    }
}