import { Navbar } from "../../components/Navbar";
import { Header } from "../../components/Header";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, ChangeEvent } from "react";

import { loadCharts } from "../../actions/chart";
import { ModalCharts } from "../../components/ModalCharts";
import { uiOpenModal6, uiOpenModal7 } from "../../actions/ui";
import { useForm } from "../../hooks/useForm";
import {
  newDashboardCreate,
  dashboardClearActive,
  updateDashboard,
  loadDashboardbyid,
} from "../../actions/dashboard";
import { useNavigate } from "react-router-dom";
import { ShowcaseLayout } from "./components/GenerarLayout";
import MenuFilterDashboard from "./components/MenuFilterDashboard";
import html2canvas from "html2canvas";
import { Dropdown } from "react-bootstrap";
export const CreateDashboards = () => {
  const initialValue = {
    name: "",
    namefilter: "",
  };
  const { FormData, onChange, setFormData } = useForm(initialValue);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mode } = useSelector((state: any) => state.ui);
  const [filtros, setFiltros] = useState([]);
  const [graficos, setGraficos] = useState<any>([
    { name: "", campo: "", index: 0 },
  ]);
  const [loading, setLoading] = useState(false);
  const [generar, setGenerar] = useState(false);
  const { uid } = useSelector((state: any) => state.auth);
  const [index, setIndex] = useState<any>();

  const { name, namefilter } = FormData;
  const { dashboardActive } = useSelector((state: any) => state.dashboard);
  const [mounted, setMounted] = useState(false);
  const [layout, setLayout] = useState<any>([]);
  const [typeFilter, setTypeFilter] = useState("");

  function onLayoutChange(newLayout) {
    setLayout(newLayout);
  }

  const handleOpen = (index) => {
    setIndex(index);

    dispatch(uiOpenModal6());
  };

  useEffect(() => {
    dispatch(loadCharts(uid, 0, 0));
  }, []);

  useEffect(() => {}, []);

  const handleSave = () => {
    setLoading(true);
    const nuevoArray = [
      ...layout.filter(
        (i) =>
          i?.chart?._id ||
          !i?.text?.includes("Escribe aqui...") ||
          !i?.image?.includes("Escribe aqui...")
      ),
    ];
    onLayoutChange(nuevoArray);
    const printArea: any = document.getElementById("printWrapper");
    setGenerar(true);
    setTimeout(() => {
      html2canvas(printArea).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpg");

        nuevoArray.map((i) => {
          if (i?.chart?._id) {
            i.chart.data = [];
          }
        });

        if (dashboardActive?._id) {
          dispatch(
            updateDashboard(
              {
                _id: dashboardActive._id,
                name,
                userid: uid,
                clientid: "63111f3b4c1587d105f6d62a",
                rows: nuevoArray,
                filtros,
                imgData,
                img: dashboardActive.img,
              },
              navigate
            )
          );
        } else {
          dispatch(
            newDashboardCreate(
              {
                name,
                userid: uid,
                clientid: "63111f3b4c1587d105f6d62a",
                rows: nuevoArray,
                filtros,
                imgData,
              },
              navigate
            )
          );
        }
      });
    }, 1000);
  };
  const handleSelect = (e, index, option?: number) => {
    const selectedIndex = e.target.value; // obtiene el índice de la opción seleccionada
    const selectedChartName = layout[selectedIndex]?.chart?.name; // obtiene el nombre del chart seleccionado
    if (option === 1) {
      setGraficos((prevGraficos) =>
        prevGraficos.map((obj, i) => {
          if (i === index) {
            return { ...obj, campo: e.target.value };
          }
          return obj;
        })
      );
    } else {
      setGraficos((prevGraficos) =>
        prevGraficos.map((obj, i) => {
          if (i === index) {
            return { ...obj, name: selectedChartName, index: e.target.value };
          }
          return obj;
        })
      );
    }
  };
  const handleSelecttypeFilter = (e) => {
    setTypeFilter(e.target.value); // obtiene el índice de la opción seleccionada
  };
  const handleAddGraf = () => {
    setGraficos([...graficos, { name: "", campo: "", index: 0 }]);
  };
  const handleRemoveGraf = (posicion) => {
    const nuevoArray = graficos.filter((o, i) => i !== posicion);

    setGraficos(nuevoArray);
  };
  const handleSaveFilter = () => {
    setFiltros([{ name: namefilter, type: typeFilter, graficos }, ...filtros]);
  };
  useEffect(() => {
    if (dashboardActive?._id) {
      setFormData({ name: dashboardActive?.name, namefilter: "" });

      setLayout(dashboardActive?.rows);
    }
  }, [dashboardActive]);
  const handleEditFilter = (event, id) => {
    const value = event.target.value;
    const newFilters = filtros.map((filter, int) => {
      if (int === id) {
        return {
          ...filter,
          [event.target.name]: value,
        };
      } else {
        return filter;
      }
    });

    setFiltros(newFilters);
  };
  const handleEditGraficFilter = (event, index, index2) => {
    const value = event.target.value;
    const name = event.target.name;
    const Filtro = [...filtros];
    const graficos = [...filtros[index2].graficos];
    if (name === "name") {
      const selectedChartName = layout[event.target.value]?.chart?.name; // obtiene el nombre del chart seleccionado
      graficos[index] = {
        ...graficos[index],
        [name]: selectedChartName,
        index: value,
      };
    } else {
      graficos[index] = { ...graficos[index], [name]: value };
    }
    Filtro[index2] = { ...filtros[index2], graficos: graficos };

    setFiltros(Filtro);
  };
  useEffect(() => {
    if (dashboardActive?._id) {
      dispatch(loadDashboardbyid(dashboardActive?._id));
      setFiltros(dashboardActive.filtros);
    }
  }, []);
  const addNew = (type) => {
    if (type === 1) {
      onLayoutChange([
        ...layout,
        {
          x: Math.abs(layout.length - 4 * Math.floor(layout.length / 4)),
          y: Math.floor(layout.length / 4),
          w: 1,
          h: 1,
          i: `${layout.length + 1}`,
          text: "Escribe aqui...",
        },
      ]);
    } else {
      if (type === 2) {
        onLayoutChange([
          ...layout,
          {
            x: Math.abs(layout.length - 4 * Math.floor(layout.length / 4)),
            y: Math.floor(layout.length / 4),
            w: 1,
            h: 3,
            i: `${layout.length + 1}`,
            image: "escribe aqui...",
          },
        ]);
      } else {
        onLayoutChange([
          ...layout,
          {
            x: Math.abs(layout.length - 4 * Math.floor(layout.length / 4)),
            y: Math.floor(layout.length / 4),
            w: 1,
            h: 3,
            i: `${layout.length + 1}`,
          },
        ]);
      }
    }
  };

  useEffect(() => {}, [index]);

  return (
    <>
      <div className="dashboard">
        <Navbar />

        <Header />

        <main className={`${mode ? `dark-mode` : ""}`}>
          <div className="page-content">
            <div className="row">
              <div className="col-md-12">
                <div className="headline">
                  <input
                    type="text"
                    onChange={onChange}
                    className="graph-title"
                    name="name"
                    placeholder="Some Dashboard Example Name"
                    value={name}
                  />
                  <div className="actions">
                    {/* <button className="al-btn secondary-btn">Compartir</button> */}
                    <button
                      onClick={handleSave}
                      disabled={loading === true ? true : false}
                      className="al-btn primary-btn"
                    >
                      {loading === true ? "Guardando..." : "Guardar"}
                    </button>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="add-block al-btn primary-btn h-100"
                      variant="none"
                      id="dropdown-custom-components"
                    >
                      Agregar bloque
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className=" mt-2"
                      aria-labelledby="filter-button-set-1"
                    >
                      <Dropdown.Item onClick={() => addNew(0)} className="py-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-chart-infographic"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx="7" cy="7" r="4" />
                          <path d="M7 3v4h4" />
                          <line x1="9" y1="17" x2="9" y2="21" />
                          <line x1="17" y1="14" x2="17" y2="21" />
                          <line x1="13" y1="13" x2="13" y2="21" />
                          <line x1="21" y1="12" x2="21" y2="21" />
                        </svg>{" "}
                        Grafico
                      </Dropdown.Item>
                      {/* <Dropdown.Item className="py-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7" />
                          <line x1="9" y1="4" x2="9" y2="17" />
                          <line x1="15" y1="7" x2="15" y2="20" />
                        </svg>{" "}
                        Mapa
                      </Dropdown.Item> */}
                      <Dropdown.Item onClick={() => addNew(1)} className="py-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M7 12h10" />
                          <path d="M7 4v16" />
                          <path d="M17 4v16" />
                          <path d="M15 20h4" />
                          <path d="M15 4h4" />
                          <path d="M5 20h4" />
                          <path d="M5 4h4" />
                        </svg>{" "}
                        Texto
                      </Dropdown.Item>
                      {/* <Dropdown.Item className="py-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="4" y1="6" x2="20" y2="6" />
                          <line x1="4" y1="12" x2="20" y2="12" />
                          <line x1="4" y1="18" x2="16" y2="18" />
                        </svg>{" "}
                        Texto
                      </Dropdown.Item> */}
                      <Dropdown.Item onClick={() => addNew(2)} className="py-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="15" y1="8" x2="15.01" y2="8" />
                          <rect x="4" y="4" width="16" height="16" rx="3" />
                          <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
                          <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
                        </svg>{" "}
                        Imagen
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="dashboard-filter bg-gray w-100">
                    <p>Filtros</p>
                    <div className="filter-group">
                      <div>
                        <MenuFilterDashboard
                          namefilter={namefilter}
                          onChange={onChange}
                          handleSelecttypeFilter={handleSelecttypeFilter}
                          graficos={graficos}
                          handleSelect={handleSelect}
                          layout={layout}
                          typeFilter={typeFilter}
                          handleRemoveGraf={handleRemoveGraf}
                          handleAddGraf={handleAddGraf}
                          handleSaveFilter={handleSaveFilter}
                        />
                      </div>
                      {filtros.length > 0 &&
                        filtros.map((i, id) => (
                          <div className="filter-item">
                            <button
                              id="filter-button-set-1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {i.name}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#2c3e50"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <circle cx="6" cy="10" r="2" />
                                <line x1="6" y1="4" x2="6" y2="8" />
                                <line x1="6" y1="12" x2="6" y2="20" />
                                <circle cx="12" cy="16" r="2" />
                                <line x1="12" y1="4" x2="12" y2="14" />
                                <line x1="12" y1="18" x2="12" y2="20" />
                                <circle cx="18" cy="7" r="2" />
                                <line x1="18" y1="4" x2="18" y2="5" />
                                <line x1="18" y1="9" x2="18" y2="20" />
                              </svg>
                            </button>
                            <div
                              className="dropdown-menu mt-2 filter-set"
                              aria-labelledby="filter-button-set-1"
                            >
                              <form className="px-4 py-3">
                                <h4>Editar filtro</h4>
                                <hr />
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="al-field">
                                      <label htmlFor="exampleDropdownFormEmail1">
                                        Nombre del filtro
                                      </label>
                                      <input
                                        type="text"
                                        name="name"
                                        value={i.name}
                                        onChange={(event) =>
                                          handleEditFilter(event, id)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="al-field">
                                      <label htmlFor="exampleDropdownFormEmail1">
                                        Tipo de filtro
                                      </label>
                                      <select
                                        name="type"
                                        id=""
                                        onChange={(event) =>
                                          handleEditFilter(event, id)
                                        }
                                      >
                                        <option>Selecione...</option>
                                        <option
                                          value="DATETIME"
                                          selected={i.type === "DATETIME"}
                                        >
                                          Fecha
                                        </option>
                                        <option
                                          value="STRING"
                                          selected={i.type === "STRING"}
                                        >
                                          Texto
                                        </option>
                                        <option
                                          value="FLOAT64"
                                          selected={i.type === "FLOAT64INT64"}
                                        >
                                          Numero
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <div className="al-field mb-2">
                                      <label htmlFor="sss">
                                        Selección de gráficos (1)
                                      </label>
                                    </div>
                                    <div className="graphic-selector">
                                      {i.graficos.length > 0 &&
                                        i.graficos.map((item, index) => (
                                          <div className="d-flex select-graph-item align-items-end">
                                            <div className="row w-100">
                                              <div className="col-md-6">
                                                <div className="al-field mb-0">
                                                  <label htmlFor="exampleDropdownFormEmail1">
                                                    Gráfico
                                                  </label>
                                                  <select
                                                    name="name"
                                                    id=""
                                                    onChange={(e) =>
                                                      handleEditGraficFilter(
                                                        e,
                                                        index,
                                                        id
                                                      )
                                                    }
                                                  >
                                                    <option>
                                                      Selecione...
                                                    </option>
                                                    {layout.length > 0 &&
                                                      layout.map(
                                                        (chart, e) =>
                                                          chart?.chart
                                                            ?.name && (
                                                            <option
                                                              value={e}
                                                              selected={
                                                                item.name ===
                                                                chart?.chart
                                                                  ?.name
                                                              }
                                                            >
                                                              {chart?.chart?.name.toLocaleLowerCase()}
                                                            </option>
                                                          )
                                                      )}
                                                  </select>
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="al-field mb-0">
                                                  <label htmlFor="exampleDropdownFormEmail1">
                                                    Campo
                                                  </label>
                                                  <select
                                                    name="campo"
                                                    id=""
                                                    onChange={(e) =>
                                                      handleEditGraficFilter(
                                                        e,
                                                        index,
                                                        id
                                                      )
                                                    }
                                                  >
                                                    <option value="">
                                                      Seleccione...
                                                    </option>

                                                    {layout[item.index]?.chart
                                                      ?.selectFilter &&
                                                      layout[
                                                        item.index
                                                      ]?.chart?.selectFilter.map(
                                                        (campos) =>
                                                          campos?.column_name &&
                                                          i.type.includes(
                                                            campos?.data_type
                                                          ) && (
                                                            <option
                                                              value={
                                                                campos?.column_name
                                                              }
                                                              selected={
                                                                item.campo ===
                                                                campos?.column_name
                                                              }
                                                            >
                                                              {
                                                                campos?.column_name
                                                              }
                                                            </option>
                                                          )
                                                      )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <button>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#2c3e50"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                />
                                                <line
                                                  x1="18"
                                                  y1="6"
                                                  x2="6"
                                                  y2="18"
                                                />
                                                <line
                                                  x1="6"
                                                  y1="6"
                                                  x2="18"
                                                  y2="18"
                                                />
                                              </svg>
                                            </button>
                                          </div>
                                        ))}
                                      <hr />
                                      <button
                                        type="submit"
                                        className="al-btn secondary-btn"
                                      >
                                        Añadir gráfico
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end filter-actions">
                                  <button
                                    type="submit"
                                    className="al-btn tertiary-btn"
                                  >
                                    Borrar filtro
                                  </button>
                                  <button
                                    type="submit"
                                    className="al-btn primary-btn"
                                  >
                                    Guardar filtro
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <ShowcaseLayout
                  modalOpen={handleOpen}
                  initialLayout={layout}
                  onLayoutChange={onLayoutChange}
                />
              </div>
            </div>
          </div>
        </main>

        <div id="printWrapper" className="dashboardimp">
          {generar && (
            <ShowcaseLayout
              modalOpen={handleOpen}
              initialLayout={layout}
              onLayoutChange={onLayoutChange}
              libre={true}
              mounted={mounted}
              setMounted={setMounted}
            />
          )}
        </div>
      </div>
      <ModalCharts rows={layout} index={index} setRows={setLayout} />
    </>
  );
};
