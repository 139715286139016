import { types } from "../types/types";

const initialState = {
    modalOpen: false,
    modalOpen2: false,
    modalOpen3: false,
    modalOpen4: false,
    modalOpen5: false,
    modalOpen6: false,
    modalOpen7: false,
    modalOpen8: false,
    modalOpen9: false,
    modalOpen10: false,
    modalOpen11: false,
    mode:false,
}



export const uiReducer = ( state = initialState, action:any) => {

    switch ( action.type ) {
        case types.uiOpenModal:
            return {
                ...state,
                modalOpen: true
            }

        case types.uiCloseModal:
            return {
                ...state,
                modalOpen: false
            }
        case types.uiOpenModal2:
            return {
                ...state,
                modalOpen2: true
            }

        case types.uiCloseModal2:
            return {
                ...state,
                modalOpen2: false
            }    
        case types.uiOpenModal3:
            return {
                ...state,
                modalOpen3: true
            }

        case types.uiCloseModal3:
            return {
                ...state,
                modalOpen3: false
            }
        
        case types.uiOpenModal4:
            return {
                ...state,
                modalOpen4: true
            }

        case types.uiCloseModal4:
            return {
                ...state,
                modalOpen4: false
                }
        
        case types.uiOpenModal5:
            return {
                ...state,
                modalOpen5: true
            }

        case types.uiCloseModal5:
            return {
                ...state,
                modalOpen5: false
                }

        case types.uiOpenModal6:
            return {
                ...state,
                modalOpen6: true
            }

        case types.uiCloseModal6:
            return {
                ...state,
                modalOpen6: false
                }
        case types.uiOpenModal7:
            return {
                ...state,
                modalOpen7: true
            }

        case types.uiCloseModal7:
            return {
                ...state,
                modalOpen7: false
                }
        
        case types.uiOpenModal8:
            return {
                ...state,
                modalOpen8: true
            }

        case types.uiCloseModal8:
            return {
                ...state,
                modalOpen8: false
                }

        case types.uiOpenModal9:
            return {
                ...state,
                modalOpen9: true
            }

        case types.uiCloseModal9:
            return {
                ...state,
                modalOpen9: false
                }
        
        case types.uiOpenModal10:
            return {
                ...state,
                modalOpen10: true
            }

        case types.uiCloseModal10:
            return {
                ...state,
                modalOpen10: false
                }

        case types.uiOpenModal11:
            return {
                ...state,
                modalOpen11: true
            }

        case types.uiCloseModal11:
            return {
                ...state,
                modalOpen11: false
                }
   
        case types.uimode:
          
            return {
                ...state,
                mode: action.payload
            }

       

        default:
            return state;
    }


}