import { types } from '../types/types';
const initialState = {
  
    tables: [],
    company:[],
    client:[],
    sources: [],
    sources2:[],
    views:[],
    sourceActive:{},
    globalSource:[],
    totalPages:0
}

export const sourceReducer = ( state = initialState, action:any ) => {

    switch ( action.type ) {

        case types.tableLoad:
            return {
                ...state,
                tables: [ ...action.payload ]
                
            }

        case types.newSource:
            return {
                ...state,
                sources: [ ...action.payload ]
                
            }
        case types.loadSource:
            return {
                ...state,
                sources2: [ ...action.payload ]
                
            }    
        case types.clientLoad:
                return {
                    ...state,
                    client: [ ...action.payload ]
                    
                }
        
        case types.viewLoad:
            return {
                ...state,
                views: [ ...action.payload ]
                
            }

        case types.companyLoad:
                    return {
                        ...state,
                        company: [ ...action.payload ]
                        
                    }    

        
        case types.sourceSetActive:
            return {
                ...state,
                sourceActive: action.payload
            }

        case types.sourceClearActiveSource:
            return {
                ...state,
                sourceActive: {}
            }
            

        case types.sourceUpdated:
            return {
                ...state,
                sources: [ ...action.payload ]
            }
        
            case types.sourceDeleted:
                return {
                    ...state,
                   sources:[ ...action.payload ]
                  
                }

        case types.sourceLoaded:
            return {
                ...state,
                users: [ ...action.payload ]
            }
        case types.globalSourcesLoad:
            return {
                ...state,
                globalSource: [ ...action.payload ]
            }  
            case types.sourceSetTotalpages:
                return {
                    ...state,
                    totalPages: action.payload
                }

        default:
            return state;
    }

}