import Swal from "sweetalert2";
import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import { types } from '../types/types';

export const loadTables= (source:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchSinToken( 'source',source,'POST' );
         const body = await resp.json();

        
        if( body.ok ) {
            
            dispatch(tableLoaded(body.tables))
            dispatch(clientLoaded(body.clients))
            dispatch(companyLoaded(body.companys))
            
        } else {
            console.log(body)
        }
    }
}
export const loadHojas= (source:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchSinToken( 'source/sheet',source,'POST' );
         const body = await resp.json();

        
        if( body.ok ) {
            
            dispatch(tableLoaded(body.tables))
            dispatch(clientLoaded(body.clients))
            dispatch(companyLoaded(body.companys))
            
        } else {
            console.log(body)
        }
    }
}
const tableLoaded = (tables) => ({ type: types.tableLoad,payload:tables });
const globalsourcesLoaded = (sources) => ({ type: types.globalSourcesLoad,payload:sources });
const clientLoaded = (client) => ({ type: types.clientLoad,payload:client });
const companyLoaded = (company) => ({ type: types.companyLoad,payload:company });
const viewLoaded = (view) => ({ type: types.viewLoad,payload:view });
export const newSourceCreate= (source:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( 'source/new',source,'POST' );
         const body = await resp.json();
     
      
        if( body.ok ) {
            
            dispatch(newSourceCreated(body.sources.reverse()))
           
            
        } else {
            
            console.log(body)
        }
    }
}
export const newSourceCreateSheet= (source:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( 'source/newsheet',source,'POST' );
         const body = await resp.json();
     
      
        if( body.ok ) {
            
            dispatch(newSourceCreated(body.sources.reverse()))
           
            
        } else {
            Swal.fire('Error en el servidor', body.error, 'error');
            console.log(body)
        }
    }
}
export const loadSource= (id:string,limit:number,page:number,search?:string,value?:any):any => {
    return async(dispatch:any) => {
        try {
            const resp = await fetchSinToken( `source/${id}?limit=${limit}&page=${page}&search=${search?search:''}&dateinit=${value &&value[0] !==null?new Date(value[0]['$d']).getTime():''}&datefin=${value &&value[1] !==null?new Date(value[1]['$d']).getTime():''}` );
   
            const body = await resp.json();
      
        
            
      
        if( body.ok ) {
            const sources=body.sources.docs

            sources.map((source: any)=>  source.check = false );
            dispatch(newSourceCreated(sources));
            if (body.sources.totalPages) {
                
                dispatch(sourceTotalpages(body.sources.totalPages));
            }
       
            dispatch(clientLoaded(body.clients))
            dispatch(viewLoaded(body.views.sort((a,b)=>{
                const valueA=a.name.toLowerCase();
                const valueB=b.name.toLowerCase();
                if (valueA < valueB) {
                    return -1;
                }
                if (valueA > valueB) {
                    return 1;
                }
                return 0;
            } )))
            dispatch(companyLoaded(body.companys))
            dispatch(globalsourcesLoaded(body.sourcesGlobal.sort((a,b)=>{
                const valueA=a.name.toLowerCase();
                const valueB=b.name.toLowerCase();
                if (valueA < valueB) {
                    return -1;
                }
                if (valueA > valueB) {
                    return 1;
                }
                return 0;
            } )))
        //    if (body2.ok) {
        //     const sources=body2.sources
        //     sources.map((source: any)=>  source.check = false );
        //     dispatch(loadSources(sources.reverse()))
        //    }
            
        } else {
            console.log(body)
        }
        } catch (error) {
            console.log(error)
        }
        
    }
}
export const downloadSourceData= async(id:string) => {

    const resp = await fetchConToken( `source/download/${id}` );
    const body = await resp.json();
  
 
   if( body.ok ) {
       
        return body.rows
      
       
   } else {
       console.log(body)
   }
    
}
const newSourceCreated = (source) => ({ type: types.newSource,payload:source });
const loadSources = (source) => ({ type: types.loadSource,payload:source });
const sourceTotalpages = (total) => ({ type: types.sourceSetTotalpages,payload:total });
export const sourceSetActive = (source:any) => ({
    type: types.sourceSetActive,
    payload: source
});

export const sourceClearActive = () => ({ type: types.sourceClearActiveSource });

export const updateSource= (source:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( `source/update/${source._id}`,source,'PUT' );
         const body = await resp.json();
     
      
        if( body.ok ) {
            
            dispatch(newSourceCreated(body.sources.reverse()))
           
            
        } else {
            console.log(body)
        }
    }
}

export const deleteSource= (source:any):any => {
    return async(dispatch:any) => {

        const resp = await fetchConToken( `source/delete/${source}`,{},'DELETE' );
         const body = await resp.json();
     
      
        if( body.ok ) {
            
            dispatch(newSourceCreated(body.sources.reverse()))
           
            
        } else {
            console.log(body)
        }
    }
}
