
import {  Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

export const PublicRoute = ({children}:any) => {
    const { uid } = useSelector( (state:any) => state.auth);
    
 
    return uid ? <Navigate to="/home"/> : children
}
