import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { uiCloseModal8 } from "../actions/ui";
import { fetchSinToken } from "../helpers/fetch";
import { TableChart } from "../pages/home/TableChart";
import CircularProgress from "@mui/material/CircularProgress";

export const ModalDatafont = () => {
  const { modalOpen8 } = useSelector((state: any) => state.ui);
  const { sourceActive } = useSelector((state: any) => state.source);
  const { mode } = useSelector((state: any) => state.ui);

  const { uid } = useSelector((state: any) => state.auth);
  const [data, setData] = useState<any>([]);
  const [tabla, setTabla] = useState<any>([]);

  const dispatch = useDispatch();

  const closeModal = () => {
    setData([]);
    setTabla([]);
    dispatch(uiCloseModal8());
  };
  const getData = async () => {
    const resp = await fetchSinToken(
      "query/q1",
      {
        consulta: `SELECT * FROM  \`vise-192912.altum_hist.${sourceActive?.tableName}${sourceActive?._id}\` limit 1000 `,
      },
      "POST"
    );
    const body = await resp.json();

    if (body.ok === true) {
      setData(body.data);
      setTabla(body.table);
    }
  };

  useEffect(() => {
    if (sourceActive._id) {
      getData();
    }
  }, [sourceActive]);

  return (
    <>
      <Modal
        show={modalOpen8}
        onHide={closeModal}
        className="full-width-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="xl"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title text-center" id="exampleModalLabel">
            {sourceActive.tableName}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => closeModal()}
          ></button>
        </div>
        <div className="modal_body">
          <div className="chart-container center-all share-live-data">
            {data.length < 1 ? (
              <CircularProgress />
            ) : (
              <div style={{ height: "700px" }}>
                <TableChart data={data} label={""} table={tabla} />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
