import Modal from "react-modal";
import ColorPicker from "react-best-gradient-color-picker";
import { useState } from "react";
import { isHexa } from "../../../helpers/isHexa";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    // width: '300px',
    // height: '300px',
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

interface Props {
  modalIsOpen: boolean;
  color: any;
  index: number;
  title: string;
  barColors: string[];
  doubleBar?: boolean;
  doubleColor?: any[];
  datasetIndex?: number;
  setDoubleColor?: (state: unknown) => void;
  setPropsColorModal: (state: unknown) => void;
  setColors: (state: string[]) => void;
}

export const ColorModal = ({
  modalIsOpen,
  color,
  index,
  title,
  barColors,
  doubleBar,
  doubleColor,
  datasetIndex,
  setPropsColorModal,
  setColors,
  setDoubleColor,
}: Props) => {
  const [textColorHexa, setTextColorHexa] = useState("");

  const handleHexa = () => {
    if (!textColorHexa) {
      closeModal();
    } else {
      if (!isHexa(textColorHexa)) {
        Swal.fire("Error", "Color no valido ", "error");
        return;
      }

      if (doubleBar) {
        const newColorBar = [...doubleColor];
        newColorBar[datasetIndex] = newColorBar[datasetIndex].map(
          () => isHexa(textColorHexa) as unknown as string
        );
        setDoubleColor(newColorBar);
        setPropsColorModal((prev) => ({
          ...prev,
          modalIsOpen: false,
        }));
        return;
      }

      const newBarColors = [...barColors];
      newBarColors[index] = isHexa(textColorHexa) as unknown as string;
      setColors(newBarColors);
      setPropsColorModal((prev) => ({
        ...prev,
        modalIsOpen: false,
      }));
    }
  };
  function convertirRGBAaHex(rgba) {
    const colorArray = rgba.replace(/[rgba()]/g, "").split(",");
    const r = parseInt(colorArray[0].trim(), 10);
    const g = parseInt(colorArray[1].trim(), 10);
    const b = parseInt(colorArray[2].trim(), 10);

    const hexColor = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return hexColor;
  }
  const closeModal = () => {
    setPropsColorModal((prev) => ({
      ...prev,
      modalIsOpen: false,
    }));

    if (doubleBar) {
      const newColorBar = [...doubleColor];
      newColorBar[datasetIndex] = newColorBar[datasetIndex].map(() => color);
      setDoubleColor(newColorBar);
      return;
    }

    const newBarColors = [...barColors];
    newBarColors[index] = color;
    setColors(newBarColors);
  };

  return (
    <div style={{ zIndex: "10000" }}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <strong className="d-block mb-3 text-center">{title}</strong>
        <div className="mb-2 d-flex align-items-end">
          <div className="d-flex flex-column">
            <label htmlFor="">Aplicar color con hexadecimal</label>
            <input
              type="text"
              placeholder="#134F40"
              value={textColorHexa}
              onChange={(e) => setTextColorHexa(e.target.value)}
              style={{ padding: "5px", marginRight: "5px" }}
            />
          </div>
          <button
            type="button"
            className="al-btn primary-btn show-datafont"
            style={{ height: "5px !important" }}
            onClick={handleHexa}
          >
            Aplicar
          </button>
        </div>

        <div className="chart-color">
          <ColorPicker
            value={color}
            onChange={(color) => {
              setPropsColorModal((prev) => ({ ...prev, color }));
              const hexa = convertirRGBAaHex(color);
              setTextColorHexa(hexa);
            }}
            hideControls
            hidePresets
            hideInputs
          />
        </div>
      </Modal>
    </div>
  );
};
