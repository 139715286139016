
import { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { startLogin } from '../../actions/auth';
import logo from '../../assets/img/logo-altum.png'
import { useForm } from '../../hooks/useForm';
export const AuthComponent = () => {

    const dispatch = useDispatch();

    const { FormData, onChange }= useForm({
        email:'',
        password:''
    });
    const { email, password }= FormData;
    const onSubmit =(event:FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
       
        dispatch(startLogin(email, password));
    }

  return (
    <>
         <div className="login">
        
   
        <div className="login-img img-fit">
            
            <img src="https://images.unsplash.com/photo-1502481851512-e9e2529bfbf9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" alt="" />
            <p>© Todos los derechos reservados 2022</p>
        </div>
    
        <div className="logo img-contain">
            <img src={logo} alt=""/>
        </div>
    
        <div className="login-form">
            <form onSubmit={onSubmit}>
                <span>Bienvenido a Altum</span>
                
                <h5>Inicia sesión<br/> con tu cuenta</h5>
                <div className="al-field">
                    <label htmlFor="">Nombre de usuario</label>
                    
                    <input type="texto"  onChange={onChange}
                        value={email}
                        name='email' />
                </div>
                <div className="al-field mb-0">
                <input type="password"  onChange={onChange}
                         value={password}
                         name='password' />
                </div>
                <p>¿Olvido su contraseña? haga <a href="#">clic aquí</a></p>
              
                <button className="al-login-btn">Iniciar sesión</button>
            </form>
            
        </div>
    
        </div>
    </>
  )
}
