import React from "react";

import Modal from "react-bootstrap/Modal";

import { useSelector, useDispatch } from "react-redux";
import { uiCloseModal7 } from "../actions/ui";
import { useEffect, useState } from "react";

import { dashboardClearActive, loadDashboardbyid } from "../actions/dashboard";
import { ShowcaseLayout } from "../pages/home/components/GenerarLayout";
import logoVise from "../assets/img/logo-vise.png";
import logo from "../assets/img/logo-altum.jpg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FiltrosDashboardMain from "../pages/home/components/FiltrosDashboardMain";
export const ModalPreviewDashboard = ({ id, setId }) => {
  const { modalOpen7 } = useSelector((state: any) => state.ui);

  const [filtrosapply, setFiltrosapply] = useState<any>([]);

  const { dashboardActive } = useSelector((state: any) => state.dashboard);
  const dispatch = useDispatch();
  const [change, setChange] = useState(1);
  const closeModal = () => {
    dispatch(dashboardClearActive());
    setId("");
    dispatch(uiCloseModal7());
  };
  useEffect(() => {
    if (id !== "") {
      dispatch(loadDashboardbyid(dashboardActive._id));
    }
  }, [id]);

  const downloadToPdf = () => {
    const printArea: any = document.getElementById("printWrapper");
    html2canvas(printArea).then((canvas) => {
      const imgData = canvas.toDataURL("image/JPEG");
      const doc: any = new jsPDF({
        orientation: "landscape",
        format: "legal",
      });
      const imgProps = doc.getImageProperties(imgData);

      const pdfHeight =
        (imgProps.height * doc.internal.pageSize.width) / imgProps.width;
      const positionY = (doc.internal.pageSize.height - pdfHeight) / 2;
      doc.addImage(
        imgData,
        "JPEG",
        5,
        positionY,
        doc.internal.pageSize.width - doc.internal.pageSize.width * (4 / 100),
        pdfHeight
      );

      doc.save("Dashboard.pdf");
    });
  };

  const handleFiltro = async (data) => {
    let nuevosFiltros = [];
    const filtroencontrado = filtrosapply.find((e) => e?.index === data?.index);
    if (data.remove) {
      nuevosFiltros = [...filtrosapply];
      nuevosFiltros = nuevosFiltros.filter(
        (filtro) => filtro.index !== data.index
      );
      setFiltrosapply(nuevosFiltros);
    } else {
      if (!filtroencontrado) {
        nuevosFiltros = [...filtrosapply, data];
        setFiltrosapply(nuevosFiltros);
      } else {
        nuevosFiltros = [...filtrosapply];
        nuevosFiltros[data.index] = { ...data };
        setFiltrosapply(nuevosFiltros);
      }
    }

    if (nuevosFiltros.length === 0) {
      dashboardActive?.rows?.forEach((element1) => {
        if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
          if (element1.chart.temp) {
            element1.chart.doble = element1?.chart?.temp;
          }
        } else {
          if (element1.chart.temp) {
            element1.chart.data = element1?.chart?.temp;
          }
        }
      });
    } else {
      nuevosFiltros?.forEach(async (element, i) => {
        switch (element.filtro.type) {
          case "DATETIME":
            await filtrarPorFechas(element, i);
            setChange(change + 1);
            break;
          case "STRING":
            await filtrarPorTexto(element, i);
            setChange(change + 1);
            break;
          case "FLOAT64INT64":
            await filtrarPorNumero(element, i);
            setChange(change + 1);
            break;
          default:
            break;
        }
      });
    }
  };

  const filtrarPorFechas = (data, i) => {
    switch (data.type) {
      case "entre":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }

                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      const fechaDato = new Date(dato[element.campo]);

                      return (
                        fechaDato >= new Date(data.value) &&
                        fechaDato <= new Date(data.value2)
                      );
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      const fechaDato = new Date(dato[element.campo]);

                      return (
                        fechaDato >= new Date(data.value) &&
                        fechaDato <= new Date(data.value2)
                      );
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    const fechaDato = new Date(dato[element.campo]);
                    return (
                      fechaDato >= new Date(data.value) &&
                      fechaDato <= new Date(data.value2)
                    );
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    const fechaDato = new Date(dato[element.campo]);
                    return (
                      fechaDato >= new Date(data.value) &&
                      fechaDato <= new Date(data.value2)
                    );
                  });
                }
              }
            }
          });
        });
        break;
      case "igual":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }
                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      const fechaDato = new Date(dato[element.campo]);
                      fechaDato.setHours(0, 0, 0, 0);
                      const fechaFiltro = new Date(data.value);
                      fechaFiltro.setHours(0, 0, 0, 0);

                      return fechaDato.getTime() === fechaFiltro.getTime();
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      const fechaDato = new Date(dato[element.campo]);
                      fechaDato.setHours(0, 0, 0, 0);
                      const fechaFiltro = new Date(data.value);
                      fechaFiltro.setHours(0, 0, 0, 0);

                      return fechaDato.getTime() === fechaFiltro.getTime();
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    const fechaDato = new Date(dato[element.campo]);
                    fechaDato.setHours(0, 0, 0, 0);
                    const fechaFiltro = new Date(data.value);
                    fechaFiltro.setHours(0, 0, 0, 0);

                    return fechaDato.getTime() === fechaFiltro.getTime();
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    const fechaDato = new Date(dato[element.campo]);
                    fechaDato.setHours(0, 0, 0, 0);
                    const fechaFiltro = new Date(data.value);
                    fechaFiltro.setHours(0, 0, 0, 0);

                    return fechaDato.getTime() === fechaFiltro.getTime();
                  });
                }
              }
            }
          });
        });
        break;
      case "mayor":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }
                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      const fechaDato = new Date(dato[element.campo]);
                      fechaDato.setHours(0, 0, 0, 0);
                      const fechaFiltro = new Date(data.value);
                      fechaFiltro.setHours(0, 0, 0, 0);

                      return fechaDato.getTime() > fechaFiltro.getTime();
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      const fechaDato = new Date(dato[element.campo]);
                      fechaDato.setHours(0, 0, 0, 0);
                      const fechaFiltro = new Date(data.value);
                      fechaFiltro.setHours(0, 0, 0, 0);

                      return fechaDato.getTime() > fechaFiltro.getTime();
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    const fechaDato = new Date(dato[element.campo]);
                    fechaDato.setHours(0, 0, 0, 0);
                    const fechaFiltro = new Date(data.value);
                    fechaFiltro.setHours(0, 0, 0, 0);

                    return fechaDato.getTime() > fechaFiltro.getTime();
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    const fechaDato = new Date(dato[element.campo]);
                    fechaDato.setHours(0, 0, 0, 0);
                    const fechaFiltro = new Date(data.value);
                    fechaFiltro.setHours(0, 0, 0, 0);

                    return fechaDato.getTime() > fechaFiltro.getTime();
                  });
                }
              }
            }
          });
        });
        break;
      case "menor":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }

                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      const fechaDato = new Date(dato[element.campo]);
                      fechaDato.setHours(0, 0, 0, 0);
                      const fechaFiltro = new Date(data.value);
                      fechaFiltro.setHours(0, 0, 0, 0);

                      return fechaDato.getTime() < fechaFiltro.getTime();
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      const fechaDato = new Date(dato[element.campo]);
                      fechaDato.setHours(0, 0, 0, 0);
                      const fechaFiltro = new Date(data.value);
                      fechaFiltro.setHours(0, 0, 0, 0);

                      return fechaDato.getTime() < fechaFiltro.getTime();
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    const fechaDato = new Date(dato[element.campo]);
                    fechaDato.setHours(0, 0, 0, 0);
                    const fechaFiltro = new Date(data.value);
                    fechaFiltro.setHours(0, 0, 0, 0);

                    return fechaDato.getTime() < fechaFiltro.getTime();
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    const fechaDato = new Date(dato[element.campo]);
                    fechaDato.setHours(0, 0, 0, 0);
                    const fechaFiltro = new Date(data.value);
                    fechaFiltro.setHours(0, 0, 0, 0);

                    return fechaDato.getTime() < fechaFiltro.getTime();
                  });
                }
              }
            }
          });
        });
        break;
      default:
        break;
    }
  };
  const filtrarPorNumero = (data, i) => {
    switch (data.type) {
      case "entre":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }

                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      return (
                        dato[element.campo] >= parseInt(data.value) &&
                        dato[element.campo] <= parseInt(data.value2)
                      );
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      return (
                        dato[element.campo] >= parseInt(data.value) &&
                        dato[element.campo] <= parseInt(data.value2)
                      );
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    return (
                      dato[element.campo] >= parseInt(data.value) &&
                      dato[element.campo] <= parseInt(data.value2)
                    );
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    return (
                      dato[element.campo] >= parseInt(data.value) &&
                      dato[element.campo] <= parseInt(data.value2)
                    );
                  });
                }
              }
            }
          });
        });
        break;
      case "igual":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }

                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      return dato[element.campo] === parseInt(data.value);
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      return dato[element.campo] === parseInt(data.value);
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    return dato[element.campo] === parseInt(data.value);
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    return dato[element.campo] === parseInt(data.value);
                  });
                }
              }
            }
          });
        });
        break;
      case "mayor":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }

                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      return dato[element.campo] > parseInt(data.value);
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      return dato[element.campo] > parseInt(data.value);
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    return dato[element.campo] > parseInt(data.value);
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    return dato[element.campo] > parseInt(data.value);
                  });
                }
              }
            }
          });
        });
        break;
      case "menor":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }

                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      return dato[element.campo] < parseInt(data.value);
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      return dato[element.campo] < parseInt(data.value);
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    return dato[element.campo] < parseInt(data.value);
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    return dato[element.campo] < parseInt(data.value);
                  });
                }
              }
            }
          });
        });
        break;
      default:
        break;
    }
  };
  const filtrarPorTexto = (data, i) => {
    switch (data.type) {
      case "igual":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }

                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      return (
                        dato[
                          dato[element.campo]
                            ? element.campo
                            : `${element.campo}x`
                        ].toLowerCase() === data.value.toLowerCase()
                      );
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      return (
                        dato[
                          dato[element.campo]
                            ? element.campo
                            : `${element.campo}x`
                        ].toLowerCase() === data.value.toLowerCase()
                      );
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    return (
                      dato[
                        dato[element.campo]
                          ? element.campo
                          : `${element.campo}x`
                      ]?.toLowerCase() === data.value.toLowerCase()
                    );
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    return (
                      dato[
                        dato[element.campo]
                          ? element.campo
                          : `${element.campo}x`
                      ]?.toLowerCase() === data.value.toLowerCase()
                    );
                  });
                }
              }
            }
          });
        });
        break;
      case "empieza":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }

                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      return (
                        dato[
                          dato[element.campo]
                            ? element.campo
                            : `${element.campo}x`
                        ][0].toLowerCase() === data.value[0].toLowerCase()
                      );
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      return (
                        dato[
                          dato[element.campo]
                            ? element.campo
                            : `${element.campo}x`
                        ][0].toLowerCase() === data.value[0].toLowerCase()
                      );
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    return (
                      dato[
                        dato[element.campo]
                          ? element.campo
                          : `${element.campo}x`
                      ][0].toLowerCase() === data.value[0].toLowerCase()
                    );
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    return (
                      dato[
                        dato[element.campo]
                          ? element.campo
                          : `${element.campo}x`
                      ][0].toLowerCase() === data.value[0].toLowerCase()
                    );
                  });
                }
              }
            }
          });
        });
        break;
      case "contiene":
        data?.filtro?.graficos.forEach((element) => {
          dashboardActive?.rows?.forEach((element1) => {
            if (element1.chart?.name === element.name) {
              if (element1?.chart?.doble.length > 1 || element1.chart.doble2) {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.doble.length > 0 && !element1.chart.temp) {
                    element1.chart.doble2 = true;
                    element1.chart.temp = [...element1.chart.doble];
                  }

                  element1.chart.doble = element1?.chart?.temp.filter(
                    (dato) => {
                      return dato[
                        dato[element.campo]
                          ? element.campo
                          : `${element.campo}x`
                      ]
                        .toLowerCase()
                        .includes(data.value.toLowerCase());
                    }
                  );
                } else {
                  element1.chart.doble = element1?.chart?.doble.filter(
                    (dato) => {
                      return dato[
                        dato[element.campo]
                          ? element.campo
                          : `${element.campo}x`
                      ]
                        .toLowerCase()
                        .includes(data.value.toLowerCase());
                    }
                  );
                }
              } else {
                if (!element1.chart.temp || i === 0) {
                  if (element1.chart.data.length > 0 && !element1.chart.temp) {
                    element1.chart.temp = [...element1.chart.data];
                  }

                  element1.chart.data = element1?.chart?.temp.filter((dato) => {
                    return dato[
                      dato[element.campo] ? element.campo : `${element.campo}x`
                    ]
                      .toLowerCase()
                      .includes(data.value.toLowerCase());
                  });
                } else {
                  element1.chart.data = element1?.chart?.data.filter((dato) => {
                    return dato[
                      dato[element.campo] ? element.campo : `${element.campo}x`
                    ]
                      .toLowerCase()
                      .includes(data.value.toLowerCase());
                  });
                }
              }
            }
          });
        });
        break;
      default:
        break;
    }
  };
  useEffect(() => {}, [change]);

  return (
    <>
      <Modal
        show={modalOpen7}
        onHide={closeModal}
        className="full-width-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="xl"
        centered
      >
        <div className="position-relative p-4">
          <div id="printWrapper" className="modal_body p-0 overflow-hidden">
            <div className="dashboard-layout pb-0">
              <div className="container-fluid ">
                <div className="row align-items-center mb-2">
                  <div className="col-md-5">
                    <img src={logoVise} alt="" height={"70px"} />
                  </div>

                  <div className="col-md-7">
                    <div className="center-vertical justify-content-end position-relative">
                      <div className="dashboard-results-header">
                        <h3>{dashboardActive?.name} </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    {dashboardActive?.filtros && (
                      <FiltrosDashboardMain
                        filtro={dashboardActive?.filtros}
                        handleFiltro={handleFiltro}
                      />
                    )}
                  </div>
                </div>
                <ShowcaseLayout
                  libre={true}
                  initialLayout={dashboardActive?.rows}
                />
                <div className="row mt-3">
                  <div className="col-md-12 d-flex justify-content-center">
                    <img src={logo} alt="Altum logo" height={60} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-actions">
            <button
              type="button"
              className="al-btn primary-btn py-3 px-4"
              data-bs-dismiss="modal"
              onClick={() => downloadToPdf()}
            >
              {" "}
              Descargar dashboard
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#fff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                <polyline points="7 11 12 16 17 11" />
                <line x1="12" y1="4" x2="12" y2="16" />
              </svg>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
